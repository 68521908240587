﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.RapportOversiktKravUE = function ($app) { // <-- Constructor method
        var $me = this;
        this.graf = null;
        this.prosjektId = null;

        // Define the jquery class
        this.on("viewinit", function () {

            $me.find(".select2").select2({ theme: "bootstrap", dropdownAutoWidth: true });

            $me.Prosjekt.on("change", function () {
                $me.prosjektId = $(this).val();
                $me.Refresh();
                $me.setHistory();
            });

            $me.EksportKnapp.on("click", function () {
                $me.HentOversiktKravUEXlsx($me.prosjektId);
            });

            $me.HentProsjekter().done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }

                $.each(result.Prosjekter, function () {
                    $me.Prosjekt.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                });

                if ($me.prosjektId) {
                    $me.Prosjekt.val($me.prosjektId);
                }
                else if (result.Prosjekter && result.Prosjekter.length) {
                    $me.prosjektId = result.Prosjekter[0].Id;
                    $me.Refresh();
                }
            });
        });

        this.setHistory = function () {
            if (typeof ($me.prosjektId) == "undefined" || $me.prosjektId == null) {
                $app.replaceHistory("#/Rapport/OversiktKravUE");
            } else {
                $app.replaceHistory("#/Rapport/OversiktKravUE+" + $me.prosjektId);
            }
        };

        this.LoadItem = function (inn) {
            var args = inn.split("-");
            if (args[0]) $me.prosjektId = parseInt(args[0]);
            $me.Refresh();
        };

        var updateGraph = function (data) {

            var ctx = document.getElementById("Graf");

            var labels = [$me.Label1.text(), $me.Label2.text(), $me.Label3.text()];
            var count = [data.GodkjenteKravUE, data.ForventetGodkjent, data.Fakturert];
            var colors = ["#4F81BC", "#BE514E", "#9CBA58"];

            //var index = 0;
            //$.each(labels, function () {
            //    var colorIndex = index >= $app.Farger.length ? index % $app.Farger.length : index;
            //    colors.push($app.Farger[colorIndex]);
            //    index++;
            //});


            var options =
            {
                legend: {
                    display: false
                },
                responsive: true,
                lineTension: 0.1,
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointRadius: 1,
                pointHitRadius: 10,
                pointHoverBorderWidth: 2,
                tooltips: {
                    enabled: false,
                    callbacks: {
                        label: function (tooltipItem) {
                            return tooltipItem.yLabel;
                        },
                        title: function (element, data) {
                            var text = labels[element[0].index];
                            return text;
                        },
                    }
                },
                animation: {
                    onProgress: function () {
                        var chartInstance = this.chart;
                        var ctx = chartInstance.ctx;
                        var height = chartInstance.controller.boxes[0].bottom;
                        ctx.textAlign = "center";
                        Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
                            var meta = dataset.metaData;
                            Chart.helpers.each(meta.forEach(function (bar, index) {
                                if (dataset.data[index] != 0) {
                                    ctx.font = "normal 14pt arial";
                                    ctx.fillStyle = "white";
                                    var tHeight = (bar._model.base - ((bar._model.base - bar._model.y) / 2)) - 10;
                                    //ctx.fillText(dataset.data[index], bar._model.x, tHeight);
                                }
                            }), this)
                        }), this);
                    }
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            autoSkip: false,
                            userCallback: function (value, index, values) {
                                return value;
                            },

                        }

                    }],
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }

            };

            var data = {
                labels: labels,
                datasets: [
                    {
                        label: "",
                        fill: false,
                        //options: options,
                        backgroundColor: colors,
                        borderColor: "rgba(75,192,192,1)",
                        pointBorderColor: "rgba(75,192,192,1)",
                        pointBackgroundColor: "#fff",
                        pointHoverBackgroundColor: colors,
                        pointHoverBorderColor: colors,
                        data: count,
                    }
                ]
            };

            if ($me.graf != null) $me.graf.destroy();
            $me.graf = new Chart(ctx, { type: "bar", data: data, options: options });
        };

        this.Refresh = function () {

            if (typeof ($me.prosjektId) == "undefined" || $me.prosjektId == null) {
                if ($me.graf != null) $me.graf.destroy();
                $me.Table.hide();
                return;
            }

            $me.HentOversiktKravUE($me.prosjektId).done(function (result) {

                if (!result.Success && result.Message) {
                    $.fn.Alert(result.Message);
                    return;
                }

                var data = result.Oversikt;

                $me.Tbody.empty();

                $me.Tbody.append("<tr>" +
                    "<td>" + $me.Title1.text() + "</td>" +
                    "<td class=\"text-right\">" + formatNum(data.GodkjenteKravUE) + "</td>" +
                    "</tr>");

                $me.Tbody.append("<tr>" +
                    "<td>" + $me.Title2.text() + "</td>" +
                    "<td class=\"text-right\">" + formatNum(data.ForventetGodkjent) + "</td>" +
                    "</tr>");

                $me.Tbody.append("<tr style=\"font-weight:bold;border-bottom:1px double #000\">" +
                    "<td>" + $me.Title3.text() + "</td>" +
                    "<td class=\"text-right\">" + formatNum(data.SumKostforventning) + "</td>" +
                    "</tr>");

                $me.Tbody.append("<tr>" +
                    "<td>" + $me.Title4.text() + "</td>" +
                    "<td class=\"text-right\">" + formatNum(data.Fakturert) + "</td>" +
                    "</tr>");

                $me.Tbody.append("<tr style=\"font-weight:bold;border-bottom:4px double #000\">" +
                    "<td>" + $me.Title5.text() + "</td>" +
                    "<td class=\"text-right\">" + formatNum(data.GjenstKostUEKrav) + "</td>" +
                    "</tr>");

                $me.Table.show();
                updateGraph(result.Oversikt);
            });
        };

        this.HentProsjekter = function () {
            return $app.APIGet("Rapport/OversiktKravUE", "HentProsjekter");
        };

        this.HentOversiktKravUE = function (prosjektId) {
            return $app.APIGet("Rapport/OversiktKravUE", "HentOversiktKravUE", { prosjektId: prosjektId });
        };

        this.HentOversiktKravUEXlsx = function (prosjektId) {
            return $app.APIDownload("Rapport/OversiktKravUE", "HentOversiktKravUEXlsx", { prosjektId: prosjektId });
        };

        return this;
    };
})(jQuery);