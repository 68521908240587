﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.RapportProsessdiagrammer = function ($app) { // <-- Constructor method
        var $me = this;
                
        // Define the jquery class
        this.on("viewinit", function () {
            $me.find(".select2").select2({ theme: "bootstrap" });

            $me.HentProsessflyter().done(function (result) {
                $.each(result.Prosessflyter, function (index) {
                    $me.Prosessflyt.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                    if (index == 0) {
                        $me.HentOgTegnDiagram(this.Id);
                    }
                });
                $me.Prosessflyt.select2();
            });

            $me.Prosessflyt.on("change", function () {
                var id = $(this).val();
                $me.HentOgTegnDiagram(id);
            });

        });

        this.HentOgTegnDiagram = function (id) {
            $me.HentDiagramdata(id).done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }

                var roller = result.Roller;
                var skjemaer = result.Skjemaer;
                var data = result.Prosessflyter;

                TegnDiagram(roller, skjemaer, data, $me.Diagram);
            });
        };

        this.HentProsessflyter = function () {
            return $app.APIGet("Rapport/Prosessdiagrammer", "HentProsessflyter");
        };

        this.HentDiagramdata = function (id) {
            return $app.APIGet("Rapport/Prosessdiagrammer", "HentDiagramdata", { id: id });
        };

        return this;
    };
})(jQuery);