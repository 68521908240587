﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminLoggVisning = function ($app) { // <-- Constructor method
        var $me = this;
        var $form;


        // Define the jquery class
        this.on("dialoginit", function () {

            $form = $("form").smartform();

            $me.Alle.on("click", function () {
                $me.OppdaterTabell();
            });

            $me.Tabell.on("click", "input[type=\"checkbox\"]", function () {
                $me.OppdaterVelgAlle();
            });

            $me.Soketekst.bind("keyup click", function () {

                var soketekst = $(this).val();
                if (soketekst == "" || soketekst == null) {
                    $me.Tabell.find("tbody tr").show();
                } else {
                    $me.Tabell.find("tbody tr").not(":Contains(\"" + soketekst + "\")").hide();
                    $me.Tabell.find("tbody tr:Contains(\"" + soketekst + "\")").show();
                }
                $me.OppdaterVelgAlle();
            });

            $form.on("submit", function () {
                var data = $form.Read();

                $me.Lagre(data).done(function (result) {
                    if (!result.Success && result.Message) {
                        $me.Tilbakemelding.empty();
                        $me.Tilbakemelding.append("<h3>" + result.Message + "<h3>");
                        $me.FeilmeldingPanel.show();

                        return;
                    }
                    $me.closeDialog();
                });

                return false;
            });

            $me.LoadData();

        });

        this.OppdaterTabell = function () {
            var checkboxes = $(this).closest('form').find(':checkbox:visible.visning');
            checkboxes.prop('checked', $me.Alle.is(':checked'));
        };

        this.OppdaterVelgAlle = function () {
            var checkboxesChecked = $me.Tabell.find("input:checkbox.visning:checked:visible").length;
            var checkboxes = $me.Tabell.find("input:checkbox.visning:visible").length;

            if (checkboxesChecked != checkboxes) $me.Alle.prop('checked', false);
            else $me.Alle.prop('checked', true);
        };

        this.LoadData = function (id) {

            $me.Hent().done(function (result) {
                $form.Write(result);

                $.each(result.Loggtyper, function (index, item) {
                    if (this.Visning == false) {
                        $me.Alle.prop("checked", false);
                        return;
                    }
                });
            });
        };

        this.Hent = function () {
            return $app.APIGet("Admin/Logg/Visning", "Hent", {});
        };

        this.Lagre = function (data) {
            return $app.APIPost("Admin/Logg/Visning", "Lagre", data);
        };

        return this;
    };
})(jQuery);
