﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.Login = function ($app) { // <-- Constructor method
        var $me = this;

        // Define the jquery class
        this.on("dialoginit", function () {

            $me.VisTestbrukere();

            $me.GlemtpassordBtn.on("click", function () {
                $me.login.hide();
                $me.Testbrukere.hide();
                $me.glemtpassord.show();
            });

            $me.on("backglemtpassord", function () {
                $me.glemtpassord.hide();
                $me.VisTestbrukere();
                $me.oppdaterpassord.hide();
                $me.login.show();
            });

            $me.LoginBtn.on("click", function () {
                window.localStorage.removeItem("username2");
                window.localStorage.removeItem("role");
                window.localStorage.removeItem("leder");
                window.localStorage.removeItem("oppdelinnboks");
                window.localStorage.removeItem("oppdelalle");

                window.localStorage.removeItem("rapportmalebrev");
                window.localStorage.removeItem("rapportoversikt");
                window.localStorage.removeItem("rapportoppforinger");
                window.localStorage.removeItem("rapportprosessdiagrammer");
                window.localStorage.removeItem("rapportoversiktkravue");

                $(window).trigger("login");
                if ($me.glemtpassord.is(":visible")) {
                    $me.GlemtPassordControl.SendGlemtBtn.trigger("click");
                    return;
                }

                try {
                    var login = $me.Username.val();
                    var pass = $me.Password.val();

                    $me.Login(login, pass).done(function (result) {
                        if (result.Message && !result.Success) {
                            if (result.ResultCode == 2) {
                                $me.login.hide();
                                $me.oppdaterpassord.show();
                            } else {
                                //$app.ClearLogin();
                                $.fn.Alert(result.Message);
                            }
                        } else {
                            window.localStorage.setItem("username", result.Username);
                            window.localStorage.setItem("role", result.Role);
                            window.localStorage.setItem("leder", result.Leder);
                            window.localStorage.setItem("oppdelinnboks", result.OppdelInnboks);
                            window.localStorage.setItem("oppdelalle", result.OppdelAlle);

                            window.localStorage.setItem("rapportmalebrev", result.VisRapportMalebrev);
                            window.localStorage.setItem("rapportoversikt", result.VisRapportOversikt);
                            window.localStorage.setItem("rapportoppforinger", result.VisRapportOppforinger);
                            window.localStorage.setItem("rapportprosessdiagrammer", result.VisRapportProsessdiagrammer);
                            window.localStorage.setItem("rapportoversiktkravue", result.VisRapportOversiktKravUE);

                            $(window).trigger("login");
                            $me.ContinueApplication();
                        }
                    });


                } catch (e) {
                    $.fn.Alert("Browser error: " + e);
                }
                return false;
            });


            $me.OppdaterPassordBtn.on("click", function () {
                var login = $me.Username.val();
                var gjeldenepassord = $me.Password.val();
                var nyttpassord = $me.nyttpassord.val();
                var nyttpassord1 = $me.nyttpassord1.val();

                if (!login || !gjeldenepassord || !nyttpassord || !nyttpassord1) {
                    $.fn.Alert($me.Tekst_1.text());
                    return;
                }

                if (!nyttpassord.localeCompare(nyttpassord1) == 0) {
                    $.fn.Alert($me.Tekst_2.text());
                    return;
                }

                $me.OppdaterPassord(login, gjeldenepassord, nyttpassord).done(function (result) {
                    if (!result.Success) {
                        $.fn.Alert(result.Message);
                    } else {
                        $.fn.Alert(result.Message, function () {
                            //$app.SetLogin(login, result.Token);
                            $me.glemtpassord.hide();
                            $me.oppdaterpassord.hide();
                            $me.login.show();
                            $me.Password.val(nyttpassord);
                            $me.LoginBtn.trigger("click");
                        });
                    }
                });

            });

            $me.find("tr").on("click", function () {
                var $tr = $(this);
                var tds = $tr.find("td");
                var login = $(tds[0]).text();
                var pass = $(tds[1]).text();
                $me.Username.val(login);
                $me.Password.val(pass);
                $me.LoginBtn.trigger("click");
            });
        });

        this.VisTestbrukere = function () {
            if (window.location.href.indexOf("ikx.no") > 0 || window.location.href.indexOf("localhost") > 0 || window.location.href.indexOf("test.doc2share.no") > 0)
                $me.Testbrukere.show();
            else
                $me.Testbrukere.hide();
        };

        this.ContinueApplication = function () {
            $me.hide();
            $app.ReloginView = null;
            $me.closeDialog();

            if (window.LoginModal) {
                window.LoginModalDef.resolveWith(this, []);
            }
        };

        this.on("dialogshowed", function () {
            if ($app.login != null) {
                // Proceed
                $me.Username.val($app.login);
                $me.Password.focus();
                return;
            } else {
                if (!$app.IsTouchDevice)
                    $me.Username.focus();
            }
        });

        this.Login = function (login, password) {
            var t = base64.encode("Edialog") + " " + base64.encode(login + ":" + password);
            return $app.APIGet("Login", "Login", { login: t });
        };

        this.OppdaterPassord = function (login, gammeltpassord, nyttpassord) {
            var t = base64.encode("Edialog") + " " + base64.encode(login + ":" + gammeltpassord);
            var d = base64.encode("Edialog") + " " + base64.encode(login + ":" + nyttpassord);
            return $app.APIGet("Login", "OppdaterPassord", { oldlogin: t, newlogin: d });
        }

        this.SendGlemtPassord = function (epost) {
            return $app.APIGet("Login", "SendGlemtPassord", { epost: epost });
        };
        return this;
    };
})(jQuery);