﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminProsjektLastNed = function ($app) { // <-- Constructor method
        var $me = this;
        var prosjektid;
        var hovedkontrakter;

        // Define the jquery class
        this.on("dialoginit", function () {

            $me.LastNedAlleOppforingerBtn.on("click", function () {

                $me.ValiderLastNedAlleOppforinger().done(function (res) {
                    if (res.ResultCode == 0) {
                        $me.LastNedAlleOppforinger();
                    } else {
                        if (res.Success) {
                            $me.Confirm(res.Message + "\n\n" + $me.Text7.text(), function () {
                                $me.LastNedAlleOppforinger();
                            });
                        } else {
                            $me.Alert(res.Message);
                        }
                    }
                });

            });

            $me.LastNedHovedkontraktBtn.on("click", function () {
                var hovedkontraktId = $me.HovedkontraktSelect.val();
                if (hovedkontraktId == null || hovedkontraktId == "") {
                    $me.Alert($me.Text3.text());
                    return;
                }
                var rolleId = $me.RolleSelect.val();
                if (rolleId == null || rolleId == "") {
                    $me.Alert($me.Text4.text());
                    return;
                }
                
                $me.ValiderLastNedOppforinger(hovedkontraktId, rolleId).done(function (res) {
                    if (res.ResultCode == 0) {
                        $me.LastNedOppforinger(hovedkontraktId, rolleId);
                    } else {
                        if (res.Success) {
                            $me.Confirm(res.Message + "\n\n" + $me.Text7.text(), function () {
                                $me.LastNedOppforinger(hovedkontraktId, rolleId);
                            });
                        } else {
                            $me.Alert(res.Message);
                        }
                    }
                });
            });

            $me.HovedkontraktSelect.select2({
                theme: "bootstrap",
                escapeMarkup: function (markup) {
                    return markup;
                },
                templateResult: function (item) {
                    return $("<span/>").html(item.text);
                },
                templateSelection: function (item) {
                    return $("<span/>").html(item.text);
                },
                placeholder: $me.Text1.text()
            });
            $me.RolleSelect.select2({
                theme: "bootstrap",
                escapeMarkup: function (markup) {
                    return markup;
                },
                templateResult: function (item) {
                    return $("<span/>").html(item.text);
                },
                templateSelection: function (item) {
                    return $("<span/>").html(item.text);
                },
                placeholder: $me.Text2.text()
            });

            $me.HovedkontraktSelect.on("change", function () {
                $me.RolleSelect.empty();
                $.each(hovedkontrakter, function (index, item) {
                    if (item.Id == $me.HovedkontraktSelect.val()) {
                        $.each(item.Roller, function (index2, item2) {
                            $me.RolleSelect.append(new Option(item2.Navn, item2.Id));
                        });
                    }
                });
                $me.RolleSelect.val(null);

            });
        });

        this.LoadItem = function (id) {
            prosjektid = id;

            $me.Hent(id).done(function (result) {
                if (!result.Success) {
                    $me.Alert(result.Message);
                    return;
                }

                hovedkontrakter = result.Hovedkontrakter;

                $.each(hovedkontrakter, function (index, item) {
                    $me.HovedkontraktSelect.append(new Option(item.Navn, item.Id));
                });

            });
        };

        this.Alert = function (message) {
            $.fn.Alert(message, function () {
                setTimeout(function () {
                    $("body").addClass("modal-open");
                }, 250);
            });
        };

        this.Confirm = function (message, okfunc, cancelfunc) {
            $.fn.Confirm(message, function () {
                setTimeout(function () {
                    $("body").addClass("modal-open");
                }, 250);
                if (okfunc) okfunc();
            }, function () {
                setTimeout(function () {
                    $("body").addClass("modal-open");
                }, 250);
                if (cancelfunc) cancelfunc();
            });
        };

        this.Hent = function (id) {
            return $app.APIGet("Admin/Prosjekt/LastNed", "Hent", { id: id });
        };

        this.ValiderLastNedAlleOppforinger = function () {
            return $app.APIGet("Admin/Prosjekt/LastNed", "ValiderLastNedAlleOppforinger", { id: prosjektid });
        };

        this.LastNedAlleOppforinger = function () {
            return $app.APIDownload("Admin/Prosjekt/LastNed", "LastNedAlleOppforinger", { id: prosjektid });
        };

        this.ValiderLastNedOppforinger = function (hovedkontraktId, rolleId) {
            return $app.APIGet("Admin/Prosjekt/LastNed", "ValiderLastNedOppforinger", { hovedkontraktId: hovedkontraktId, rolleId: rolleId  });
        };

        this.LastNedOppforinger = function (hovedkontraktId, rolleId) {
            return $app.APIDownload("Admin/Prosjekt/LastNed", "LastNedOppforinger", { hovedkontraktId: hovedkontraktId, rolleId: rolleId  });
        };

        return this;
    };
})(jQuery);