﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminKontraktstypeOversikt = function ($app) { // <-- Constructor method
        var $me = this;
        var sortering = 2;
        var reversert = false;
        var scrollTop = 0;
        var debouncer = null;

        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.find("[data-s]").each(function () {
                $(this).append("<span title=\"Sortert stigende\" class=\"glyphicon glyphicon-triangle-top sortdown\"></span>");
                $(this).append("<span title=\"Sortert synkende\" class=\"glyphicon glyphicon-triangle-bottom sortup\"></span>");
            });
            $me.find("[data-s]").on("click", function () {
                var nysortering = $(this).attr("data-s");
                if (nysortering != Math.abs(sortering)) {
                    // Sorter på annen kolonne
                    reversert = false;
                    sortering = nysortering;

                } else {
                    // Reverser gjeldende sortering
                    sortering = sortering * -1;
                    reversert = reversert == true ? false : true;
                }
                $me.find("[data-s]").attr("class", null);

                $(this).addClass("sort" + (reversert ? "" : "rev"));
                $me.setHistory();
                $me.Refresh();
            });

            $me.VisSlettede.on("click", function () {
                $me.Refresh();
            });

            $me.Liste.on("click", ".glyphicon-trash", function () {
                var id = $(this).closest("tr").attr("data-value");
                var text = $(this).closest("tr").children("td").eq(1).text().trim();
                var slettet = $(this).closest("tr").hasClass("text-warning");

                var verb = "";
                if (slettet)
                    verb = $me.Title3.text();
                else
                    verb = $me.Title4.text();

                $.fn.Confirm($me.Title2.text() + " " + verb + " '" + text + "'", function () {
                    $me.SlettKontraktstype(id).done(function (result) {
                        if (!result.Success && result.Message) {
                            $.fn.Alert(result.Message);
                            return;
                        }
                        $me.Refresh();
                    });
                });
            });

            debouncer = $me.debounce($me.setHistory, 50)
            $(window).on("scroll.AdminKontraktstypeOversikt", debouncer);
        });

        this.on("viewclosing", function () {
            if (debouncer != null) $(window).off("scroll.AdminKontraktstypeOversikt", debouncer);
        });

        this.setHistory = function () {
            $app.replaceHistory("#/Admin/Kontraktstype/Oversikt+" + Math.abs(sortering) + "-" + reversert + "-" + $(window).scrollTop(), "");
        };

        this.debounce = function (fn, delay) {
            var timer = null;
            return function () {
                var context = this, args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function () {
                    fn.apply(context, args);
                }, delay);
            };
        }

        this.LoadItem = function (inn) {
            var args = inn.split("-");
            if (args[0]) sortering = parseInt(args[0]);
            if (args[1]) reversert = args[1] == "true";
            if (reversert == true && sortering > 0)
                sortering *= -1;
            if (args[2]) scrollTop = args[2];
            $me.find(".sortrev").removeClass("sortrev");
            $me.find(".sort").removeClass("sort");
            $me.find("[data-s=" + (sortering < 0 ? sortering * -1 : sortering) + "]").addClass(reversert == true ? "sort" : "sortrev");

            $me.Refresh();
        };

        this.Refresh = function () {
            var visSlettede = $me.VisSlettede.prop("checked");
            $me.Liste.empty();
            $me.HentOversikt(visSlettede).done(function (result) {
                $.each(result.Liste, function () {
                    $me.Liste.append("<tr data-value=\"" + this.Id + "\" tabindex=\"0\" class=\"" + (this.Slettet ? "text-warning" : "") + "\">" +
                        "<td>" + this.Id + "</td>" +
                        "<td>" + this.Navn + "</td>" +
                        "<td>" + this.Beskrivelse + "</td>" +
                        "<td>" + this.Referanser + "</td>" +
                        "<td>" + formatDateTime(this.Aktivert) + "</td>" +
                        "<td class=\"nopadd\"><a class=\"btn btn-default\"><span class=\"glyphicon glyphicon-trash\"></span></a></td>" +
                        "<td class=\"nopadd\"><a class=\"btn btn-default\" href=\"#/Admin/Kontraktstype/Rediger+" + this.Id + "\"><span class=\"glyphicon glyphicon-pencil\"></span></a></td>" +
                        "</tr>"
                    );
                });

                $me.Liste.find("tr").on("dblclick", function () {
                    var $a = $(this).find("a[href]");
                    if ($a.attr("href")) {
                        location = $a.attr("href");
                    }
                });

                if (scrollTop > 0) {
                    $(window).scrollTop(scrollTop);
                    scrollTop = 0;
                }
            });

        };

        this.HentOversikt = function (visSlettede) {
            return $app.APIGet("Admin/Kontraktstype/Oversikt", "HentOversikt", { visSlettede: visSlettede, sortering: sortering, reversert: reversert });
        };

        this.SlettKontraktstype = function (id) {
            return $app.APIGet("Admin/Kontraktstype/Oversikt", "SlettKontraktstype", { id: id }, { background: true })
        };

        return this;

    };


})(jQuery);