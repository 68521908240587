﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminProsjektOversikt = function ($app) { // <-- Constructor method
        var $me = this;
        var sortering = 3;
        var reversert = false;
        var scrollTop = 0;
        var debouncer = null;

        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());
            $me.VisSlettede.on("change", $me.Refresh);

            $me.find("[data-s]").each(function () {
                $(this).append("<span title=\"Sortert stigende\" class=\"glyphicon glyphicon-triangle-top sortdown\"></span>");
                $(this).append("<span title=\"Sortert synkende\" class=\"glyphicon glyphicon-triangle-bottom sortup\"></span>");
            });
            $me.find("[data-s]").on("click", function () {
                var nysortering = $(this).attr("data-s");
                if (nysortering != Math.abs(sortering)) {
                    // Sorter på annen kolonne
                    reversert = false;
                    sortering = nysortering;

                } else {
                    // Reverser gjeldende sortering
                    sortering = sortering * -1;
                    reversert = reversert == true ? false : true;
                }
                $me.find("[data-s]").attr("class", null);

                $(this).addClass("sort" + (reversert ? "" : "rev"));
                $me.setHistory();
                $me.Refresh();
            });

            debouncer = $me.debounce($me.setHistory, 50)
            $(window).on("scroll", debouncer);

        });

        this.on("viewclosing", function () {
            if (debouncer != null) $(window).off("scroll", debouncer);
        });

        this.setHistory = function () {
            $app.replaceHistory("#/Admin/Prosjekt/Oversikt+" + Math.abs(sortering) + "-" + reversert + "-" + $(window).scrollTop(), "");
        };

        this.debounce = function (fn, delay) {
            var timer = null;
            return function () {
                var context = this, args = arguments;
                clearTimeout(timer);
                timer = setTimeout(function () {
                    fn.apply(context, args);
                }, delay);
            };
        }

        this.LoadItem = function (inn) {
            var args = inn.split("-");
            if (args[0]) sortering = parseInt(args[0]);
            if (args[1]) reversert = args[1] == "true";
            if (reversert == true && sortering > 0)
                sortering *= -1;
            if (args[2]) scrollTop = args[2];
            $me.find(".sortrev").removeClass("sortrev");
            $me.find(".sort").removeClass("sort");
            $me.find("[data-s=" + (sortering < 0 ? sortering * -1 : sortering) + "]").addClass(reversert == true ? "sort" : "sortrev");

            $me.Refresh();
        };

        this.Refresh = function () {
            var slettede = $me.VisSlettede.prop("checked");
            $me.HentOversikt(slettede).done(function (result) {
                $me.Liste.empty();
                $.each(result.Liste, function () {
                    var $tr = $("<tr tabindex=\"0\" data-id=\"" + this.Id + "\" data-name=\"" + this.Navn + "\" />");

                    $tr.append("<td>" + this.Prosjektnr + "</td>");
                    $tr.append("<td>" + this.Byggewebnr + "</td>");
                    $tr.append("<td>" + this.Navn + "</td>");
                    $tr.append("<td>" + this.Hovedkontrakter + "</td>");
                    $tr.append("<td>" + formatDate(this.Opprettet) + "</td>");

                    $tr.append("<td class=\"nopadd\"><a class=\"delete btn btn-default\"><span class=\"glyphicon glyphicon-trash\"></span></a></td>");
                    $tr.append("<td class=\"nopadd\"><a class=\"btn btn-default\" href=\"#/Admin/Prosjekt/Rediger+" + this.Id + "\"><span class=\"glyphicon glyphicon-pencil\"></span></a></td>");

                    if (this.Slettet != null) {
                        $tr.addClass("text-danger");
                    }

                    $me.Liste.append($tr);
                });
                $me.Liste.find("tr").on("dblclick", function () {
                    location = $(this).find("a[href]").attr("href");
                });

                $me.Liste.find(".delete").on("click", function () {
                    var id = $(this).closest("tr").attr("data-id");
                    var navn = $(this).closest("tr").attr("data-name");

                    var tilbakemeldingstekst = $me.Tekst1.text();
                    if ($(this).closest("tr").hasClass("text-danger")) tilbakemeldingstekst = $me.Tekst2.text();

                    $.fn.Confirm("" + $me.Tekst3.text() + " " + tilbakemeldingstekst + " " + $me.Tekst4.text() + " '" + navn + "' ?", function () {
                        $me.SlettProsjekt(id).done(function (result) {
                            if (!result.Success) {
                                $.fn.Alert(result.Message, null, "h3");
                            }
                            $me.Refresh();
                        });
                    });
                });

                if (scrollTop > 0) {
                    $(window).scrollTop(scrollTop);
                    scrollTop = 0;
                }
            });
        };

        this.HentOversikt = function (slettede) {
            return $app.APIGet("Admin/Prosjekt/Oversikt", "HentOversikt", { slettede: slettede, sortering: sortering, reversert: reversert });
        };

        this.SlettProsjekt = function (id) {
            return $app.APIGet("Admin/Prosjekt/Oversikt", "SlettProsjekt", { id: id });
        };

        return this;
    };
})(jQuery);