﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminHovedstatusRediger = function ($app) { // <-- Constructor method
        var $me = this;
        var $form = $me.smartform();

        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.submit(function () {
                var data = $form.Read();
                $me.LagreHovedstatus(data).done(function (result) {
                    if (!result.Success && result.Message) {
                        $.fn.Alert(result.Message);
                        return;
                    }
                    $me.Tilbakemelding.show().fadeOut(1600, "linear");
                });
                return false;
            });
        });

        this.LoadItem = function (id) {
            if (id > 0) {
                $me.Handlingstype.append($me.Title2.text());
                $me.Id.val(id);
                $me.HentHovedstatus(id).done(function (result) {
                    if (!result.Success && result.Message) {
                        if (result.ResultCode == 2) {
                            $.fn.Alert(result.Message, function () {
                                location = "#/Admin/Hovedstatus/Oversikt";
                            });
                        } else {
                            $.fn.Alert(result.Message);
                            return;
                        }
                    }
                    $.each(result.HovedStatusTyper, function (index, item) {
                        $me.type.append("<option value=" + item.Id + ">" + item.Navn + "</option>");
                    });
                    if (result.Hovedstatus.ErIBruk){
                        $me.type.prop("disabled", true);
                        $me.type.attr("title", "Hovedstatusen er i bruk av en eller flere prosessstatuser, kan derfor ikke endre systemtype.");
                    }else{
                        $me.type.attr("title", "");
                    }

                    $form.Write(result.Hovedstatus);
                });
            } else {
                $me.Handlingstype.append($me.Title3.text());
                $me.HentHovedstatusTyper().done(function (result) {
                    $.each(result, function (index, item) {
                        $me.type.append("<option value=" + item.Id + ">" + item.Navn + "</option>");
                    });
                });

                $form.Write({});
            }
        };

        this.HentHovedstatus = function (id) {
            return $app.APIGet("Admin/Hovedstatus/Rediger", "HentHovedstatus", { id: id }, { background: true });
        };

        this.LagreHovedstatus = function (data) {
            return $app.APIPost("Admin/Hovedstatus/Rediger", "LagreHovedstatus", data, { background: true });
        };

        this.HentHovedstatusTyper = function () {
            return $app.APIGet("Admin/Hovedstatus/Rediger", "HentHovedstatusTyper");
        }

        return this;
    };

})(jQuery);