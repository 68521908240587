﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminHovedstatusOversikt = function ($app) { // <-- Constructor method
        var $me = this;


        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.Refresh();

            $me.VisSlettede.on("click", function () {
                $me.Refresh();
            });

            $me.Liste.on("dblclick", "tr", function () {
                location = $(this).find("a[href]").attr("href");
            });

            $me.Liste.on("click", ".deletebtn", function () {
                var id = $(this).closest("tr").attr("data-value");
                var text = $(this).closest("tr").children("td").eq(1).text().trim();
                var slettet = $(this).closest("tr").children("td").hasClass("text-danger");

                var verb = "";
                if (slettet)
                    verb = $me.Text2.text();
                else
                    verb = $me.Text3.text();

                $.fn.Confirm($me.Text1.text() + " " + verb + " '" + text + "'", function () {
                    $me.SlettHovedstatus(id).done(function (result) {
                        if (!result.Success && result.Message) {
                            $.fn.Alert(result.Message);
                            return;
                        }
                        $me.Refresh();
                    });
                });
            });
        });

        this.Refresh = function () {
            var visSlettede = $me.VisSlettede.prop("checked");
            $me.Liste.empty();
            $me.HentOversikt(visSlettede).done(function (result) {
                $.each(result.Liste, function () {
                    $me.Liste.append("<tr data-value=\"" + this.Id + "\" tabindex=\"0\">" +
                        "<td class=\"" + (this.Slettet ? "text-danger" : "") + "\">" + this.Id + "</td>" +
                        "<td class=\"" + (this.Slettet ? "text-danger" : "") + "\">" + this.Navn + "</td>" +
                        "<td class=\"" + (this.Slettet ? "text-danger" : "") + "\">" + this.Type + "</td>" +
                        "<td class=\"" + (this.Slettet ? "text-danger" : "") + "\">" + this.Beskrivelse + "</td>" +
                        "<td class=\"" + (this.Slettet ? "text-danger" : "") + "\">" + this.Mappenavn + "</td>" +
                        "<td class=\"" + (this.Slettet ? "nopadd text-danger" : "nopadd") + "\"><btn class=\"btn btn-default deletebtn\"" + "><span class=\"" + (this.Slettet ? "glyphicon glyphicon-arrow-left" : "glyphicon glyphicon-trash") + "\"></span></btn></td>" +
                        "<td class=\"" + (this.Slettet ? "nopadd text-danger" : "nopadd") + "\"><a class=\"btn btn-default\" href=\"#/Admin/Hovedstatus/Rediger+" + this.Id + "\"><span class=\"glyphicon glyphicon-pencil\"></span></a></td>" +
                        "</tr>"
                    );
                });
            });

        };

        this.HentOversikt = function (visSlettede) {
            return $app.APIGet("Admin/Hovedstatus/Oversikt", "HentOversikt", { visSlettede: visSlettede }, { background: true });
        };

        this.SlettHovedstatus = function (id) {
            return $app.APIGet("Admin/Hovedstatus/Oversikt", "SlettHovedstatus", { id: id }, { background: true })
        };

        return this;

    };


})(jQuery);