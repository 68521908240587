﻿(function ($) {
    "use strict";
    $.fn.AdminProsjektVariabler = function ($app) { // <-- Constructor method
        var $me = this;

        var editid = null;
        var pid = null;
        var vid = null;
        var oVerdi = null;

        this.on("dialoginit", function () {

            $me.find("form").on("submit", function () {
                if ($("#variabelTable tr").length > 1) {
                    $me.Lagre().done(function (result) {
                        if (!result.success) {
                            $.fn.Alert(result.Message, null, "h3");
                            return false;
                        }
                    });
                };
                $me.closeDialog();
                return false;
            });

            $me.ProsessflytId.on("change", function () {
                pid = $me.ProsessflytId.val();

                $me.HentVariabler(pid).done(function (result) {
                    if (!result.Success) {
                        $.fn.Alert(result.Message, null, "h3");
                        return;
                    }

                    $me.Liste.children().remove();

                    $.each(result.Variabler, function () {
                        var $tr = $("<tr tabindex=\"0\" data-id=\"" + this.Id + "\" />");
                        $tr.append("<td>" + this.Id + "</td>");
                        $tr.append("<td>" + this.Navn + "</td>");
                        $tr.append("<td name=\"VariabelId\" style=\"display:none\">" + this.Variabeltype[0].Id + "</td>");
                        $tr.append("<td>" + this.Variabeltype[0].Navn + "</td>");
                        $tr.append("<td>" + this.StandardVerdi + "</td>");

                        if (typeof this.Overstyrt[0] !== "undefined") {
                            $tr.append("<td name=\"OverstyrtId\" style=\"display:none\">" + this.Overstyrt[0].Id + "</td>");
                            $tr.append("<td class=\"nopadd\"><input class=\"form-control\" name=\"OverstyrtVerdi\" placeholder=\"" + $me.Tekst1.text() + "\" value=\"" + this.Overstyrt[0].Verdi + "\" /></td>");
                        } else {
                            $tr.append("<td name=\"OverstyrtId\" style=\"display:none\"></td>");
                            $tr.append("<td class=\"nopadd\"><input class=\"form-control\" name=\"OverstyrtVerdi\" placeholder=\"" + $me.Tekst1.text() + "\" value /></td>");
                        }

                        $me.Liste.append($tr);

                    });
                });
                return false;
            });
        });

        this.LoadItem = function (id) {
            if (id == "") {
                $.fn.Alert($me.Tekst2.text(), null, "h3");
                $me.closeDialog();
                return;
            }

            $me.HentProsessflyter(id).done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }

                $.each(result.Prosessflyter, function () {
                    $me.ProsessflytId.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                });

                editid = id;
            });
        }

        this.HentProsessflyter = function (id) {
            return $app.APIGet("Admin/Prosjekt/Variabler", "HentProsessflyter", { id: id });
        }

        this.HentVariabler = function (id) {
            return $app.APIGet("Admin/Prosjekt/Variabler", "HentVariabler", { pid: id, eid: editid });
        }

        this.Lagre = function () {
            var variabler = [];

            $("#variabelTable tr").each(function () {
                vid = $(this).find("td").eq(0).html();
                oVerdi = $(this).find("input[name=\"OverstyrtVerdi\"]").val();
                var oid = $(this).find("td").eq(5).html();
                if (oid == "") oid = null;
                if (vid != null)
                    variabler.push({
                        Id: oid,
                        ProsessvariabelId: vid,
                        Verdi: oVerdi
                    })
            })



            return $app.APIPost("Admin/Prosjekt/Variabler", "Lagre", { auth: $app.Token, id: editid, Konfigurasjoner: variabler });
        }

        return this;
    };
})(jQuery);