(function ($) {

    // startsWith
    jQuery.extend(jQuery.expr[':'], {
        attrStartsWith: function (el, _, b) {
            for (var i = 0, atts = el.attributes, n = atts.length; i < n; i++) {
                if (atts[i].nodeName.toLowerCase().indexOf(b[3].toLowerCase()) === 0) {
                    return true;
                }
            }
            return false;
        }
    });

    // Global variables
    var __translateLang = null;
    var __translateDictonary = null;

    // Setup (change language)
    $.translateSetup = function (setup) {

        var dfd = $.Deferred();

        if (!setup || !setup.lang || !setup.url) {
            dfd.resolveWith(null);
        } else {
            var handleXml = function (xml) {

                __translateLang = setup.lang;

                var $xml = $(xml);
                __translateDictonary = {};

                $xml.find("trans-unit").each(function () {

                    var $h = $(this);

                    var id = $h.attr("id");
                    var target = $h.find("target");

                    if (!id) console.warn("Translation unit missing id!");
                    if (!target.length) console.warn("Translation unit " + id + " missing target!");

                    __translateDictonary[id] = target.text();
                });
            }

            var xhr = new XMLHttpRequest();
            xhr.onreadystatechange = function () {
                if (this.readyState == 4) {
                    if (this.status == 200) {
                        handleXml(xhr.responseXML);
                    }
                    dfd.resolveWith(null);
                }
            };
            xhr.open("GET", setup.url, true);
            xhr.send();
        }

        return dfd.promise();
    }

    $.translate = function (element, options) {

        var defaults = {
            foo: 'bar',
            onFoo: function () { }
        }

        var plugin = this;

        plugin.settings = {};

        var $element = $(element);
        var element = element;

        // Public methods
        plugin.foo_public_method = function () {
            // code goes here
        };

        // Private methods
        var foo_private_method = function () {
            // code goes here
        };

        var getText = function (id) {
            if (!id) return null;
            if (!__translateDictonary) return null;
            return __translateDictonary[id.substring(2)];
        };

        plugin.init = function () {

            plugin.settings = $.extend({}, defaults, options);

            $element.find("*:attrStartsWith('i18n')").each(function (index, value) {
                for (var i = 0, atts = this.attributes, n = atts.length; i < n; i++) {

                    var $h = $(this);
                    var att = atts[i].nodeName.toLowerCase();

                    // Handle "i18n"
                    if (att == "i18n") {
                        var id = $h.attr(att);
                        var translation = getText(id);

                        if (translation) $h.text(translation);
                    }

                    // Handle "i18n-*"
                    else if (att.indexOf("i18n-") === 0) {

                        var id = $h.attr(att);
                        var translation = getText(id);

                        if (translation) {
                            var repattr = att.replace("i18n-", "");
                            $h.attr(repattr, translation);
                        }
                    }
                }
            });
        };

        plugin.init();
    }

    $.fn.translate = function (options) {
        return this.each(function () {
            if (undefined == $(this).data('translate')) {
                var plugin = new $.translate(this, options);
                $(this).data('translate', plugin);
            }
        });
    }
})(jQuery);

