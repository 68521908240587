﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.InnboksSkjemaMålebrev = function ($app) { // <-- Constructor method
        var $me = this;
        this.oppføringId = null;

        this.historisk = false;
        this.VisHistorisk = function () {
            $me.historisk = true;

            $me.Sted.attr("name", "Sted");
            $me.Element.attr("name", "Element");
            $me.Prosess.attr("name", "Prosess");

            $me.KontraktBeskrivelse.attr("data-func", "input").attr("data-name", "KontraktBeskrivelse");
            $me.GodkjentMengde.attr("data-func", "input").attr("data-name", "GodkjentMengde");
            $me.Kontraktsmengde.attr("data-func", "input").attr("data-name", "Kontraktsmengde");
            $me.Enhet.attr("data-func", "input").attr("data-name", "Enhet");
            $me.Enhetspris.attr("data-func", "input").attr("data-name", "Enhetspris");
            $me.Elementbeskrivelse.attr("data-func", "input").attr("data-name", "Postbeskrivelse");

            $me.Enhetspris.on("change", function () {
                var $h = $(this);
                var val = $h.text();
                val = Number(val);
                if (isNaN(val)) return;
                val = val.toLocaleString("nb", { minimumFractionDigits: 2 });
                $h.text(val);
            });
            $me.Kontraktsmengde.on("change", function () {
                var $h = $(this);
                var val = $h.text();
                val = Number(val);
                if (isNaN(val)) return;
                val = val.toLocaleString("nb", { useGrouping: false, minimumFractionDigits: 2 });
                $h.text(val);
            });
            $me.GodkjentMengde.on("change", function () {
                var $h = $(this);
                var val = $h.text();
                val = Number(val);
                if (isNaN(val)) return;
                val = val.toLocaleString("nb", { useGrouping: false, minimumFractionDigits: 2 });
                $h.text(val);
            });
        }

        // Define the jquery class
        this.on("controlinit", function () {

            var pageSize = 60;

            var ajaxconfig = function (valg) {

                return {
                    url: "API/InnboksSkjemaMålebrev/Søk",
                    dataType: "json",
                    delay: 0,
                    data: function (params) {

                        params.term = params.term || "";

                        var sted = $me.Sted.val();
                        var prosess = $me.Prosess.val();
                        var element = $me.Element.val();

                        if (valg == "Steder") {
                            sted = params.term;
                        } else if (valg == "Prosesser") {
                            prosess = params.term;
                        } else if (valg == "Elementer") {
                            element = params.term;
                        }

                        var query = {
                            oppføringId: $me.oppføringId,
                            valg: valg,
                            sted: sted,
                            prosess: prosess,
                            element: element,
                            page: params.page || 1,
                            pageSize: pageSize
                        };

                        return query;
                    },
                    processResults: function (data, params) {
                        return {
                            results: data.items,
                            pagination: {
                                more: ((params.page || 1) * pageSize) < data.total
                            }
                        };
                    },
                    cache: false
                }
            };

            var userlang = localStorage.getItem("userLang");
            if (userlang == "en") userlang = null;
            else userlang = "nb";

            $me.Sted.select2({ ajax: ajaxconfig("Steder"), language: userlang, placeholder: $me.Selecttekst_1.text(), allowClear: true })

            $me.Prosess.select2({ ajax: ajaxconfig("Prosesser"), language: userlang, placeholder: $me.Selecttekst_2.text(), allowClear: true })

            $me.Element.select2({ ajax: ajaxconfig("Elementer"), language: userlang, placeholder: $me.Selecttekst_3.text(), allowClear: true })

            $me.MålebrevkontraktId.on("change", function () {
                $me.UpdateSelects();
            });

            $me.Sted.on("change", function () {
                $me.HandleSelectChange()
            });
            $me.Prosess.on("change", function () {
                $me.HandleSelectChange()
            });
            $me.Element.on("change", function () {
                $me.HandleSelectChange()
            });
        });

        this.ClearKontrakt = function (text) {
            $me.KontraktBeskrivelse.text("");
            $me.GodkjentMengde.text("");
            $me.Kontraktsmengde.text("");
            $me.Enhet.text("");
            $me.Enhetspris.text("");
            $me.Elementbeskrivelse.text("");

            $me.MålebrevkontraktId.val("");
        };

        this.LoadingFromId = false;

        this.HandleSelectChange = function () {

            if ($me.LoadingFromId || !$me.oppføringId || $me.historisk) {
                return;
            }

            var sted = $me.Sted.val();
            var prosess = $me.Prosess.val();
            var element = $me.Element.val();

            if (!sted || !sted.length) {
                $me.ClearKontrakt("sted");
                return;
            }

            if (!prosess || !prosess.length) {
                $me.ClearKontrakt("prosess");
                return;
            }

            if (!element || !element.length) {
                $me.ClearKontrakt("element");
                return;
            }

            // Hent kontrakt og sett $me.Målebrevkontrakt.val();
            $me.HentMålebrevkontrakt($me.oppføringId, sted, prosess, element).done(function (result) {

                if (result) {

                    $me.KontraktBeskrivelse.text(result.Beskrivelse);
                    $me.GodkjentMengde.text(result.GodkjentMengde);
                    $me.Kontraktsmengde.text(result.Kontraktsmengde);
                    $me.Enhet.text(result.Enhet);
                    $me.Enhetspris.text(result.Enhetspris);
                    $me.Elementbeskrivelse.text(result.Elementbeskrivelse);

                    $me.MålebrevkontraktId.val(result.Id);
                    $me.MålebrevkontraktId.trigger("change").trigger("input");

                } else {
                    $.fn.Alert($me.Alert_1.text());
                    $me.ClearKontrakt();
                }
            });
        }

        this.UpdateSelects = function () {

            var id = $me.MålebrevkontraktId.val();

            if (!id || !$me.oppføringId || $me.historisk) {
                return;
            }

            $me.HentMålebrevkontraktFraId($me.oppføringId, id).done(function (result) {

                $me.LoadingFromId = true;

                $me.Sted.append("<option value=\"" + result.Sted + "\" selected>" + result.Sted + "</option>");
                $me.Element.append("<option value=\"" + result.Element + "\" selected>" + result.Element + "</option>");
                $me.Prosess.append("<option value=\"" + result.Prosess + "\" selected>" + result.Prosess + "</option>");

                $me.Sted.trigger("change");
                $me.Element.trigger("change");
                $me.Prosess.trigger("change");

                $me.LoadingFromId = false;

                $me.KontraktBeskrivelse.text(result.Beskrivelse);
                $me.GodkjentMengde.text(result.GodkjentMengde);
                $me.Kontraktsmengde.text(result.Kontraktsmengde);
                $me.Enhet.text(result.Enhet);
                $me.Enhetspris.text(result.Enhetspris);
                $me.Elementbeskrivelse.text(result.Elementbeskrivelse);
            });
        }

        this.HentSkjemaData = function () {
            return $app.APIGet("InnboksSkjemaMålebrev", "HentSkjemaData");
        };

        this.HentMålebrevkontrakt = function (oppføringId, sted, prosess, element) {
            return $app.APIGet("InnboksSkjemaMålebrev", "HentMålebrevkontrakt", { oppføringId: oppføringId, sted: sted, prosess: prosess, element: element });
        };

        this.HentMålebrevkontraktFraId = function (oppføringId, id) {
            return $app.APIGet("InnboksSkjemaMålebrev", "HentMålebrevkontraktId", { oppføringId: oppføringId, id: id });
        };

        return this;
    };
})(jQuery);