﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.GlemtPassord = function ($app) { // <-- Constructor method
        var $me = this;

        // Define the jquery class
        this.on("viewinit", function () {

            $me.on("backglemtpassord", function () {
                location = "#/";
            });
        });

        return this;
    };
})(jQuery);