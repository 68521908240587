﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.GlemtPassordControl = function ($app) { // <-- Constructor method
        var $me = this;

        // Define the jquery class
        this.on("controlinit", function () {

            $me.find(".TilbakeBtn").on("click", function () {
                $me.trigger("backglemtpassord");
            });

            $me.SendGlemtBtn.on("click", function () {
                var brukernavn = $me.BrukernavnTxt.val();

                $me.SendGlemtPassord(brukernavn).done(function (result) {
                    if (result.Success) {
                        $.fn.Alert($me.Tekst_1.text(), function () {
                            $me.trigger("backglemtpassord");
                        });
                        return;
                    }
                    if (!result.Success && result.Message) {
                        $.fn.Alert(result.Message);
                        return;
                    }
                });
                return false;
            });

        });

        this.SendGlemtPassord = function (brukernavn) {
            return $app.APIGet("GlemtPassordControl", "SendGlemtPassord", { brukernavn: brukernavn });
        };

        return this;
    };
})(jQuery);