﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminKontraktstypeRediger = function ($app) { // <-- Constructor method
        var $me = this;
        var $form = $me.smartform();
        var editId = null;
        var prosessflytGrupppeId = [];

        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.Form.on("submit", function () {
                var data = $form.Read();

                $me.LagreKontraktstype(data).done(function (result) {
                    if (!result.Success && result.Message) {
                        $.fn.Alert(result.Message);
                        return;
                    }
                    document.location.hash = "Admin/Kontraktstype/Rediger+" + result.Id;
                    $me.Tilbakemelding.show().fadeOut(1600, "linear");
                });
                return false;
            });

            $me.on("click", ".hovedkontrakt", function () {
                var id = $(this).closest("tr").find("[name=\"HovedprosjektId\"]").val();
                window.location = "/#/Admin/Prosjekt/Rediger+" + id;
            });

            $me.prosessflyter.select2({
                escapeMarkup: function (markup) {
                    return markup;
                },
                templateResult: function (item) {
                    return item.text;
                },
                templateSelection: function (item) {
                    var a = $("<a/>");
                    a.on("click", function (e) { e.stopPropagation() });
                    a.attr("href", "#/Admin/Prosessflyt/Rediger/" + prosessflytGrupppeId[item.id] + "/" + item.id);
                    a.attr("target", "_blank");
                    a.css({ "text-decoration": "underline" });
                    a.html(item.text);
                    return a;
                },
            });

            $me.VisSlettedeHovedkontrakter.on("change", function () {
                if ($(this).is(":checked")) {
                    $me.Hovedkontrakter.hide();
                    $me.HovedkontrakterMedSlettede.show();
                } else {
                    $me.Hovedkontrakter.show();
                    $me.HovedkontrakterMedSlettede.hide();
                }
            });

        });

        this.LoadItem = function (id) {
            if (id > 0) {
                $me.Kontraktstype.append($me.Title2.text());

            } else {
                $me.Kontraktstype.append($me.Title3.text());
                $form.Write({});
            }

            $me.Id.val(id);
            $me.HentKontraktstype(id).done(function (result) {
                if (!result.Success && result.Message) {
                    if (result.ResultCode == 2) {
                        $.fn.Alert(result.Message, function () {
                            location = "#/Admin/Kontraktstype/Oversikt";
                        });
                    } else {
                        $.fn.Alert(result.Message);
                        return;
                    }
                }
                editId = id;

                $me.prosessflyter.empty();
                $.each(result.Prosessflyter, function () {
                    prosessflytGrupppeId[this.Id] = this.GruppeId;
                    $me.prosessflyter.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                });

                $form.Write(result.Kontraktstype);

                $.each(result.Hovedkontrakter, function () {
                    if (!this.Slettet && !this.AlleSlettet) {
                        var førsteIkkeSlettetHovedkontrakt;
                        for (var i = 0; i < this.Hovedkontrakter.length; i++) {
                            if (!this.Hovedkontrakter[i].Slettet) {
                                førsteIkkeSlettetHovedkontrakt = i;
                                break;
                            }
                        }

                        $me.Hovedkontrakter.append("<tr><td><a href=\"#Admin/Prosjekt/Rediger+" + this.Id + "\" target=\"_blank\">" + this.Navn + "</a></td><td>" + this.Hovedkontrakter[førsteIkkeSlettetHovedkontrakt].Navn + "</td></tr>");
                        for (var i = førsteIkkeSlettetHovedkontrakt + 1; i < this.Hovedkontrakter.length; i++) {
                            if (!this.Hovedkontrakter[i].Slettet) {
                                $me.Hovedkontrakter.append("<tr><td></td><td>" + this.Hovedkontrakter[i].Navn + "</td></tr>");
                            }
                        }
                    }

                    $me.HovedkontrakterMedSlettede.append("<tr><td>" + "<a href=\"#Admin/Prosjekt/Rediger+" + this.Id + "\" target=\"_blank\"" + (this.Slettet ? "style=\"color: #ff4136\"" : "") + ">" + this.Navn + "</a>" + "</td><td><span class=\"" + (this.Hovedkontrakter[0].Slettet ? "text-danger" : "") + "\">" + this.Hovedkontrakter[0].Navn + "</span></td></tr>");
                    for (var i = 1; i < this.Hovedkontrakter.length; i++) {
                        $me.HovedkontrakterMedSlettede.append("<tr><td></td><td><span class=\"" + (this.Hovedkontrakter[i].Slettet ? "text-danger" : "") + "\">" + this.Hovedkontrakter[i].Navn + "</span></td></tr>");
                    }
                });

                if (result.Kontraktstype.EksisterendeProsessflyter.length > 0) {
                    $me.prosessflyter.val(result.Kontraktstype.EksisterendeProsessflyter);
                    $me.prosessflyter.trigger("change");
                }
            });
        };

        this.HentKontraktstype = function (id) {
            return $app.APIGet("Admin/Kontraktstype/Rediger", "HentKontraktstype", { id: id });
        };

        this.LagreKontraktstype = function (data) {
            return $app.APIPost("Admin/Kontraktstype/Rediger", "LagreKontraktstype", data);
        };



        return this;
    };

})(jQuery);