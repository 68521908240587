﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.InnboksOpprett = function ($app) { // <-- Constructor method
        var $me = this;
        var typerlookup = {};
        var leder = false;
        var benytterTittelmal = false;

        var hovedkontraktId = null;
        var filtrerHovedkontraktKey = window.localStorage.getItem("username") + "filtrerHovedkontrakt";

        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title_1.text());

            var filtrerHovedkontrakt = window.localStorage.getItem(filtrerHovedkontraktKey);
            if ($me.isNum(filtrerHovedkontrakt))
                hovedkontraktId = filtrerHovedkontrakt;

            $me.OppdaterProsjekter();

            $me.Prosjekt.on("change", function () {
                // Load hovedkontrakter
                var prosjektId = $me.Prosjekt.val();

                $me.HentHovedkontrakter(prosjektId).done(function (result) {
                    $me.Hovedkontrakt.empty();
                    $.each(result.Liste, function () {
                        $me.Hovedkontrakt.append("<option value=\"" + this.Id + "\" data-leder=\"" + this.Leder + "\"" + (this.Id == hovedkontraktId ? "selected" : "") + ">" + this.Navn + "</option>");
                    });
                    if (result.Liste.length > 0)
                        $me.Hovedkontrakt.trigger("change");
                    else $me.Abort();
                });
            });

            $me.Hovedkontrakt.on("change", function () {
                // Load kontrakter
                var hovedkontraktId = $me.Hovedkontrakt.val();

                leder = $(this).find("option:selected").attr("data-leder") == "true";

                $me.HentOppføringstyper(hovedkontraktId).done(function (result) {
                    $me.Oppføringstype.empty();
                    typerlookup = {};
                    $.each(result.Liste, function () {
                        $me.Oppføringstype.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>"); //this.VisPrioritet
                        typerlookup[this.Id] = this;
                    });
                    if (result.Liste.length > 0)
                        $me.Oppføringstype.trigger("change");
                    else $me.Abort();
                });

                
            });

            $me.Kontrakt.on("change", function () {
                // Load relevante oppføringstyper
                
                var typeId = $me.Oppføringstype.val();

                var vispri = typerlookup[typeId].VisPrioritet;
                benytterTittelmal = typerlookup[typeId].Tittelmal;

                if (vispri) {
                    $me.PrioritetFelt.show();
                } else {
                    $me.PrioritetFelt.hide();
                }

                if (benytterTittelmal) {
                    $me.Tittel.removeAttr("required");
                    $me.Tittelfelt.hide();
                } else {
                    $me.Tittel.attr("required", "required");
                    $me.Tittelfelt.show();
                }

                $me.HentRepresentanter($me.Oppføringstype.val(), $me.Kontrakt.val()).done(function (result) {

                    $me.Representant.empty();

                    $.each(result.Rollebrukerkontoer, function () {

                        var selectname = "representant_";
                        var rolleid = this.Id;
                        var rollenavn = this.Navn;
                        var css = this.CSS;
                        var currentbruker = result.Bruker;

                        var dropdown = "<div class=\"form-group col-sm-6 col-xs-12\"><span class=\"glyphicon glyphicon-stop text-" + css + "\"></span><label>&nbsp; " + rollenavn + "</label><br /><select name=\"" + selectname + rolleid + "\" class=\"form-control representant\" data-rolleid=\"" + rolleid + "\">";

                        dropdown += "<option value=\"\">(" + $me.Tekst_2.text() + ")</option>";

                        $.each(this.Brukerkontoer, function () {
                            var brukerid = this.Id;
                            var brukernavn = this.Navn;
                            var brukerepost = this.Epost;

                            var selected = brukerid == currentbruker.Id && currentbruker.Roller.indexOf(rolleid) > -1 ? "selected" : "";
                            dropdown += "<option value=\"" + brukerid + "\" " + selected + ">" + brukernavn + " (" + brukerepost + ")</option>";
                        });

                        dropdown += "</select></div>";

                        $me.Representant.append(dropdown);
                    });
                });
            });

            $me.Oppføringstype.on("change", function () {

                var hovedkontraktId = $me.Hovedkontrakt.val();
                var typeId = $me.Oppføringstype.val();

                $me.HentKontrakter(hovedkontraktId, typeId).done(function (result) {
                    $me.Kontrakt.empty();
                    $.each(result.Liste, function () {
                        $me.Kontrakt.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                    });
                    if (result.Liste.length > 0) {
                        $me.Kontrakt.trigger("change");
                        if (result.Liste.length == 1) {
                            $me.KontraktValg.hide();
                        } else {
                            $me.KontraktValg.show();
                        }
                    }
                    else $me.Abort();
                });

                
            });

            $me.Relater.on("change", function () {
                if ($me.Relater.prop("checked"))
                    $me.Relateringsfelter.show();
                else
                    $me.Relateringsfelter.hide();
            });

            var formatItem = function (item) {
                if (item.loading) { return $("<div><img src=\"Images/hourglass.svg\" style=\"height:1em\" /> Søker ...</div>"); }
                if (!item.id && item.Id) item.id = item.Id;
                if (!item.Status) item.Status = "i utkast";
                var id = item.Oppføringsnr;
                if (id == null) id = "utkast";
                if (!item.Tittel) return $("<div>(klikk for å søke)</div>");

                var $item = $("<div class=\"select2-result-repository opprettresultat\" style=\"display:inline-block;margin-bottom:2px;\"><span class=\"label label-default\">" + id + "</span> <b>" + item.Tittel + "</b> (" + item.Status + ")</div>");
                return $item;
            };

            var currentSearchDataLookup = {};
            var currentRelaterte = [];

            var relatertSelect2 = $me.RelatertSøk2.select2({
                closeOnSelect: false,
                theme: "bootstrap",
                placeholder: "(Søk)",
                allowClear: true,
                dropdownAutoWidth: true,

                templateResult: formatItem,
                templateSelection: formatItem,

                ajax: {
                    url: "/API/InnboksOpprett/SokRelaterte",
                    dataType: "json",
                    delay: 0,
                    data: function (params) {
                        var side = params.page ? params.page : 0;

                        var hovedkontraktId = $me.Hovedkontrakt.val();

                        if (!hovedkontraktId) return false;

                        return {
                            auth: "",
                            hovedkontraktId: hovedkontraktId,
                            sok: params.term ? params.term : "", // search term
                            side: side,
                            sideantall: 30,
                            eksluder: currentRelaterte
                        };
                    },
                    processResults: function (data, params) {
                        params.page = params.page || 1;
                        $.each(data.Liste, function () {
                            this.id = this.Id;
                            currentSearchDataLookup[this.Id] = this;
                        });
                        return {
                            results: data.Liste,
                            pagination: {
                                more: (params.page * 30) < data.Antall
                            }
                        };
                    },
                    cache: false
                },
            });

            relatertSelect2.on("change", function (e) {
                $(window).scroll();

            });

            $me.RelatertSøk2.on("change", function (e) {
                var values = $me.RelatertSøk2.val();
                if (values && values.length)
                    $me.RelatertLeggTil.attr("disabled", null);
                else
                    $me.RelatertLeggTil.attr("disabled", true);
            });

            var $relaterte = $me.RelaterteOppføringer.smartform();

            var updateRelaterte = function () {
                var list = [];
                for (var i = 0; i < currentRelaterte.length; i++) {
                    list.push(currentSearchDataLookup[currentRelaterte[i]]);
                }
                $relaterte.Write({ List: list });

                if (!list || !list.length) {
                    $me.RelaterteOppføringer.hide();
                } else {
                    $me.RelaterteOppføringer.show();
                }
            }

            $relaterte.on("attach", function (e, $element, $form) {
                $element.find(".open").on("click", function () {
                    var id = $element.find("input[name='Id']").val();
                    window.open("#/O+" + id);
                });
            });

            $relaterte.on("removed", function (e, index, $arr, $removedItem) {
                var id = $removedItem.find("input[name='Id']").val();
                if (!id) return;
                var idx = currentRelaterte.indexOf(id);
                if (idx == -1) return;
                currentRelaterte.splice(idx, 1);
                updateRelaterte();
            });

            $me.RelatertLeggTil.on("click", function () {
                var values = $me.RelatertSøk2.val();
                $me.RelatertSøk2.val(null).trigger("change");
                $me.RelatertLeggTil.attr("disabled", true);

                if (values && values.length) {
                    for (var i = 0; i < values.length; i++) {

                        var has = false;
                        var currVal = values[i];

                        for (var x = 0; x < currentRelaterte.length; x++) {
                            if (currentRelaterte[x] == currVal) {
                                has = true;
                            }
                        }

                        if (!has) {
                            currentRelaterte.push(currVal);
                        }

                    }
                    updateRelaterte();
                }
            });

            $me.Side1.on("submit", function () {
                var kontraktId = $me.Kontrakt.val();
                var typeId = $me.Oppføringstype.val();
                var tittel = $me.Tittel.val();
                var pri = $me.Prioritet.val();

                if (!benytterTittelmal && tittel.trim() == "") {
                    $.fn.Alert($me.Tekst_3.text());
                    return;
                }

                var representanter = [];

                $("select.representant").each(function () {
                    var $h = $(this);

                    if ($h.val() && $h.data("rolleid")) {
                        representanter.push({ RolleId: $h.data("rolleid"), BrukerId: $h.val() });
                    }
                });

                var data = $relaterte.Read();
                var list = [];
                var relChecked = $me.Relater.prop("checked");
                if (data && relChecked) list = data.List;

                if (relChecked && !list.length) {
                    $.fn.Alert("Ingen relasjoner valgt!");
                    return false;
                }

                $me.Opprett(kontraktId, typeId, tittel, pri, representanter, list).done(function (result) {
                    if (!result.Success) {
                        $.fn.Alert(result.Message);
                        return;
                    }
                    location = "#/O+" + result.Id;
                });
                return false;
            });

            $(window).on("filterHovedkontraktChanged.opprett", $me.FilterHovedkontrakt);

        });

        this.on("viewclosing", function () {
            $(window).off("filterHovedkontraktChanged.opprett");
        });

        this.FilterHovedkontrakt = function () {
            var filtrerHovedkontrakt = window.localStorage.getItem(filtrerHovedkontraktKey);

            if ($me.isNum(filtrerHovedkontrakt)) {
                hovedkontraktId = filtrerHovedkontrakt;
            }
            $me.OppdaterProsjekter();
        };

        this.OppdaterProsjekter = function () {
            $me.HentProsjekter(hovedkontraktId).done(function (result) {
                $me.Prosjekt.empty();
                $.each(result.Liste, function () {
                    $me.Prosjekt.append("<option value=\"" + this.Id + "\"" + (this.Selected ? "selected" : "") + ">" + this.Navn + "</option>");
                });
                if (result.Liste.length > 0)
                    $me.Prosjekt.trigger("change");
                else
                    $me.Abort();
            });
        };

        this.isNum = function (val) {
            if (typeof val === "undefined") return false;
            if (val === null) return false;
            var num = parseInt(val);
            return !isNaN(num);
        };

        this.Abort = function () {
            $.fn.Alert($me.Tekst_4.text(), function () {
                location = "#/";
            }, "h3");
        };

        this.HentProsjekter = function (hovedkontraktId) {
            return $app.APIGet("Innboks/Opprett", "HentProsjekter", { hovedkontraktId: hovedkontraktId});
        };

        this.HentKontrakter = function (hovedkontraktId, oppføringstypeId) {
            return $app.APIGet("Innboks/Opprett", "HentKontrakter", { hovedkontraktId: hovedkontraktId, oppføringstypeId: oppføringstypeId });
        };

        this.HentHovedkontrakter = function (prosjektId) {
            return $app.APIGet("Innboks/Opprett", "HentHovedkontrakter", { prosjektId: prosjektId });
        };

        this.HentOppføringstyper = function (hovedkontraktId) {
            return $app.APIGet("Innboks/Opprett", "HentOppføringstyper", { hovedkontraktId: hovedkontraktId });
        };

        this.HentRepresentanter = function (oppføringstypeId, kontraktId) {
            return $app.APIGet("Innboks/Opprett", "HentRepresentanter", { oppføringstypeId: oppføringstypeId, kontraktId: kontraktId });
        };

        this.Opprett = function (kontraktId, typeId, tittel, prioritet, representanter, relaterte) {
            return $app.APIPost("Innboks/Opprett", "Opprett", { kontraktId: kontraktId, typeId: typeId, tittel: tittel, prioritet: prioritet, representanter: representanter, relaterte: relaterte });
        };

        return this;
    };
})(jQuery);