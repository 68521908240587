﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.BrukerInnstillinger = function ($app) { // <-- Constructor method
        var $me = this;
        var $form = $me.smartform();

        // Define the jquery class
        this.on("viewinit", function () {
            $me.LoadData();

            $form.on("submit", function () {
                var data = $form.Read();
                $me.Lagre(data).done(function (result) {
                    $.fn.Alert(result.Message);
                    $me.LoadData();
                });
                return false;
            });
        });

        this.LoadData = function () {
            $me.Hent().done(function (result) {
                $form.Write(result);

                window.localStorage.setItem("oppdelinnboks", result.OppdelInnboks);
                window.localStorage.setItem("oppdelalle", result.OppdelAlle);
                window.localStorage.setItem("MottaEpostkvittering", result.MottaEpostkvittering);
                $(window).trigger("login");
            });
        };

        this.Hent = function () {
            return $app.APIGet("Bruker/Innstillinger", "Hent");
        };

        this.Lagre = function (data) {
            return $app.APIPost("Bruker/Innstillinger", "Lagre", data);
        };

        return this;
    };
})(jQuery);