﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminLoggOverføringsfeil = function ($app) { // <-- Constructor method
        var $me = this;

        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.LoadNextPage();

            $me.BtnPrøvAlle.on("click", function () {
                $me.RetryAll().done(function (result) {

                    if (!result.Success) {
                        if (result.Feilmeldinger.length > 0) {
                            var feilmeldinger = [];
                            $.each(result.Feilmeldinger, function () {
                                feilmeldinger.push(this.Message);
                            });
                            $.fn.Alert(feilmeldinger.join("<br /><br />"));
                        } else {
                            $.fn.Alert(result.Message);
                        }
                    }

                    $me.LoadNextPage();
                });
            });

            $me.Liste.on("click", "button[name=BtnRetry]", function () {
                var Id = $(this).closest("tr").attr("data-id");
                $me.Retry(Id).done(function (result) {
                    $.fn.Alert(result.Message);
                    $me.LoadNextPage();
                });
            });

        });

        this.LoadNextPage = function () {
            $me.HentLogg().done(function (result) {
                $me.Liste.empty();
                $me.BtnPrøvAlle.show();
                $.each(result.Logg, function () {
                    $me.Liste.append("<tr data-id=\"" + this.OppføringId + "\">" +
                        "<td>" + this.Id + "</td>" +
                        "<td>" + formatDateTime(this.Dato) + "</td>" +
                        "<td>" + this.Beskrivelse + "</td>" +
                        "<td>" + this.AntallForsøk + "</td>" +
                        "<td><a href=\"#/O+" + this.OppføringId + "\">" + this.Oppføringsnr + "</a></td>" +
                        "<td class=\"tight\"><button name=\"BtnRetry\" class=\"btn btn-primary\">" + $me.Btn1.text() + "</button>" +
                        "</tr>"
                    );
                });
                if (result.Logg.length < 1)
                    $me.BtnPrøvAlle.hide();
            });
        };

        this.HentLogg = function () {
            return $app.APIGet("Admin/Logg/Overføringsfeil", "Hent");
        };

        this.Retry = function (Id) {
            return $app.APIGet("AdminLoggOverføringsfeil", "Retry", { Id: Id })
        }

        this.RetryAll = function () {
            return $app.APIGet("Admin/Logg/Overføringsfeil", "RetryAll");
        }

        return this;
    };
})(jQuery);