﻿var jsp = null;
function TegnDiagram(roller, skjemaer, data, $Diagram, redigerbar) {
    var render = function () {
        var $DiagramHeader = $Diagram.find("#Diagramheaders");
        var $DiagramContent = $Diagram.find("#Diagramcontent")

        $DiagramHeader.empty();
        $DiagramContent.empty();

        var columns = {};
        var rlookup = {};
        $.each(roller, function () { rlookup[this.Id] = this; });

        var slookup = {};
        $.each(skjemaer, function () { slookup[this.Id] = this; });


        var firstid = "";
        var $start = $("<div id=\"S_0\" class=\"text-center text-muted\">Start<br><span style=\"margin:0\" class=\"glyphicon glyphicon-arrow-down\"></span></div>");

        var statuslookup = {};
        $.each(data.Statuser, function () {
            if (this.guid)
                statuslookup[this.guid] = this;
            else
                statuslookup[this.Guid] = this;
        });


        var colcount = 0;
        $.each(data.Prosesstrinn, function (pindex) {
            var $td = null;
            var rolle = rlookup[this.RolleId];
            if (!columns[this.RolleId]) {
                colcount += 1;
                $td = $("<td style=\"vertical-align:middle;width:20%;padding:2em\"/>");
                if (pindex == 0) {
                    $td.css("vertical-align", "top");

                    $td.append($start);
                }


                $DiagramContent.append($td);
                columns[this.RolleId] = $td;

                var ico = rolle.Navn == "Arkiv" ? "folder-close" : "user";

                $DiagramHeader.append("<th class=\"text-center\"><span class=\"glyphicon glyphicon-" + ico + "\"></span> " + rolle.Navn + "</th>");
            } else $td = columns[this.RolleId];

            if (pindex == 0) firstid = "T_" + (this.guid ? this.guid : this.Guid);

            var $trinn = $("<div id=\"T_" + (this.guid ? this.guid : this.Guid) + "\" />");
            $trinn.addClass("panel panel-" + rolle.FargeklasseCss + " small");

            var assisterende = "";
            $.each(this.BehandlesAvRoller, function () {
                if (rlookup[this])
                    assisterende += "<span title=\"" + rlookup[this].Navn + "\" style=\"margin-top:3px;margin-right:4px;padding: 1px 0 0 0;width:14px;height:14px;text-align:center\" class=\"label label-" + rlookup[this].FargeklasseCss + " pull-right \"><span style=\"display:inline-block;vertical-align: middle;horizontal-align: middle;\" class=\"glyphicon glyphicon-user\" display:inline-block></span></span>";
            });

            $trinn.append("<div>" + assisterende + "</div><div class=\"panel-heading\"><b>" + this.Tittel + "</b></div>");
            var trinnindex = pindex;

            if (redigerbar) {
                $trinn.find("b").attr("contenteditable", "true").on("input", function () {
                    var text = $(this).text();
                    $($("[data-array=\"Prosesstrinn\"] > *")[trinnindex]).find("h4[data-name=\"Tittel\"]").text(text);
                    $Diagram.trigger("tittelEndret", [text, trinnindex]);
                });
            }
            var that = this;
            var $tbl = $("<table class=\"table table-striped\"></table>");
            $.each(this.Handlinger, function (hindex) {
                var handlingindex = hindex;
                var skjema = "";
                if (this.SkjemaId != null && this.SkjemaId != "") {
                    skjema = "<span class=\"text-muted small\"> med &laquo;" + slookup[this.SkjemaId].Navn + "&raquo;</span>";
                }
                if (this.StatusId != null && this.StatusId != "") {
                    skjema += "<span style=\"margin-top:5px;opacity:0.6\" class=\"pull-right label label-default small\">" + statuslookup[this.StatusId].Navn + "</span>";
                }

                var tilgang = "";
                //Finn alle roller som har tilgang til å se denne handlingen (this.Id)
                $.each(this.Rolletilgang, function () {
                    if (this.HarTilgang) {
                        tilgang += "<span title=\"" + rlookup[this.RolleId].Navn + "\" style=\"padding:0px;margin: 0 1px 0 0 !important;width:4px;height:4px;display:inline-block\" class=\"label-" + rlookup[this.RolleId].FargeklasseCss + " label\"></span>"
                    }
                });

                var tittel = redigerbar ? ("<span contenteditable=\"true\">" + this.Tittel + "</span>") : this.Tittel;
                if (this.SkjemaId != null && this.SkjemaId != "") {
                    tittel = "<span class=\"knapptekst\">Start &laquo;</span>" + tittel + "<span class=\"knapptekst\">&raquo;</span>";
                }
                var $tr = $("<tr><td style=\"position:relative\" id=\"H" + pindex + "x" + hindex + "\" data-link=\"T_" + this.LinkId + "\"><div style=\"position:absolute;right:2px;top:-4px\">" + tilgang + "</div><span class=\"glyphicon glyphicon-circle-arrow-right\"></span> " + tittel + skjema + "</td></tr>");
                $tbl.append($tr);
                if (redigerbar) {
                    $tr.find("[contenteditable]").on("input", function () {
                        var text = $(this).text();
                        $($($("[data-array=\"Prosesstrinn\"] > *")[trinnindex]).find("[data-array=\"Handlinger\"] > *")[handlingindex]).find("[data-name=\"Tittel\"]").text(text);
                    });
                }
            });
            if (this.Handlinger.length == 0)
                $tbl.append("<tr><td><span class=\"\"></span> Oppføringen er avsluttet.</td></tr>");
            $trinn.append($tbl);
            $td.append($trinn);
            $td.append("<div class=\"spacer\"></div>");
            $td.append("<div class=\"spacer\"></div>");
        });

        $DiagramContent.children().last().css("vertical-align", "bottom");


        if (colcount < 4) {
            $Diagram.children("table").css("width", (colcount * 25) + "%");
        } else {
            $Diagram.children("table").css("width", null);
        }
        // ------------------------
        $DiagramContent.find("[data-link]").each(function (index) {
            var source = $(this).attr("id");
            var target = $(this).attr("data-link");
            console.log("link " + source + " to " + target);
            jsp.importDefaults({
                Connector: ["Flowchart", { stub: 20 + (index % 3) * 5, cornerRadius: 5 + (index % 3) * 5 }]
            });

            if (target == firstid) {
                $start.css("padding-left", "1em");
                $start.css("display", "inline-block");
            }

            jsp.connect({
                source: $("#" + source),
                target: $("#" + target),
                anchors: ["Right", "Top"],
                endpoints: [
                ["Rectangle", { width: 8, height: 8 }],
                ["Image", { src: "/Images/arrdown.png", hoverClass: "hoverarrow" }]],
                detachable: false
            });
        });

        /*jsp.connect({
            source: $("#S_0"),
            target: $(firstid),
            anchors: ["Bottom", "Top"]
        });*/
        jsp.repaintEverything();

    };


    if (jsp != null) {
        var s = jsp.getConnections();
        $.each(s, function () {
            jsp.detach(this);
        });
        jsp = jsPlumb.getInstance();
    } else {
        $(window).on("resize", function () {
            if (jsp != null) {
                jsp.repaintEverything();
                console.log("jsp repaint")
            }
        });
        $(document).on("scroll", function () {
            if (jsp != null) {
                jsp.repaintEverything();
                console.log("jsp repaint")
            }
        });
    }

    jsPlumb.ready(function () {
        setTimeout(function () {
            jsp = jsPlumb.getInstance();

            jsp.setContainer($Diagram);

            // Init connection, som fikser rar bug
            var c = jsp.connect({ source: $("a"), target: $("a") });
            jsp.detach(c);

            jsp.importDefaults({
                Connector: ["Flowchart", { stub: 20, cornerRadius: 5 }],
                PaintStyle: {
                    lineWidth: 2,
                    strokeStyle: "rgba(0,0,0,0.3)",
                    dashstyle: "2 1"
                },
                HoverPaintStyle: {
                    lineWidth: 2,
                    strokeStyle: "rgba(255,0,0,1)",
                    dashstyle: "2 1"
                },
                EndpointHoverStyle: { fillStyle: "red" }
            });
            render();
        }, 150);
    });
}