﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminMålebrevkontraktImport = function ($app) { // <-- Constructor method
        var $me = this;
        var file;
        var overskriv = false;

        // Define the jquery class
        this.on("viewinit", function () {

            $me.HentHovedkontrakter().done(function (result) {
                if (!result.Success && result.Message) {
                    $.fn.Alert(result.Message);
                    return;
                }

                $.each(result.Hovedkontrakter, function () {
                    var $option = $("<option/>");
                    $me.Hovedkontrakt.append($option.val(this.Id).text(this.Navn));
                });

                $.each(result.Filtyper, function (index) {
                    var selected = index == 0 ? "checked" : "";
                    $me.Filtype.append("<label class=\"nobr\" style=\"cursor:pointer\"><input type=\"radio\" name=\"filtype\" value=\"" + this.Id + "\" " + selected + "> " + this.Navn + "</label><br>");
                });
            });

            $("#FilDialogModal").on("hidden.bs.modal", function () {
                $me.Tbody.empty();
                $me.PanelBody.empty();
                $me.PanelHeading.empty();
            });

            $me.BtnOverskriv.on("click", function () {
                overskriv = true;
                $me.LastOppBtn.trigger("click");
            });

            $me.LastOppBtn.on("click", function () {

                if (!file) {
                    $.fn.Alert($me.Text1.text());
                    return false;
                }

                var filtype = $me.find("input[name=filtype]:checked").val()

                if (!filtype) {
                    $.fn.Alert($me.Text2.text());
                    return false;
                }

                $me.LastOppImportData(file, $me.FileUpload.UpdateProgress, $me.FileUpload.AbortUploadBtn, $me.Hovedkontrakt.val(), filtype).done(function (result) {

                    if (result.Success) {
                        var msg = result.Antall + " " + $me.Text3.text();

                        if (result.AntallOverskrevet > 0) {
                            msg += ", " + $me.Text5.text() + " " + result.AntallOverskrevet + " " + $me.Text4.text() + "."
                        } else {
                            msg += ".";
                        }

                        $.fn.Alert(msg);
                        return false;
                    }

                    if (result.Emptyquery) {
                        $.fn.Alert($me.Text6.text());
                        return false;
                    }

                    if (result.Feil && result.Feil.length) {
                        $me.Tabell.show();
                        $.each(result.Feil, function () {
                            $me.Tbody.append("<tr>" +
                                "<td class=\"text-right\">" +
                                this.Rad +
                                "</td>" +
                                "<td class=\"text-right\">" +
                                (this.Kolonne ? this.Kolonne : "") +
                                "</td>" +
                                "<td>" +
                                this.Beskrivelse +
                                "</td>" +
                                "</tr>");
                        });

                        if (result.FinnesAllerede > 0) {
                            $me.BtnOverskriv.show();
                        }

                        if (result.Formangefeil) {

                            $me.PanelBody.empty();
                            $me.PanelHeading.empty();

                            $me.Feilmelding.empty();
                            $me.Feilmelding.text(result.Message);
                        }
                    } else {
                        if (!result.Success) {
                            $.fn.Alert($me.Text7.text() + ": " + result.Message);
                            return false;
                        }
                    }

                    $("#FilDialogModal").modal("show");

                    return false;
                });

                return false;
            });

            $me.FilOpplasting.on("change", function () {
                file = $me.FilOpplasting[0].files[0];
            });
        });

        this.LastOppImportData = function (file, progress, $cancelBtn, hovedkontraktId, filtype) {
            var dfd = $.Deferred();

            var xhr = new XMLHttpRequest();
            if (xhr.upload) {
                xhr.open("POST", $app.ServiceUrl + "/AdminMålebrevkontraktImport/LastOppImportData", true);
                $app.showLoading(file.name);

                xhr.setRequestHeader("auth", $app.token);

                if (progress) progress(0, 100);
                $me.FileUpload.Dialog.show();

                xhr.upload.addEventListener("progress", function (e) {
                    if (progress && xhr !== null) progress(e.loaded, file.size);
                }, false);

                xhr.onreadystatechange = function (e) {
                    if (xhr.readyState === 4) {
                        $me.FileUpload.Dialog.hide();
                        $app.hideLoading(file.name);

                        var data = JSON.parse(xhr.responseText);
                        dfd.resolveWith(this, [data, xhr]);
                        xhr = null;
                    }
                };

                $cancelBtn.on("click", function () {
                    $me.FileUpload.Dialog.hide();
                    $app.hideLoading(file.name);

                    if (xhr === null) return;
                    xhr.onreadystatechange = null;
                    xhr.abort();
                    dfd.resolveWith(this, [{ Success: false, Message: $me.Text8.text() }, xhr]);
                    xhr = null;
                });

                var fd = new FormData();
                fd.append("fil", file);
                fd.append("hovedkontraktId", hovedkontraktId);
                fd.append("overskriv", overskriv);
                fd.append("filtype", filtype);
                overskriv = false;

                xhr.send(fd);
            }

            return dfd;
        }

        this.HentHovedkontrakter = function () {
            return $app.APIGet("AdminMålebrevkontraktImport", "HentHovedkontrakter");
        };

        return this;
    };
})(jQuery);