﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminSkjemaOversikt = function ($app) { // <-- Constructor method
        var $me = this;
        var sortering = 0;
        var reversert = false;

        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.VisSlettede.on("change", function () {
                $me.Refresh();
            });

            $me.find("[data-s]").each(function () {
                $(this).append("<span title=\"" + $me.Text1.text() + "\" class=\"glyphicon glyphicon-triangle-top sortering stigende\"></span>");
                $(this).append("<span title=\"" + $me.Text2.text() + "\" class=\"glyphicon glyphicon-triangle-bottom sortering synkende\"></span>");
            });
            $me.find("[data-s]").on("click", function () {
                var nysortering = $(this).attr("data-s");
                if (nysortering != Math.abs(sortering)) {
                    // Sorter på annen kolonne
                    reversert = false;
                    sortering = nysortering;
                    // Tall og datoer starter descending (som explorer.exe)
                    if (nysortering == 1 || nysortering == 4 || nysortering == 5 || nysortering == 6) {
                        reversert = true;
                        sortering = nysortering * -1;
                    }
                } else {
                    // Reverser gjeldende sortering
                    sortering = sortering * -1;
                    reversert = !reversert;
                }

                $me.find(".sortering").css("display", "none");

                if (reversert) {
                    $(this).find(".synkende").first().css("display", "inline-block");
                } else {
                    $(this).find(".stigende").first().css("display", "inline-block");
                }

                $me.Refresh();
            });

            $me.EksportBtn.on("click", function () {
                $me.HentEksport().done(function (result) {
                    var json = JSON.stringify(result, null, 3);
                    var list = [];
                    $.each(result.Liste, function () {
                        list.push(this.Skjema);
                    });
                    //$.fn.Alert(json, null, "div");

                    $me.Source.val(JSON.stringify(list, null, 3));
                    $me.SourcePanel.modal();

                });
            });
        });

        this.LoadItem = function (args) {
            if (!args) args = 0;
            var split = args.split("+");
            sortering = split[0];
            if (sortering < 0) reversert = true;

            if (reversert) {
                $me.find("[data-s=\"" + Math.abs(sortering) + "\"]").find(".synkende").first().css("display", "inline-block");
            } else {
                $me.find("[data-s=\"" + Math.abs(sortering) + "\"]").find(".stigende").first().css("display", "inline-block");
            }

            $me.Refresh();
        }

        this.Refresh = function () {
            var slettede = $me.VisSlettede.prop("checked");
            $app.replaceHistory("#/Admin/Skjema/Oversikt+" + sortering);
            $me.HentOversikt(slettede, sortering).done(function (result) {
                $me.Liste.empty();
                $.each(result.Liste, function () {
                    var slettet = this.Slettet ? "text-danger" : "";
                    var ikon = this.Slettet ? "glyphicon glyphicon-list-alt text-danger" : "glyphicon glyphicon-list-alt text-muted";

                    var prosessflyter = "";
                    $.each(this.Prosessflyter, function () {
                        prosessflyter += "<a class=\"badge badge-default\" href=\"#/Admin/Prosessflyt/Rediger/" + this.ProsessflytgruppeId + "/" + this.Id + "\">" + this.Navn + "</a>";
                    });

                    var kontraktstyper = "";
                    $.each(this.Kontraktstyper, function () {
                        kontraktstyper += "<a class=\"badge badge-default\" href=\"#/Admin/Kontraktstype/Rediger+" + this.Id + "\">" + this.Navn + "</a>";
                    });

                    var $tr = $("<tr tabindex=\"0\" data-id=\"" + this.Id + "\" data-name=\"" + this.Navn + "\" >" +
                        "<td class=\"" + slettet + "\">" + this.Id + "</td>" +
                        "<td class=\"" + slettet + "\"><span class=\"" + ikon + "\"></span> " + this.Navn + "</td>" +
                        "<td class=\"" + slettet + "\">" + this.Beskrivelse + "</td>" +
                        "<td class=\"" + slettet + "\">" + this.Versjon + "</td>" +
                        "<td class=\"" + slettet + "\">" + this.Referanser + "</td>" +
                        "<td class=\"" + slettet + "\">" + formatDateTime(this.Oppdatert) + "</td>" +
                        "<td class=\"" + slettet + "\">" + prosessflyter + "</td>" +
                        "<td class=\"" + slettet + "\">" + kontraktstyper + "</td>" +
                        "<td class=\"nopadd\"><a class=\"btn btn-default slett\"><span class=\"glyphicon glyphicon-trash\"></span></a></td>" +
                        "<td class=\"nopadd\"><a class=\"btn btn-default\" href=\"#/Admin/Skjema/Rediger+" + this.Id + "\"><span class=\"glyphicon glyphicon-pencil\"></span></a></td>" +
                        "</tr>");

                    if (this.Referanser == 0)
                        $tr.addClass("text-warning");

                    $me.Liste.append($tr);

                });

                $me.Liste.find("tr").on("dblclick", function () {
                    var id = $(this).data("id");
                    location = "#/Admin/Skjema/Rediger+" + id;
                });

                $me.Liste.find(".slett").on("click", function () {
                    var id = $(this).closest("tr").attr("data-id");
                    var navn = $(this).closest("tr").attr("data-name");
                    var slettet = $(this).closest("tr").children("td").hasClass("text-danger");

                    var verb = "";
                    if (slettet)
                        verb = $me.Text4.text();
                    else
                        verb = $me.Text5.text();

                    $.fn.Confirm($me.Text3.text() + " " + verb + " " + $me.Text6.text() + " '" + navn + "' ?", function () {
                        $me.SlettSkjema(id).done(function (result) {
                            if (!result.Success) {
                                $.fn.Alert(result.Message, null, "h3");
                            }
                            else {
                                location.reload();

                            }
                        });
                    });
                });
            });

        };

        this.HentOversikt = function (slettede, sortering) {
            return $app.APIGet("Admin/Skjema/Oversikt", "HentOversikt", { visSlettede: slettede, sortering: sortering });
        };

        this.HentEksport = function () {
            return $app.APIGet("Admin/Skjema/Oversikt", "HentEksport");
        };

        this.SlettSkjema = function (id) {
            return $app.APIGet("Admin/Skjema/Oversikt", "SlettSkjema", { id: id });
        };

        return this;
    };
})(jQuery);