﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.LogOut = function ($app) { // <-- Constructor method
        var $me = this;

        // Define the jquery class
        this.on("viewinit", function () {
            $me.LogOut().done(function (result) {
                window.localStorage.removeItem("username");
                window.localStorage.removeItem("role");
                window.localStorage.removeItem("leder");
                $(window).trigger("login");
                //$app.UpdateTitle("BIM2Share - " + $me.Tittel_1.text());
            });
        });

        this.LogOut = function () {
            return $app.APIGet("LogOut", "LogOut");
        };

        return this;
    };
})(jQuery);