﻿jQuery.extend(
    jQuery.expr[":"].Contains = function (a, i, m) {
        return $(a).text().toUpperCase().indexOf(m[3].toUpperCase()) >= 0;
    }
);

function navback() {
    if (!window.history || window.history.length == 0) return true;
    window.history.back();
    return false;
}
Date.prototype.toDateInputValue = (function () {
    var local = new Date(this);
    local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
});

String.prototype.toDateInputValue = (function () {
    var local = new Date(this);
    var t = new Date(this);
    local.setMinutes(t.getMinutes() - t.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
});

function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var $e = $(elem);
    if (!$e.is(":visible")) return false;
    var elemTop = $e.offset().top;

    return elemTop < docViewBottom;
}

(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.Edialog = function (googleMapsAPIKey) { // <-- Constructor method
        var options = {
            disableJsonp: true
        };

        var userlang = localStorage.getItem("userLang");

        var translateSetupPromise = $.translateSetup({
            lang: userlang,
            url: "Views/messages." + userlang + ".xlf"
        });

        tinymce.baseURL = "tinymce";
        tinymce.suffix = '.min';

        var $app = this.smartapp2("API", "Views", options);
        $app.GoogleMapsAPIKey = googleMapsAPIKey;

        $app.createView("Innboks/Oversikt"); // <--- Default view

        $app.createView("Admin/Kontraktstype/Oversikt");
        $app.createView("Admin/Kontraktstype/Oversikt+*", $app.fetch("Admin/Kontraktstype/Oversikt.html"), $app.runPlugin("AdminKontraktstypeOversikt"));
        $app.createView("Admin/Kontraktstype/Rediger+*", $app.fetch("Admin/Kontraktstype/Rediger.html"), $app.runPlugin("AdminKontraktstypeRediger"));
        $app.createView("Admin/Hovedstatus/Oversikt");
        $app.createView("Admin/Hovedstatus/Rediger+*", $app.fetch("Admin/Hovedstatus/Rediger.html"), $app.runPlugin("AdminHovedstatusRediger"));
        $app.createView("Admin/Arkivoverføring");
        $app.createView("Admin/Arkivoverføring+*", $app.fetch("Admin/Arkivoverføring.html"), $app.runPlugin("AdminArkivoverføring"));
        $app.createView("Admin/Logg/Hendelseslogg");
        $app.createDialog("Admin/Logg/Visning");
        $app.createView("Admin/Logg/Overføringsfeil");
        
        $app.createView("Admin/Prosjekt/Oversikt");
        $app.createView("Admin/Prosjekt/Oversikt+*", $app.fetch("Admin/Prosjekt/Oversikt.html"), $app.runPlugin("AdminProsjektOversikt"));
        $app.createView("Admin/Prosjekt/Rediger+*", $app.fetch("Admin/Prosjekt/Rediger.html"), $app.runPlugin("AdminProsjektRediger"));
        $app.createDialog("Admin/Prosjekt/Variabler");
        $app.createDialog("Admin/Prosjekt/Kontrakter");
        $app.createDialog("Admin/Prosjekt/LastNed");

        $app.createView("Admin/Rolle/Oversikt");
        $app.createView("Admin/Rolle/Rediger+*", $app.fetch("Admin/Rolle/Rediger.html"), $app.runPlugin("AdminRolleRediger"));

        $app.createView("Admin/Skjema/Oversikt+*", $app.fetch("Admin/Skjema/Oversikt.html"), $app.runPlugin("AdminSkjemaOversikt"));
        $app.createView("Admin/Skjema/Rediger+*", $app.fetch("Admin/Skjema/Rediger.html"), $app.runPlugin("AdminSkjemaRediger"));

        $app.createView("Admin/MålebrevkontraktImport");

        $app.createView("Bruker/Innstillinger");

        //$app.createView("Innboks/Behandle+*", $app.fetch("Innboks/Behandle.html"), $app.runPlugin("InnboksBehandle"));
        $app.createView("Innboks/Behandle+*", $app.fetch("Innboks/Behandle.html"), function ($me) {
            $me.LoadItem = function (id) {
                location.hash = "O+" + id;
            };
        });

        $app.createControl("Innboks/Skjema/Målebrev");

        $app.createView("Innboks/Opprett");
        $app.createView("Innboks/Oversikt+*", $app.fetch("Innboks/Oversikt.html"), $app.runPlugin("InnboksOversikt"));

        $app.createView("Rapport/Målebrev");
        $app.createView("Rapport/Oversiktsrapport");
        $app.createView("Rapport/Prosessdiagrammer");
        $app.createView("Rapport/OversiktKravUE");
        $app.createView("Rapport/OversiktKravUE+*", $app.fetch("Rapport/OversiktKravUE.html"), $app.runPlugin("RapportOversiktKravUE"));

        $app.createView("Dashboard");
        $app.createControl("FileUpload");
        $app.createDialog("Login");
        $app.createView("GlemtPassord");
        $app.createControl("GlemtPassordControl");
        $app.createView("LogOut");
        $app.createView("O+*", $app.fetch("Innboks/Behandle.html"), $app.runPlugin("InnboksBehandle"));
        $app.createDialog("Retry");

        this.RetryDialogName = "Retry";
        this.QueuedFailedLoginCalls = null;


        // Sett opp standard tekster
        $.fn.AlertSettings = {
            OKBtn: "OK, fortsett!",
            DefaultContainer: "h2",
            ModalOptions: {}
        };

        $.fn.ConfirmSettings = {
            OKBtn: "OK, fortsett!",
            CancelBtn: "Avbryt",
            DefaultContainer: "h2"
        };

        // Define the jquery class
        this.on("appinit", function () {

            $app.on("viewinit", function (e, $view, $app) {
                $view.find(".slidein").addClass("slide");
            });
            $app.on("viewclosing viewsuspended", function () {
                $(".tooltip").remove();
            });
            $app.on("viewshowed", function (e, $view, $app) {
                $(".tooltip").remove();
                $view.find(".slidein").addClass("in");
                setTimeout(function () {
                    $view.find(".slidein").removeClass("slide in slidein");
                    $view.find("[autofocus]").focus();
                }, 160);
            });

            $app.on("viewinit controlinit dialoginit", function (e, $view, $app) {
                $view.translate();
            });

            if (!Modernizr.inputtypes.date) {
                var dateOptions = {
                    prevText: "&#x3c;Tilbake", prevStatus: "",
                    prevJumpText: "&#x3c;&#x3c;", prevJumpStatus: "",
                    nextText: "Videre&#x3e;", nextStatus: "",
                    nextJumpText: "&#x3e;&#x3e;", nextJumpStatus: "",
                    currentText: "Idag", currentStatus: "",
                    todayText: "Idag", todayStatus: "",
                    clearText: "-", clearStatus: "",
                    closeText: "Lukk", closeStatus: "",
                    monthNames: ["Januar", "Februar", "Mars", "April", "Mai", "Juni",
                        "Juli", "August", "September", "Oktober", "November", "Desember"],
                    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "Mai", "Jun",
                        "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
                    dayNames: ["Søndag", "Mandag", "Tirsdag", "Onsdag", "Torsdag", "Fredag", "Lørdag"],
                    dayNamesShort: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
                    dayNamesMin: ["Sø", "Ma", "Ti", "On", "To", "Fr", "Lø"],
                    showMonthAfterYear: false,
                    dateFormat: "yy-mm-dd"
                };

                $app.on("viewshowed dialogshowed attach", function (e, $e) {
                    $e.find("input[type=\"date\"]").datepicker(dateOptions);
                });
            }
        });

        this.UpdateTitle = function (title) {
            $("title").text(title);
        };

        this.Farger = ["#00A7F6", "#00BCD7", "#009788", "#47B04B", "#89C541",
            "#CDDE20", "#FEED17", "#FEC200", "#FE9900", "#7A5447",
            "#607D8D", "#9E9E9E", "#FF5605", "#EC1161",
            "#9D1BB3", "#6834BA", "#404EB9", "#1294F6"];

        var trim = (function () {
            "use strict";

            function escapeRegex(string) {
                return string.replace(/[\[\](){}?*+\^$\\.|\-]/g, "\\$&");
            }

            return function trim(str, characters, flags) {
                flags = flags || "g";
                if (typeof str !== "string" || typeof characters !== "string" || typeof flags !== "string") {
                    throw new TypeError("argument must be string");
                }

                if (!/^[gi]*$/.test(flags)) {
                    throw new TypeError("Invalid flags supplied '" + flags.match(new RegExp("[^gi]*")) + "'");
                }

                characters = escapeRegex(characters);

                return str.replace(new RegExp("^[" + characters + "]+|[" + characters + "]+$", flags), '');
            };
        }());

        this.AddFailedLoginCall = function (call) {
            if (!window.LoginModal) {
                window.LoginModalDef = $.Deferred();
                window.LoginModal = window.LoginModalDef.promise();

                $app.hideLoading();
                $app.openDialog("Login", {
                    show: true,
                    keyboard: false,
                    backdrop: "static"
                }).done(function ($v) {
                    $app.ReloginView = $v;
                });

                window.LoginModal.then(function () {
                    window.LoginModal = null;
                });
            }
            window.LoginModal.then(call);
        };

        this.ForwardDeferredResult = function (call, dfd, controller, method, data, opts) {
            if (opts == null) opts = {};
            if (data == null) data = {};

            controller = controller.replace(new RegExp("/", "g"), "");
            var ajaxcall = null;
            function nestedCall() {
                ajaxcall = call(controller, method, data, opts).then(function (resultObject, textStatus, jqXhr) {
                    ajaxcall = null;
                    if (resultObject != null && resultObject.ResultCode == -999 && !opts.nologin) {
                        $app.AddFailedLoginCall(nestedCall);
                    } else {
                        dfd.resolveWith(this, [resultObject, textStatus, jqXhr]);
                    }
                }, function (xhr, ajaxOptions, thrownError) {
                    if (xhr.status == 401 && !opts.nologin)
                        $app.AddFailedLoginCall(nestedCall);
                    else dfd.rejectWith(this, [xhr, ajaxOptions, thrownError]);
                });
            };
            nestedCall();

            var abortFunc = function () {
                if (ajaxcall != null) ajaxcall.abort();
                ajaxcall = null;
            };
            return abortFunc;
        };

        // Keep old references
        var apiGet = this.APIGet;
        var apiPost = this.APIPost;
        var apiDownload = this.APIDownload;

        this.APIGet = function (controller, method, data, opts) {
            var dfd = $.Deferred();
            var abortFunc = $app.ForwardDeferredResult(apiGet, dfd, controller, method, data, opts);
            var promise = dfd.promise();
            promise.abort = abortFunc;
            return promise;
        };
        this.APIPost = function (controller, method, data, opts) {
            var dfd = $.Deferred();
            var abortFunc = $app.ForwardDeferredResult(apiPost, dfd, controller, method, data, opts);
            var promise = dfd.promise();
            promise.abort = abortFunc;
            return promise;
        };
        this.APIDownload = function (controller, method, data, opts) {
            if (!data) data = {};

            return apiDownload(controller.replace(new RegExp("/", "g"), ""), method, data, opts);
        };

        translateSetupPromise.done(function () {
            $app.run();
        });

        return $app; // Return this jquery control
    };

})(jQuery);


function pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
}

function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
};

function formatDate(e) {
    if (e == null) return "-";
    var d = new Date(e);
    return pad(d.getDate(), 2) + "." + pad(1 + d.getMonth(), 2) + "." + d.getFullYear();
};

function isNumber(obj) { return !isNaN(parseFloat(obj)) };

function formatDateInput(e) {
    if (e == null) return "-";
    var d = new Date(e);
    return d.getFullYear() + "-" + pad(1 + d.getMonth(), 2) + "-" + pad(d.getDate(), 2);
};

function formatDateTime(e) {
    if (e == null) return "-";
    var d = new Date(e);
    return pad(d.getDate(), 2) + "." + pad(1 + d.getMonth(), 2) + "." + d.getFullYear() + " kl. " + pad(d.getHours(), 2) + ":" + pad(d.getMinutes(), 2);
};
function isScrolledIntoView(elem) {
    var docViewTop = $(window).scrollTop();
    var docViewBottom = docViewTop + $(window).height();
    var $e = $(elem);
    if (!$e.is(":visible")) return false;
    var elemTop = $e.offset().top;

    return elemTop < docViewBottom;
}
Number.prototype.round = function (places) {
    return +(Math.round(this + "e+" + places) + "e-" + places);
}
Number.isInteger = Number.isInteger || function (value) {
    return typeof value === 'number' &&
        isFinite(value) &&
        Math.floor(value) === value;
};
function formatNum(x, digits) {
    if (x == null) return "";

    if (digits == 0) x = Math.round(x).toFixed(0);
    else if (Number.isInteger(digits)) x = (x.round(digits)).toFixed(digits);

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function formatPostnr(e) {
    return ("000" + e).slice(-4);
}

function guid() {
    function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
        s4() + '-' + s4() + s4() + s4();
}

function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
}