﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.Retry = function ($app) { // <-- Constructor method
        var $me = this;

        this.on("dialoginit", function () {
            $me.RetryBtn.click(function () {
                $me.ExecCalls($app.FailedRetryCalls);
                $app.FailedRetryCalls = null;
                $app.FailedCancelCalls = null;
                return false;
            });
            $me.CancelBtn.click(function () {
                $me.ExecCalls($app.FailedCancelCalls);
                $app.FailedRetryCalls = null;
                $app.FailedCancelCalls = null;
                return false;
            });
        });

        this.ExecCalls = function (callList) {
            $app.RetryView = null;
            $me.closeDialog();
            for (var i = 0; i < callList.length; i++)
                callList[i]();
            return false;
        };

        this.SetErrorText = function () {
            $me.HeaderLbl.text($me.Tekst_1.text());
            $me.MessageLbl.html($me.Tekst_2.text() + " 23 68 75 00, " + $me.Tekst_3.text() + " support@bim2share.no");
            $me.Message2Lbl.html($me.Tekst_4.text());
        }

        return this;
    };
})(jQuery);