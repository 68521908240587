﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminProsjektRediger = function ($app) { // <-- Constructor method
        var prosjektid;
        var $me = this;
        var $form;
        var kontraktstyper = [];
        var brukere = [];
        var brukerkontoer = [];
        var lagredeHovedkontrakter = [];

        var select2Options = {
            theme: "bootstrap",
            dropdownAutoWidth: true,
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (item) {
                return $("<span/>").html(item.text);
            },
            templateSelection: function (item) {
                return $("<span/>").html(item.text);
            }
        };

        var select2OptionsMultiple = {
            theme: "bootstrap",
            dropdownAutoWidth: true,
            escapeMarkup: function (markup) {
                return markup;
            },
            templateResult: function (item) {
                return $("<span/>").html(item.text);
            },
            templateSelection: function (item) {
                return $("<span/>").html(item.text);
            },
            multiple: true
        };

        var tinymceInitPromise2 = $.Deferred();

        // Define the jquery class
        this.on("viewinit", function () {
            $form = $me.Form.smartform();
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.OversiktKravUE.on("change", function () {
                var $h = $(this);
                var checked = $h.prop("checked");
                $me.ProsessflytKravUE.prop("disabled", !checked);
            });
            $me.ProsessflytKravUE.select2({ theme: "bootstrap", dropdownAutoWidth: true });
            $me.OversiktKravUE.trigger("change");

            $form.on("attach", function (e, $el, $f) {
                $el.find(".hovedkontrakter").on("click", function () {
                    // Open modal over kontrakter for denne hovedkontrakten
                    var eid = $(this).closest("tr").find("td").first().text();
                    $app.openDialog("Admin/Prosjekt/Kontrakter").done(function ($v) {
                        $v.LoadItem(eid);
                        $v.on("dialogclosing", function () {
                            $me.LoadItem(prosjektid);
                        });
                    });
                });

                $el.find(".hovedkontrakter[name=Slettet]").on("change", function () {
                    var v = $(this).val();
                    if (v == "" || v == null) {
                        $el.closest("tr").removeClass("text-danger");
                    } else {
                        $el.closest("tr").addClass("text-danger");
                        if (!$me.VisSlettede.prop("checked"))
                            $el.closest("tr").hide();
                    }
                });
                $el.find("select[name=TypeId]").each(function () {
                    var $sel = $(this);
                    $sel.empty();
                    $.each(kontraktstyper, function () {
                        $sel.append($("<option value=\"" + this.Id + "\" />").text(this.Navn));
                    });
                });
                $el.find(".redigerKontraktstype").on("click", function () {
                    var id = $(this).closest("td").find("select[name=TypeId]").val();
                    var url = "#/Admin/Kontraktstype/Rediger+" + id;
                    window.open(url, "_blank");
                });

                $el.find(".variabler").on("click", function () {
                    // Open modal over variabler for denne hovedkontrakten
                    var eid = $(this).closest("tr").find("td").first().text();
                    $app.openDialog("Admin/Prosjekt/Variabler").done(function ($v) {
                        $v.LoadItem(eid);
                    });
                });
                $el.find(".delete").on("click", function () {
                    var $tr = $(this).closest("tr");

                    if ($tr.hasClass("autoinsert")) return;
                    var id = $tr.find("td").first().text();
                    var navn = $tr.find("td").eq(1).text();

                    var tilbakemeldingstekst = $me.Text2.text();
                    if ($tr.hasClass("text-danger")) tilbakemeldingstekst = $me.Text3.text();

                    $.fn.Confirm($me.Text1.text() + " " + tilbakemeldingstekst + " " + $me.Text4.text() + " &laquo;" + navn + "&raquo; ?", function () {

                        if (!$tr.hasClass("text-danger")) {
                            $tr.remove();
                        } else {
                            $tr.find("[name=Slettet]").val("");
                            $tr.removeClass("text-danger");
                        }
                    });
                });
                $el.find(".test").on("click", function () {
                    var $h = $(this);
                    var $tr = $(this).closest("tr");

                    if ($tr.hasClass("autoinsert")) return false;

                    var eid = $(this).closest("tr").find("td").first().text();
                    
                    var mappenavn = $tr.find("input[name=Mappenavn]").val();

                    if (!lagredeHovedkontrakter.some(h => h.Id == eid && h.Mappenavn == mappenavn && h.TypeId == $tr.find("select[name=TypeId]").val())) {
                        $.fn.Alert($me.Text17.text());
                        return false;
                    }

                    var $checkmark = $h.closest("td").find(".checkmark").first();

                    $me.TestMappenavn(eid).done(function (result) {
                        if ($checkmark && $checkmark.length) {
                            $checkmark.remove();
                        }

                        if (result.Success) {
                            $checkmark = "<span class=\"checkmark alert-success\"><span class=\"glyphicon glyphicon-ok\"></span></span>";
                        } else {
                            $checkmark = "<span class=\"checkmark alert-danger\"><span class=\"glyphicon glyphicon-remove\"></span></span>";

                            $.fn.Alert(result.Message, null, "h3");
                        }

                        $h.closest("td").prepend($checkmark);
                    });

                    return false;
                });


                $el.find("[name=BrukergrupperOpprettet]").on("change", function () {
                    $(this).text(formatDateTime($(this).text()));
                });

                $el.find("[name=BrukergrupperSlettet]").on("change", function () {
                    var t = $(this).text();
                    var res = "-";
                    if (t != "null") {
                        var row = $(this).closest("tr");
                        row.addClass("text-danger");
                        row.hide();
                        res = formatDateTime(t);
                    }

                    $(this).text(res);
                });

                $el.find("select[name=BrukerIder]").each(function () {
                    var $sel = $(this);
                    $sel.empty();
                    $.each(brukerkontoer, function () {
                        var t = this.Slettet ? ("<span class=\"glyphicon glyphicon-warning-sign text-danger\"></span> " + this.Navn + " <span class=\"text-danger\">(slettet)</span>") : this.Navn;
                        $sel.append($("<option value=\"" + this.Id + "\" />").text(t));
                    });

                    $sel.select2(select2OptionsMultiple);
                });

                $el.find(".deleteBrukergruppe").on("click", function () {
                    var $tr = $(this).closest("tr");

                    if ($tr.hasClass("autoinsert")) return;
                    var id = $tr.find("td").first().text();
                    var navn = $tr.find("td").eq(1).text();

                    var tilbakemeldingstekst = $me.Text2.text();
                    if ($tr.hasClass("text-danger")) tilbakemeldingstekst = $me.Text3.text();

                    $.fn.Confirm($me.Text1.text() + " " + tilbakemeldingstekst + " " + $me.Text16.text() + " &laquo;" + navn + "&raquo;?", function () {

                        if (!$tr.hasClass("text-danger")) {
                            //$tr.find("[name=Slettet]").val(new Date());
                            //$tr.addClass("text-danger");
                            $tr.remove();
                        } else {
                            $tr.find("[name=Slettet]").val("");
                            $tr.removeClass("text-danger");
                        }
                    });
                });

                $el.find(".redigerBrukergruppe").on("click", function () {
                    var $tr = $(this).closest("tr");

                    if ($tr.hasClass("autoinsert")) return;
                    var id = $tr.find("td").first().text();
                    
                    location = "#/Admin/Brukergruppe/Rediger/" + id;
                });


                $el.find(".prosjektadminBrukere").each(function () {
                    var $sel = $(this);
                    $sel.empty();
                    $sel.append($("<option hidden disabled selected value />").text($me.Text15.text()));
                    $.each(brukere, function () {
                        $sel.append($("<option value=\"" + this.Id + "\" />").text("<span class=\"text-muted " + (this.Id.startsWith("b") ? "glyphicon glyphicon-user" : "fa fa-users") + "\"></span> " + this.Navn));
                    });

                    $sel.select2(select2Options);

                    $sel.on("select2:select", function (e) {
                        $me.OppdaterProsjektAdminSelect2();
                    });
                });



                $el.find(".deleteProsjektAdmin").on("click", function () {
                    var $tr = $(this).closest("tr");

                    if ($tr.hasClass("autoinsert")) return;
                    var navn = $tr.find("td").eq(1).find("select :selected").text();

                    $.fn.Confirm($me.Text1.text() + " " + $me.Text2.text() + " " + $me.Text14.text() + " &laquo;" + navn + "&raquo; ?", function () {
                        $tr.remove();
                        $me.OppdaterProsjektAdminSelect2();
                    });
                });

                $el.find("td.logo input[type=\"file\"]").each(function () {
                    $(this).attr("accept", ".jpg,.png");
                });
            });

            $form.on("added", function (e, $el, $f) {
                var $advarselcheckbox = $el.find("input[name=\"Arkiveringsadvarsel\"]").first();

                if ($advarselcheckbox && $advarselcheckbox.length) {
                    var val = $advarselcheckbox.prop("checked");

                    var $btn = $el.find(".hovedkontraktadvarsel").first();

                    var id = $el.find("[data-name=\"Id\"]").first().text();

                    $btn.attr("href", "#/Admin/Arkivoverføring+" + prosjektid + "+" + id + "");

                    if (!val) {
                        if ($btn.attr('data-original-title')) $btn.tooltip("destroy");
                        $btn.hide();
                        return;
                    }

                    $btn.show();

                    $btn.tooltip({
                        animation: false, //viktig, destroy er 'async' hvis animasjon så det kan oppstå feil når man gjenoppretter tooltip
                        placement: "right",
                        html: true,
                        template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner alert-danger" style="background-color:#E33939;font-size:16px;"></div></div>',
                        title: $me.Text5.text(),
                        trigger: "hover"
                    });
                }

                $me.OppdaterProsjektAdminSelect2();
            });

            $form.on("loadcomplete", function () {
                $.each($me.find(".ManglendeRoller"), function (index, item) {
                    if ($(item).text() == "") {
                        $(item).css("display", "none");
                    } else {
                        $(item).attr("data-original-title", $me.Text6.text() + ": " + $(item).text());
                        $(item).text("");
                    }
                });

                $(".ManglendeRoller").tooltip({
                    animation: false, //viktig, destro er 'async' så det kan oppstå feil når man gjenoppretter tooltip
                    placement: "right",
                    html: true,
                    template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner alert-danger" style="background-color:#E33939;font-size:16px;"></div></div>'
                });

                $.each($me.find(".ManglendeBrukere"), function (index, item) {
                    if ($(item).text() == "") {
                        $(item).css("display", "none");
                    } else {
                        $(item).attr("data-original-title", $(item).text());
                        $(item).text("");
                    }
                });

                $(".ManglendeBrukere").tooltip({
                    animation: false, //viktig, destro er 'async' så det kan oppstå feil når man gjenoppretter tooltip
                    placement: "right",
                    html: true,
                    template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner alert-danger" style="background-color:#E33939;font-size:16px;"></div></div>'
                });


                $.each($me.find("[data-array=\"ProsjektAdministratorer\"] tr"), function (index, item) {
                    if ($(item).find("[data-name=\"Id\"]").text().length > 0)
                        $(item).find(".prosjektadminBrukere").attr("disabled", true);

                    if ($(item).find("[name=\"Utløpt\"]").val() == "true") {
                        $(item).addClass("text-danger");
                        $(item).find(".deleteProsjektAdmin").remove();
                        $(item).find("[name=\"Oppstart\"]").attr("disabled", true);
                        $(item).find("[name=\"Utløpsdato\"]").attr("disabled", true);
                        if (!$me.VisSlettedeAdmin.prop("checked"))
                            $(item).hide();
                    }
                });

                $me.OppdaterProsjektAdminSelect2();
            });

            $me.VisSlettede.on("change", function () {
                var v = $(this).prop("checked");
                if (v) {
                    $me.find("#Hovedkontrakter table tr.text-danger").show();
                } else {
                    $me.find("#Hovedkontrakter table tr.text-danger").hide();
                }
            });

            $me.VisSlettedeAdmin.on("change", function () {
                var v = $(this).prop("checked");
                if (v) {
                    $me.find("#ProsjektAdmin table tr.text-danger").show();
                } else {
                    $me.find("#ProsjektAdmin table tr.text-danger").hide();
                }
            });

            $me.VisSlettedeBrukergrupper.on("change", function () {
                if ($(this).prop("checked")) {
                    $me.find("#Brukergrupper table").addClass("visSlettede");
                    $me.find("#Brukergrupper table tr.text-danger").show();
                } else {
                    $me.find("#Brukergrupper table").removeClass("visSlettede");
                    $me.find("#Brukergrupper table tr.text-danger").hide();
                }
            });

            $me.VisSlettedeGruppemedlemmer.on("change", function () {
                if ($(this).prop("checked")) {
                    $me.find("#Brukergrupper table").addClass("visSlettedeBrukere");
                } else {
                    $me.find("#Brukergrupper table").removeClass("visSlettedeBrukere");
                }
            });

            $me.OppdaterArkiveringspassord.on("click", function () {
                $me.LagreArkiveringspassord.prop("checked", true);
                $me.Arkiveringslogin.prop("disabled", false);
                $me.ArkiveringspassordFormgroup.show();
                $me.OppdaterArkiveringspassord.hide();
            });

            var ajax = null;

            $me.LagreBtn.on("click", function () {
                var data = new FormData();
                var r = $form.Read();
                r.Prosjektnotater = $me.Prosjektnotater.val();
                console.log(r);

                var d = JSON.stringify(r);
                data.append("data", d);

                var attachments = 0;
                $form.find("input[type=file][data-upload]").each(function (i, item) {
                    var f = item.files[$(item).attr("data-fileindex") || 0];
                    var filename = $(item).attr("data-upload");
                    data.append("attachments[]", f, filename);
                    attachments++;
                });

                $form.find("img[data-upload]").each(function (index, item) {
                    var base64 = $(item).attr("src").split(";base64,")[1];
                    var blob = b64toBlob(base64);
                    var filename = $(item).attr("data-upload");
                    data.append("attachments[]", blob, filename);
                    attachments++;
                });

                var xhr = null;
                $app.showLoading("AdminProsjektRediger/Lagre");

                ajax = $.ajax({
                    url: "API/AdminProsjektRediger/Lagre",
                    type: "POST",
                    dataType: "json",
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    xhr: function () {
                        // get the native XmlHttpRequest object
                        xhr = $.ajaxSettings.xhr();

                        if (attachments > 0) {
                            $me.FileUpload.Dialog.show();
                            xhr.upload.onprogress = function (evt) {
                                if (ajax != null) {
                                    $me.FileUpload.UpdateProgress(evt.loaded, evt.total);
                                }
                            };
                        }

                        return xhr;
                    }
                });

                $me.FileUpload.AbortUploadBtn.off("click");
                $me.FileUpload.AbortUploadBtn.on("click", function () {
                    if (ajax != null) ajax.abort();
                    ajax = null;

                    $me.FileUpload.Dialog.hide();
                    $me.FileUpload.Reset();

                    // Reload...
                    location.reload();
                });

                ajax.always(function () {
                    ajax = null;
                    $me.FileUpload.Dialog.hide();
                    $me.FileUpload.Reset();
                    $app.hideLoading("AdminProsjektRediger/Lagre");
                });

                ajax.done(function (result) {
                    if (!result.Success) {
                        $.fn.Alert(result.Message, null, "h3");
                        return;
                    }

                    $me.LoadItem(result.ProsjektId);

                    if (result.HovedkontakterMedLikeKontaktsnummer.length > 0) {
                        var melding = "Endringene er lagret. Legg merke til følgende:<br>";
                        $.each(result.HovedkontakterMedLikeKontaktsnummer, function (index, item) {
                            melding += "<br>Hovedkontrakten \"" + item.HovedkontraktNavn + "\" inneholder kontraktsnummer som også finnes i følgende hovedkontrakter: "; 
                            melding += "<ul>"
                            $.each(item.AndreHovedkontrakter, function (index, item2) {
                                melding += "<li>\"" + item2.HovedkontraktNavn + "\" i hovedprosjekt " + "<a href=\"#Admin/Prosjekt/Rediger+" + item2.HovedprosjektId + "\" target=\"_blank\">" + item2.HovedprosjektNavn + "</a></li>"
                            });
                            melding += "</ul>";
                        });
                        $.fn.Alert(melding, null, "h4");
                    }

                    if (result.KontrakterMedOppdatertNr.length > 0) {
                        $.fn.Confirm($me.Text13.text(), function () {
                            $me.OppdaterOppføringsnummer(result.KontrakterMedOppdatertNr).done(function (result2) {
                                if (result2.Success) $.fn.Alert($me.Text11.text(), null, "h3");
                                else $.fn.Alert($me.Text12.text(), null, "h3");
                            });
                        });
                    }
                });

                ajax.fail(function (jqXHR, textStatus, errorThrown) {
                    $.fn.Alert(jqXHR.responseJSON.Message);
                });

                return false;
            });

            $me.LastNedBtn.on("click", function () {
                $app.openDialog("Admin/Prosjekt/LastNed").done(function ($v) {
                    $v.LoadItem(prosjektid);
                });
            });

            $me.BtnValiderSamtlige.on("click", function () {

                $me.Hovedkontrakter.find("tbody").first().find("tr").each(function () {
                    var $tr = $(this);
                    if ($tr.hasClass("autoinsert")) return;

                    var eid = $tr.find("td").first().text();
                    var mappenavn = $tr.find("input[name=Mappenavn]").val();

                    if (!lagredeHovedkontrakter.some(h => h.Id == eid && h.Mappenavn == mappenavn && h.TypeId == $tr.find("select[name=TypeId]").val())) {
                        $.fn.Alert($me.Text17.text());
                        return false;
                    }
                });

                $me.TestSamtligeMappenavn(prosjektid).done(function (result) {

                    if (!result.Success) {
                        $.fn.Alert(result.Message, null, "h3");
                    } else {

                        var $rader = $me.Hovedkontrakter.find("tbody").first().find("tr");
                        var harugyldige = false;

                        var currentrad = -1;
                        $.each(result.Resultater, function () {
                            currentrad++;
                            var gyldig = this.gyldig || false;

                            if (!gyldig) {
                                harugyldige = true;
                            }

                            var $currentrad = $rader.eq(currentrad);

                            if ($currentrad.hasClass("autoinsert")) return;

                            var $checkmark = $currentrad.find(".checkmark").first();

                            if ($checkmark && $checkmark.length) {
                                $checkmark.remove();
                            }

                            var $td = $currentrad.find("button.test").first().closest("td");

                            if (gyldig) {
                                $td.prepend("<span class=\"checkmark alert-success\"><span class=\"glyphicon glyphicon-ok\"></span></span>");
                            } else {
                                $td.prepend("<span class=\"checkmark alert-danger\"><span class=\"glyphicon glyphicon-remove\"></span></span>");
                            }
                        });

                        if (harugyldige) {
                            $.fn.Alert($me.Text8.text() + ". <br/><br/>" + $me.Text9.text() + ".", null, "h3");
                        }
                    }
                });
            });

            tinymce.init({
                selector: "#Prosjektnotater",
                theme: "modern",
                themeToolbar1: "undo redo | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link\"",
                plugins: "link code paste table lists hr autoresize textcolor",
                insert_toolbar: "",
                menubar: false,
                statusbar: false,
                content_css: "Edialog.css",
                paste_preprocess: function (pl, o) {
                    if (o.content.indexOf("<img ") >= 0) o.content = "";
                    //timer = setTimeout($me.TimerFunk, 450);
                },
                setup: function (ed) {
                    ed.on("change input", function (e) {
                        var verdi = tinyMCE.get("Prosjektnotater").getContent();
                        $me.Prosjektnotater.val(verdi).trigger("change");
                    });
                    ed.on("init", function () {
                        var verdi = $me.Prosjektnotater.val();
                        $me.Prosjektnotater.tinymce().setContent(verdi);
                        tinymceInitPromise2.resolve();
                    });
                },
                paste_data_images: false,
                paste_as_text: false,
                branding: false
            });
            
        });

        var removeTiny2 = false;
        this.on("viewclosed", function () {
            if (!removeTiny2) {
                tinymceInitPromise2.then(function () {
                    $me.Prosjektnotater.tinymce().remove();
                });
                removeTiny2 = true;
            }
        });

        this.OppdaterProsjektAdminSelect2 = function () {
            $me.ProsjektAdmin.find(".prosjektadminBrukere").each(function (index2, item2) {
                $(item2).find("option:not(hidden)").attr("disabled", false);
                $(item2).select2(select2Options);
            });

            $me.ProsjektAdmin.find(".prosjektadminBrukere").each(function (index, item) {
                if ($(item).closest("tr").hasClass("text-danger")) return true;
                var selected = $(item).find("option:selected").val();

                $me.ProsjektAdmin.find(".prosjektadminBrukere").not(item).each(function (index2, item2) {
                    if ($(item2).closest("tr").hasClass("text-danger")) return true;
                    $(item2).find("option[value=\"" + selected + "\"]").attr("disabled", true);
                    $(item2).select2(select2Options);
                });

            });
        };

        this.LoadItem = function (id) {
            if (id == 0) {
                $me.Prosjektnr.hide();
            } else {
                $me.Prosjektnr.show();
            }
            prosjektid = id;
            $me.Hent(id).done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }
                $app.replaceHistory("#Admin/Prosjekt/Rediger+" + result.Prosjekt.Id);
                $form.find("[data-array] > *").remove();
                kontraktstyper = result.Kontraktstyper;
                brukere = result.Brukere;
                brukerkontoer = result.Brukerkontoer;

                if (!result.LagreArkiveringspassord) {
                    $me.LagreArkiveringspassord.prop("checked", false);
                    $me.Arkiveringslogin.prop("disabled", true);
                    $me.OppdaterArkiveringspassord.show();
                    $me.ArkiveringspassordFormgroup.hide();
                }

                $me.UbehandletvarselIntervall.text("( " + result.Prosjekt.UbehandletvarselIntervall + " dager )");

                $me.ProsessflytKravUE.empty();
                $.each(result.Prosessflyter, function () {
                    $me.ProsessflytKravUE.append("<option value=\"" + this.Id + "\">(" + this.Id + ") " + this.Visningsnavn + " " + this.Kode + "</option>");
                });

                var urlPrefix = "/API/AdminProsjektRediger/LastNed?id=" + id + "&vedlegg=";
                $form.DownloadUrlPrefix = urlPrefix;
                $form.ThumbUrlPrefix = urlPrefix;

                $form.Write(result.Prosjekt);
                $me.VisSlettede.trigger("change");
                $me.VisSlettedeBrukergrupper.trigger("change");

                if (result.Prosjekt.Arkiveringsadvarsel) {
                    $me.ProsjektAdvarsel.attr("href", "#/Admin/Arkivoverføring+" + prosjektid + "");
                    $me.ProsjektAdvarsel.show();
                    $me.ProsjektAdvarsel.tooltip({
                        animation: false, //viktig, destroy er 'async' hvis animasjon så det kan oppstå feil når man gjenoppretter tooltip
                        placement: "right",
                        html: true,
                        template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner alert-danger" style="background-color:#E33939;font-size:16px;"></div></div>',
                        title: $me.Text10.text(),
                        trigger: "hover"
                    });
                } else {
                    if ($me.ProsjektAdvarsel.attr('data-original-title')) $btn.tooltip("destroy");
                    $me.ProsjektAdvarsel.hide();
                }

                lagredeHovedkontrakter = result.Prosjekt.Hovedkontrakter;

                $me.Prosjektnotater.val(result.Prosjekt.Prosjektnotater);

                tinymceInitPromise2.then(function () {
                    $me.Prosjektnotater.tinymce().setContent(result.Prosjekt.Prosjektnotater);
                });
            });
        };

        this.Hent = function (id) {
            return $app.APIGet("Admin/Prosjekt/Rediger", "Hent", { id: id });
        };
        this.TestMappenavn = function (id) {
            return $app.APIGet("Admin/Prosjekt/Rediger", "TestMappenavn", { hovedkontraktId: id });
        };

        this.TestSamtligeMappenavn = function (id) {
            return $app.APIGet("Admin/Prosjekt/Rediger", "TestSamtligeMappenavn", { prosjektId: id });
        };

        

        this.OppdaterOppføringsnummer = function (data) {
            return $app.APIPost("Admin/Prosjekt/Rediger", "OppdaterOppføringsnummer", data);
        };

        return this;
    };
})(jQuery);