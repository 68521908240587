﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminRolleOversikt = function ($app) { // <-- Constructor method
        var $me = this;


        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.VisSlettede.on("change", function () {
                $me.Refresh();
            });

            $me.LagreBtn.on("click", function () {
                var data = {
                    IdSortering: []
                };

                $me.Liste.find("input").each(function () {
                    var $input = $(this);

                    data.IdSortering.push($input.val());
                });

                $me.Lagre(data).done(function (result) {
                    $me.LagreBtn.addClass("disabled");
                    $.fn.Alert(result.Message, null, "h3");
                    $me.Refresh();
                });
            });

            $me.Refresh();

            $me.Liste.on("click", ".glyphicon-trash", function () {
                var id = $(this).closest("tr").children("input").val();
                var text = $(this).closest("tr").children("td").eq(0).text().trim();
                var rolle = $(this).closest("tr").children("td").eq(1).text().trim();
                var slettet = $(this).closest("tr").children("td").hasClass("text-danger");

                var verb = "";
                if (slettet)
                    verb = $me.Text2.text();
                else
                    verb = $me.Text3.text();

                $.fn.Confirm($me.Text1.text() + " " + verb + " " + $me.Text4.text() + " &laquo;" + rolle + "&raquo; ?", function () {
                    $me.SlettRolle(id).done(function (result) {
                        if (!result.Success && result.Message) {
                            $.fn.Alert(result.Message);
                            return;
                        }
                        $me.Refresh();
                    });
                });
            });

            $("#Liste").sortable({
                change: function () {
                    $me.LagreBtn.removeClass("disabled");
                },
                handle: ".draghandle"
            });
            $("#Liste").disableSelection();

        });

        this.Refresh = function () {
            var slettede = $me.VisSlettede.prop("checked");
            $me.HentOversikt(slettede).done(function (result) {
                $me.Liste.empty();
                var sortering = 1;
                $.each(result.Liste, function () {
                    $me.Liste.append("<tr>" +
                        "<td>" + this.Id + "</td>" +
                        "<td class=\"" + (this.Slettet ? "draghandle text-danger" : "draghandle") + "\"><span class=\"glyphicon glyphicon-stop text-" + this.FargeklasseCss + "\"></span> " + this.Navn + "</td>" +
                        "<td class=\"" + (this.Slettet ? "text-danger" : "") + "\">" + this.Beskrivelse + "</td>" +
                        "<td class=\"" + (this.Slettet ? "text-danger" : "") + "\">" + "" + sortering + "</td>" +
                        "<td class=\"" + (this.Slettet ? "nopadd text-danger" : "nopadd") + "\"><a class=\"btn btn-default\"><span class=\"glyphicon glyphicon-trash\"></span></a></td>" +
                        "<td class=\"" + (this.Slettet ? "nopadd text-danger" : "nopadd") + "\"><a class=\"btn btn-default\" href=\"#/Admin/Rolle/Rediger+" + this.Id + "\"><span class=\"glyphicon glyphicon-pencil\"></span></a></td>" +
                        "<input type=\"hidden\" name=\"id\" value=\"" + this.Id + "\" />" +
                        "</tr>"
                    );
                    sortering++;
                });
            });
        };

        this.HentOversikt = function (slettede) {
            return $app.APIGet("Admin/Rolle/Oversikt", "HentOversikt", { visSlettede: slettede }, { background: true });
        };

        this.Lagre = function (data) {
            return $app.APIPost("Admin/Rolle/Oversikt", "LagreSortering", data)
        };

        this.SlettRolle = function (id) {
            return $app.APIGet("Admin/Rolle/Oversikt", "SlettRolle", { id: id }, { background: true });
        };

        return this;
    };
})(jQuery);