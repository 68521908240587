﻿

(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminArkivoverføring = function ($app) { // <-- Constructor method
        var $me = this;

        var sideno = 0;
        var antallprside = 30;
        var oldsoketekst = null;
        var soketekst = "";

        var ajax = null;
        var timer = null;

        var $liste;
        var $kunfeil;
        var $visadmin;
        var $oppdater;

        var hovedprosjekter = [];
        var hovedkontrakter = [];

        var avbryt = false;
        var overfører = false;

        var load_hovedprosjekt = null;
        var load_hovedkontrakt = null;

        // Define the jquery class
        this.on("viewinit", function () {

            $liste = $me.Oppføringer;
            $visadmin = $me.VisAdmin;
            $oppdater = $me.BtnOppdater;

            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $(window).on("scroll", $me.HandleScroll);
            $me.on("click", ".deaktiver .btn", function () {
                var $tr = $(this).closest("tr");
                var id = $tr.attr("data-id");
                $.fn.Confirm($me.Text1.text(), function () {
                    $me.Deaktiver(id).done(function (result) {

                        if (result.Success) {
                            $tr.remove();
                        } else {
                            $.fn.Alert(result.Message);
                        }

                    });
                });
            });
            $me.Soketekst.bind("keyup click tap", function () {
                if (timer != null) clearTimeout(timer);

                var soketekst = $(this).val();

                if (soketekst == oldsoketekst) {
                    $liste.css("opacity", 1);
                    return;
                }

                $liste.css("opacity", 0.2);

                timer = setTimeout($me.ExecSearch, 500);
                return false;
            });
            $me.HovedprosjektSelect.on("change", function () {
                var $h = $(this);

                var hovedprosjekt = $h.val();

                $me.HovedkontraktSelect.empty();
                $me.HovedkontraktSelect.append("<option value=\"\">" + $me.Text2.text() + "</option>");

                if (!hovedprosjekt || hovedprosjekt.length == 0) {
                    $.each(hovedkontrakter, function () {
                        $me.HovedkontraktSelect.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                    });
                } else {
                    $.each(hovedprosjekter[hovedprosjekt].Hovedkontrakter, function () {
                        $me.HovedkontraktSelect.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                    });
                }
                $me.HovedkontraktSelect.val("");

                if (timer != null) clearTimeout(timer);
                $me.ExecSearch();
                return false;
            });
            $me.HovedkontraktSelect.on("change", function () {
                if (timer != null) clearTimeout(timer);
                $me.ExecSearch();
                return false;
            });
            $me.BtnOppdater.on("click", function () {
                if (timer != null) clearTimeout(timer);
                $me.ExecSearch();
                return false;
            });
            $me.HentHovedprosjekt().then(function (result) {

                var hprosjekt_found = false;
                var hovedkontrakt_found = false;

                $me.HovedprosjektSelect.append("<option value=\"\">" + $me.Text3.text() + "</option>");
                $.each(result.Hovedprosjekt, function () {

                    hovedprosjekter[this.Id] = this;
                    hovedkontrakter = hovedkontrakter.concat(this.Hovedkontrakter);

                    $me.HovedprosjektSelect.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");

                    if (load_hovedprosjekt) {
                        if (load_hovedprosjekt == this.Id) hprosjekt_found = true;
                    }
                });
                hovedkontrakter.sort($me.sorter);

                $me.HovedkontraktSelect.append("<option value=\"\">" + $me.Text4.text() + "</option>");
                $.each(hovedkontrakter, function () {
                    $me.HovedkontraktSelect.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");

                    if (load_hovedkontrakt) {
                        if (load_hovedkontrakt == this.Id) hovedkontrakt_found = true;
                    }
                });

                if (load_hovedprosjekt) {
                    if (!hprosjekt_found) {
                        $.fn.Alert($me.Text5.text() + " " + load_hovedprosjekt + " " + $me.Text6.text() + ".", null, "h3");
                    } else {
                        $me.HovedprosjektSelect.val(Number(load_hovedprosjekt));
                    }
                }
                if (load_hovedkontrakt) {
                    if (!hovedkontrakt_found) {
                        $.fn.Alert($me.Text7.text() + " " + load_hovedkontrakt + " " + $me.Text8.text() + ".", null, "h3");
                    } else {
                        $me.HovedkontraktSelect.val(Number(load_hovedkontrakt));
                    }
                }

                $me.LoadNextPage();
                $me.AttachSearch();
            });


            $me.BtnAvbryt.on("click", function () {
                avbryt = true;
                $me.BtnAvbryt.addClass("disabled");
            });

            $me.BtnOverfør.on("click", function () {

                var disableInterface = function () {
                    $me.BtnAvbryt.removeClass("disabled");

                    overfører = true;
                    $me.LoaderAlle.show();
                    $me.BtnOverfør.attr("disabled", "disabled");
                    $me.BtnOppdater.attr("disabled", "disabled");
                    $me.Soketekst.attr("disabled", "disabled");
                    $me.HovedprosjektSelect.attr("disabled", "disabled");
                    $me.HovedkontraktSelect.attr("disabled", "disabled");
                }

                disableInterface();

                $me.LoadAll();

                ajax.then(function () {
                    $me.PrøvAlle();
                });
            });
        });

        this.sorter = function (a, b) {
            var aName = a.Navn.toLowerCase();
            var bName = b.Navn.toLowerCase();
            return ((aName < bName) ? -1 : ((aName > bName) ? 1 : 0));
        }

        this.LoadItem = function (args) {
            var split = args.split("+");
            load_hovedprosjekt = split[0];
            load_hovedkontrakt = split[1];
        }

        this.PrøvAlle = function () {

            var enableInterace = function () {
                $me.BtnAvbryt.removeClass("btn-danger");
                $me.BtnAvbryt.addClass("disabled");

                overfører = false;
                $me.LoaderAlle.hide();
                $me.BtnOverfør.removeAttr("disabled");
                $me.BtnOppdater.removeAttr("disabled");
                $me.Soketekst.removeAttr("disabled");
                $me.HovedprosjektSelect.removeAttr("disabled");
                $me.HovedkontraktSelect.removeAttr("disabled");
            }

            var overfør = function ($tr) {

                if (!$tr || $tr.length == 0) {
                    enableInterace();
                    return;
                }

                if (avbryt) {
                    avbryt = false;
                    enableInterace();
                    return;
                }

                $tr.one("ferdig", function () {
                    overfør($tr.next());
                });

                var $btn = $tr.find(".overføring").find(".btn");

                if (!$btn || $btn.length == 0) {
                    enableInterace();
                    return;
                }

                $btn.trigger("click");
            }
            overfør($liste.find("tr").first());
        }

        this.HandleScroll = function () {
            if (ajax != null) return; // Already loading
            if (isScrolledIntoView($me.LoadMore)) {
                $me.LoadNextPage();
            }
            var $txt = $me.Soketekst;
            if ($txt.is(":focus") && !isScrolledIntoView($txt)) {
                $txt.blur();
            }
        };

        this.AddOppføring = function (item) {

            var $tr = $("<tr data-id=\"" + item.Id + "\"><td>" +
                item.Oppføringsnr + "</td><td>" +
                item.Tittel + "</td><td>" +
                item.Type + "</td><td>" +
                item.Hovedprosjekt + "</td><td>" +
                item.Hovedkontrakt + "</td>" +
                "<td class=\"deaktiver\" title=\"" + $me.Text9.text() + ".\"><span class=\"btn btn-warning\"><span class=\"glyphicon glyphicon-remove\"></span></span></td>" +
                "<td class=\"overføring\">" +
                "<span class=\"btn btn-default\" title=\"" + $me.Text10.text() + "\"><span class=\"glyphicon glyphicon-folder-close\"></span></span>" +
                "<div class=\"loader\" style=\"text-align: center;vertical-align: middle;padding-top:10px;display:none\"><div class=\"loadersmall\"></div></div>" +
                "</td><td>" +
                "<a target=\"_blank\" href=\"#/O+" + item.Id + "\" class=\"btn btn-default\" title=\"" + $me.Text11.text() + "\"><span class=\"glyphicon glyphicon-zoom-in\"></span></a>" + "</td><td>" +
                "</td></tr>");

            var $td = $tr.find(".overføring");
            var $btn = $td.find(".btn");
            var $loader = $td.find(".loader");
            var tsuccess = false;
            var waiting = false;

            $btn.on("click", function () {

                if (tsuccess) {
                    $tr.trigger("ferdig");
                    return;
                }

                if (waiting) {
                    return;
                }

                $btn.hide();
                $loader.show();

                $btn.removeAttr("title");

                waiting = true;

                $me.Overfør(item.Id).done(function (result) {

                    tsuccess = result.Success;
                    waiting = false;

                    $tr.trigger("ferdig");

                    $loader.hide();

                    if (!result.Success) {
                        $btn.addClass("text-danger");

                        $btn.show();

                        $btn.tooltip({
                            animation: false, //viktig, destro er 'async' så det kan oppstå feil når man gjenoppretter tooltip
                            placement: "right",
                            html: true,
                            template: '<div class="tooltip" role="tooltip" style="width:250px;max-width:250px;"><div class="tooltip-arrow"></div><div class="tooltip-inner alert-danger" style="background-color:#E33939;width:350px;max-width:350px;max-height:1000px;font-size:16px;"></div></div>',
                            title: result.Message
                        });
                    } else {
                        $btn.addClass("text-success");

                        $btn.show();

                        $btn.tooltip({
                            animation: false, //viktig, destro er 'async' så det kan oppstå feil når man gjenoppretter tooltip
                            placement: "right",
                            html: true,
                            template: '<div class="tooltip" role="tooltip" style="width:150px;max-width:150px;"><div class="tooltip-arrow"></div><div class="tooltip-inner alert-success" style="background-color:#28b62c;width:250px;max-width:250px;max-height:1000px;font-size:16px;"></div></div>',
                            title: result.Message
                        });

                        $btn.css({ "cursor": "default" });
                    }

                });
            });

            $liste.append($tr);
        };

        function isScrolledIntoView(elem) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var $e = $(elem);
            if (!$e.is(":visible")) return false;
            var elemTop = $e.offset().top;
            //var elemBottom = elemTop + $(elem).height();

            return elemTop < docViewBottom;
        }

        this.LoadAll = function () {
            sideno = 0;
            timer = null;

            $me.LoadNextPage(true);
        }

        this.LoadNextPage = function (all) {

            // pageno
            if (sideno == 0) $liste.html(""); // Nullstill listen
            $me.LoadMore.show();
            $liste.find(".empty").remove();
            $liste.css("opacity", 1);
            var hovedprosjekt = $me.HovedprosjektSelect.val() || null;
            var hovedkontrakt = $me.HovedkontraktSelect.val() || null;

            var antall = antallprside;
            if (all) antall = 2147483647;

            ajax = $me.HentOppføringer(soketekst, sideno, antall, hovedprosjekt, hovedkontrakt);

            ajax.done(function (result) {
                ajax = null;
                sideno++;
                oldsoketekst = soketekst;

                $me.Totalt.text(result.Antall);

                $.each(result.Oppføringer, function (index, item) {
                    $me.AddOppføring(item);
                });

                if (result.Oppføringer.length == antall) $me.HandleScroll();
                else {
                    $me.LoadMore.hide();

                    if (sideno == 1 && result.Oppføringer.length == 0)
                        $liste.append("<tr class=\"empty\"><td class=\"text-muted\" colspan=\"9\">" + $me.Text12.text() + "</td></tr>");
                }
            });

            $me.HandleScroll();
        };

        this.ExecSearch = function () {
            soketekst = $me.Soketekst.val();

            sideno = 0;
            timer = null;

            $me.LoadNextPage();
        };

        this.on("viewsuspended viewclosed", function () {
            $(document).off("scroll");
            $(document).off("keydown");
        });

        this.on("viewrestored", function () {
            $me.AttachSearch();
        });

        this.AttachSearch = function () {
            $(document).off("keydown");
            $(document).on("keydown", function (ev) {
                if (ev.which >= 33 && ev.which <= 40) return true; // Arrow keys
                var nodeName = ev.target.nodeName;
                if ("INPUT" == nodeName || "TEXTAREA" == nodeName || "SELECT" == nodeName) return;
                if ($(ev.target).attr("contenteditable") == "true") return;
                $me.Soketekst.val("").trigger("focus");
            });
        };

        this.Deaktiver = function (oppføringId) {
            return $app.APIGet("Admin/Arkivoverføring", "Deaktiver", { id: oppføringId });
        };

        this.Overfør = function (oppføringId) {
            return $app.APIGet("Admin/Arkivoverføring", "Overfør", { oppføringId: oppføringId }, { background: true });
        };

        this.HentHovedprosjekt = function () {
            return $app.APIGet("Admin/Arkivoverføring", "HentHovedprosjekt");
        };

        this.HentOppføringer = function (soketekst, sideno, antallprside, hovedprosjekt, hovedkontrakt) {
            return $app.APIGet("Admin/Arkivoverføring", "HentOppføringer", { soketekst: soketekst, sideno: sideno, antallprside: antallprside, hovedprosjekt: hovedprosjekt, hovedkontrakt: hovedkontrakt }, { background: true });
        };

        return this;
    };
})(jQuery);