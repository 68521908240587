﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminSkjemaRediger = function ($app) { // <-- Constructor method
        var $me = this;


        var $form = $me.find("form");
        var editid = null;

        // Define the jquery class
        this.on("viewinit", function () {
            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.find("[data-tab]").on("click", function () {
                var tabname = $(this).attr("data-tab");
                var $tab = $me.find("[id=" + tabname + "]");
                $me.find(".active").removeClass("active");
                $tab.addClass("active");
                $(this).addClass("active");
            });

            $me.KopiBtn.on("click", function () {
                $.fn.Confirm($me.Text1.text(), function () {
                    $me.Tittel.val($me.Tittel.val() + " kopi");
                    editid = 0;
                    $me.Versjon.val(1);
                    $me.LagreBtn.trigger("click");
                });
            });

            $me.LagreBtn.on("click", function () {
                var data = $form.Read();
                data.Id = editid;

                $(".skjemaerror").tooltip("destroy");
                $(".skjemaerror").hide();

                $me.Lagre(data).done(function (result) {

                    if (!result.Success)
                        $me.VisSkjemafeil(result);

                    if (result.Message) {
                        $.fn.Alert(result.Message, null, "h3");
                    }

                    if (result.Success) {
                        if (editid != result.Id) {
                            editid = result.Id;
                            $app.replaceHistory("#/Admin/Skjema/Rediger+" + editid);
                        }
                        $me.LoadItem(result.Id);
                    }


                });
            });

            $form = $form.smartform();


            $me.SourceBtn.on("click", function () {
                $me.Source.val(JSON.stringify($form.Read(), null, 3));
                $me.SourcePanel.modal();

            });
            $me.SourceUpdateBtn.on("click", function () {
                var json = $me.Source.val();
                try {
                    var o = eval("(" + json + ")");
                    $form.Write(o);
                    $me.SourcePanel.modal("hide");
                }
                catch (e) {
                    $.fn.Alert("Invalid JSON syntax: " + e);

                }
                return false;
            });

            $me.find(".eace").each(function () {
                var $txt = $(this);
                var id = $txt.attr("id");
                var $div = $("<div/>").attr("id", id + "_editor");
                $txt.after($div);
                $txt.hide();

                var fe = ace.edit(id + "_editor");
                fe.setOptions({ maxLines: Infinity });
                fe.setTheme("ace/theme/chrome");
                fe.getSession().setMode("ace/mode/html");
                $txt.on("change", function () {
                    fe.getSession().setValue($txt.val());
                });
                fe.getSession().on("change", function () {
                    $txt.val(fe.getSession().getValue());
                });
            });
        });

        this.VisSkjemafeil = function (result) {
            if (result.Skjemafeil) {

                var feilSkjemamalen = "";
                var feilArkiveringsmalen = "";
                var feilVisningsmalen = "";
                var feilVarslingsmalen = "";

                for (var i = 0; i < result.Skjemafeil.length; i++) {
                    var feil = result.Skjemafeil[i];

                    switch (feil["Mal"]) {
                        case "skjemamalen":
                            feilSkjemamalen += ("<li>" + feil["Feilmelding"] + "</li>");
                            break;
                        case "arkiveringsmalen":
                            feilArkiveringsmalen += ("<li>" + feil["Feilmelding"] + "</li>");
                            break;
                        case "visningsmalen":
                            feilVisningsmalen += ("<li>" + feil["Feilmelding"] + "</li>");
                            break;
                        case "varslingsmalen":
                            feilVarslingsmalen += ("<li>" + feil["Feilmelding"] + "</li>");
                            break;
                    }

                    if (feilSkjemamalen != "") {
                        $me.Skjemafeil.attr("data-original-title", "<ul>" + feilSkjemamalen + "</ul>");
                        $me.Skjemafeil.show();
                    }
                    if (feilArkiveringsmalen != "") {
                        $me.Arkiveringsmalfeil.attr("data-original-title", "<ul>" + feilArkiveringsmalen + "</ul>");
                        $me.Arkiveringsmalfeil.show();
                    }
                    if (feilVisningsmalen != "") {
                        $me.Visningsmalfeil.attr("data-original-title", "<ul>" + feilVisningsmalen + "</ul>");
                        $me.Visningsmalfeil.show();
                    }
                    if (feilVarslingsmalen != "") {
                        $me.Varslingsmalfeil.attr("data-original-title", "<ul>" + feilVarslingsmalen + "</ul>");
                        $me.Varslingsmalfeil.show();
                    }
                }

                $(".skjemaerror").tooltip({
                    animation: false, //viktig, destro er 'async' så det kan oppstå feil når man gjenoppretter tooltip
                    placement: "right",
                    html: true,
                    template: '<div class="tooltip" role="tooltip" style="width:500px;max-width:500px;"><div class="tooltip-arrow"></div><div class="tooltip-inner alert-danger" style="background-color:#E33939;width:500px;max-width:500px;max-height:1000px;font-size:16px;"></div></div>'
                });

            }
        };

        this.LoadItem = function (id) {
            $me.Hent(id).done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }

                $me.VisSkjemafeil(result);
                editid = id;
                $form.Write({});

                $form.Write(result.Skjema);
                $me.Tittel2.text(result.Skjema.Navn);
            });
        };

        this.Hent = function (id) {
            return $app.APIGet("Admin/Skjema/Rediger", "Hent", { id: id });
        };

        this.Lagre = function (data) {
            return $app.APIPost("Admin/Skjema/Rediger", "Lagre", data);
        };

        return this;
    };
})(jQuery);