﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.RapportOversiktsrapport = function ($app) { // <-- Constructor method
        var $me = this;
        this.graf = null;
        var sortering = 1;
        var reversert = false;
        // Få tak i hovedkontrakt Id
        var hovedkontraktId = null;
        var oppføringsstatusId = null;

        // Define the jquery class
        this.on("viewinit", function () {
            $me.find(".select2").select2({ theme: "bootstrap" });


            $("#Graf").on("click", function (evt) {

                var punkter = $me.graf.getElementsAtEvent(evt);

                if (punkter.length > 0) {
                    //Få tak i index for den delen av diagrammet som bruker klikket på
                    var indexForKlikketElement = punkter[0]["_index"];

                    hovedkontraktId = $me.Hovedkontrakt.val();

                    //Få tak i label (gruppe-Id)
                    oppføringsstatusId = $me.graf.data.labels[indexForKlikketElement].split("#")[0];

                    $me.Refresh();

                }
            });

            $me.Prosess.on("change", function () {
                var id = $(this).val();
                $me.Table.hide();

                if (typeof (id) == "undefined" || id == null) {
                    if ($me.graf != null) $me.graf.destroy();
                    return;
                }

                var prosess = $(this).find("option:selected").text();
                $me.GetProsessStatusAntall(id, $me.Hovedkontrakt.val()).done(function (result) {
                    if (!result.Success) {
                        $.fn.Alert(result.Message, null, "h3");
                        return;
                    }

                    var ctx = document.getElementById("Graf");

                    var labels = [];
                    var count = [];
                    var colors = [];

                    var index = 0;

                    $.each(result.ProsessStatuser, function () {
                        labels.push(this.Id + "#" + this.Navn);
                        count.push(this.Antall);

                        var colorIndex = index >= $app.Farger.length ? index % $app.Farger.length : index;
                        colors.push($app.Farger[colorIndex]);
                        index++;
                    });
                    var etiketter = [];

                    var options =
                        {
                            responsive: true,
                            lineTension: 0.1,
                            borderCapStyle: 'butt',
                            borderDash: [],
                            borderDashOffset: 0.0,
                            borderJoinStyle: 'miter',
                            pointBorderWidth: 1,
                            pointHoverRadius: 5,
                            pointRadius: 1,
                            pointHitRadius: 10,
                            pointHoverBorderWidth: 2,
                            tooltips: {
                                callbacks: {
                                    title: function (element, data) {
                                        var text = labels[element[0].index];
                                        return text.split("#")[1];
                                    },
                                }
                            },
                            animation: {
                                onProgress: function () {
                                    var chartInstance = this.chart;
                                    var ctx = chartInstance.ctx;
                                    var height = chartInstance.controller.boxes[0].bottom;
                                    ctx.textAlign = "center";
                                    Chart.helpers.each(this.data.datasets.forEach(function (dataset, i) {
                                        var meta = dataset.metaData;
                                        Chart.helpers.each(meta.forEach(function (bar, index) {
                                            if (dataset.data[index] != 0) {
                                                ctx.font = "normal 14pt arial";
                                                ctx.fillStyle = "white";
                                                var tHeight = (bar._model.base - ((bar._model.base - bar._model.y) / 2)) - 10;
                                                ctx.fillText(dataset.data[index], bar._model.x, tHeight);
                                            }
                                        }), this)
                                    }), this);
                                }
                            },
                            scales: {
                                xAxes: [{
                                    ticks: {
                                        autoSkip: false,
                                        userCallback: function (value, index, values) {
                                            return value.split("#")[1];
                                        },

                                    }

                                }],
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }]
                            }

                        };


                    var data = {
                        labels: labels,
                        datasets: [
                            {
                                label: prosess,
                                fill: false,
                                //options: options,
                                backgroundColor: colors,
                                borderColor: "rgba(75,192,192,1)",
                                pointBorderColor: "rgba(75,192,192,1)",
                                pointBackgroundColor: "#fff",
                                pointHoverBackgroundColor: colors,
                                pointHoverBorderColor: colors,
                                data: count,
                            }
                        ]
                    };

                    if ($me.graf != null) $me.graf.destroy();
                    $me.graf = new Chart(ctx, { type: "bar", data: data, options: options });

                });
            });

            $me.Hovedkontrakt.on("change", function () {
                var id = $(this).val();
                $me.Prosess.empty();
                $me.Table.hide();
                if (typeof (id) == "undefined" || id == null) {
                    if ($me.graf != null) $me.graf.destroy();
                    return;
                }
                $me.GetProsesser(id).done(function (result) {
                    if (!result.Success) {
                        $.fn.Alert(result.Message, null, "h3");
                        return;
                    }

                    $.each(result.Prosesser, function () {
                        $me.Prosess.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                    });

                    $me.Prosess.trigger("change");
                });

            });

            $me.Prosjekt.on("change", function () {
                var id = $(this).val();
                $me.Hovedkontrakt.empty();
                $me.Table.hide();
                if (typeof (id) == "undefined" || id == null) {
                    if ($me.graf != null) $me.graf.destroy();
                    return;
                }
                $me.GetHovedkontrakter(id).done(function (result) {
                    if (!result.Success) {
                        $.fn.Alert(result.Message, null, "h3");
                        return;
                    }

                    $.each(result.Hovedkontrakter, function () {
                        $me.Hovedkontrakt.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                    });

                    $me.Hovedkontrakt.trigger("change");
                });
            });

            $me.GetProsjekter().done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }

                $.each(result.Prosjekter, function () {
                    $me.Prosjekt.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                });

                $me.Prosjekt.trigger("change");
            });

            $me.find("[data-s]").each(function () {
                $(this).append("<span title=\"Sortert stigende\" class=\"glyphicon glyphicon-triangle-top sortdown\"></span>");
                $(this).append("<span title=\"Sortert synkende\" class=\"glyphicon glyphicon-triangle-bottom sortup\"></span>");
            });
            $me.find("[data-s]").on("click", function () {
                var nysortering = $(this).attr("data-s");
                if (nysortering != Math.abs(sortering)) {
                    // Sorter på annen kolonne
                    reversert = false;
                    sortering = nysortering;

                } else {
                    // Reverser gjeldende sortering
                    sortering = sortering * -1;
                    reversert = reversert == true ? false : true;
                }
                $me.find("[data-s]").attr("class", null);

                $(this).addClass("sort" + (reversert ? "" : "rev"));
                $me.setHistory();
                $me.Refresh();
            });

        });

        this.setHistory = function () {
            $app.replaceHistory("#/Rapport/Oversiktsrapport/Oversikt+" + Math.abs(sortering) + "-" + reversert + "-" + $(window).scrollTop(), "");
        };

        this.LoadItem = function (inn) {
            var args = inn.split("-");
            if (args[0]) sortering = parseInt(args[0]);
            if (args[1]) reversert = args[1] == "true";
            if (reversert == true && sortering > 0)
                sortering *= -1;
            if (args[2]) scrollTop = args[2];
            $me.find(".sortrev").removeClass("sortrev");
            $me.find(".sort").removeClass("sort");
            $me.find("[data-s=" + (sortering < 0 ? sortering * -1 : sortering) + "]").addClass(reversert == true ? "sort" : "sortrev");

            //$me.Refresh();
        };

        this.Refresh = function () {
            $me.HentListeMedOppføringer(oppføringsstatusId, hovedkontraktId, sortering, reversert).done(function (result) {
                if (!result.Success && result.Message) {
                    $.fn.Alert(result.Message);
                    return;
                }

                var l = result.Oppføringsliste;

                $me.Tbody.empty();

                $.each(result.Oppføringsliste, function (index, l) {
                    $me.Tbody.append(
                        "<tr>" +

                        "<td>" + l.Oppføringsnr + "</td>" +
                        "<td>" + l.Tittel + "</td>" +
                        "<td>" + formatDateTime(l.Opprettet) + "</td>" +
                        "<td>" + formatDateTime(l.Innsendt) + "</td>" +
                        "<td>" + (l.Arkivert ? formatDateTime(l.Arkivert) : "") + "</td>" +
                        "<td class=\"nopadd\"><a href=\"#/O+" + l.Id + "\" class=\"btn btn-default\"><span class=\"glyphicon glyphicon-zoom-in\"></span></a></td>" +
                        "</tr>"

                    );

                });

                $me.Table.show();

                $me.Tbody.find("tr").on("dblclick", function () {
                    var $a = $(this).find("a[href]");
                    if ($a.attr("href")) {
                        location = $a.attr("href");
                    }
                });
            });
        };

        this.GetProsjekter = function () {
            return $app.APIGet("Rapport/Oversiktsrapport", "GetProsjekter");
        }

        this.GetHovedkontrakter = function (prosjektId) {
            return $app.APIGet("Rapport/Oversiktsrapport", "GetHovedkontrakter", { id: prosjektId });
        }

        this.GetProsesser = function (hovedkontraktId) {
            return $app.APIGet("Rapport/Oversiktsrapport", "GetProsesser", { id: hovedkontraktId });
        }

        this.GetProsessStatusAntall = function (prosessId, hovedkontraktId) {
            return $app.APIGet("Rapport/Oversiktsrapport", "GetProsessStatusAntall", { prosessId: prosessId, hovedkontraktId: hovedkontraktId });
        }

        this.HentListeMedOppføringer = function (oppføringsstatusId, hovedkontraktId, sortering, reversert) {
            return $app.APIGet("Rapport/Oversiktsrapport", "HentListeMedOppføringer", { oppføringsstatusId: oppføringsstatusId, hovedkontraktId: hovedkontraktId, sortering: sortering, reversert: reversert });
        };

        return this;
    };
})(jQuery);