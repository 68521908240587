﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.InnboksBehandle = function ($app) { // <-- Constructor method
        var $me = this;
        var _Id = null;
        var editId = null;
        var sistEndret = null;
        var $skjema = null;
        var skjemaverdier = [];
        var alternativ = null;
        var alternativer = [];
        var oldjson = null;
        var oldrelaterte = null;
        var downloadurl = null;
        var dowloadurl2 = null;
        var $relaterte = null;
        var loadedRelaterte = null;
        var visVedleggsoppføringer = false;
        var oppføringsnr = null;
        var redigerSerienummer = null;
        var oldMerknad = null;
        var valgtskjema = null;
        var valgtskjemaId = 0;
        var viserMeldingId = null;
        var representantDropdown = [];
        var oppføringSlettet = false;

        var tillatSkjemakopieringLookup = {};
        var skjemakopiert = false;
        var forrigeskjema = null;
        var kopiertskjemaId = 0;
        var sendingkopiertskjema = false;

        var oldlokasjoner = null;
        var $lokasjoner = null;
        var kart = null;
        var kartLastet = false;
        var markers = [];
        var infoWindow = null;

        var skrivebeskyttetGjeldendeProsesstrinn = false;
        var skrivebeskyttetMedAlleRoller = false;

        var tinymceInitPromise = $.Deferred();

        // Define the jquery class
        this.on("viewinit", function () {

            $me.BtnOverførTilArkiv.on("click", function () {
                $me.OverførTilArkiv(editId).done(function (result) {
                    $.fn.Alert(result.Message);
                });
            });

            tinymce.init({
                selector: "#Merknader",
                theme: "modern",
                themeToolbar1: "undo redo | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link\"",
                plugins: "link code paste table lists hr autoresize textcolor",
                insert_toolbar: "",
                menubar: false,
                statusbar: false,
                content_css: "Edialog.css",
                paste_preprocess: function (pl, o) {
                    if (o.content.indexOf("<img ") >= 0) o.content = "";
                    //timer = setTimeout($me.TimerFunk, 450);
                },
                setup: function (ed) {
                    ed.on("change input", function (e) {
                        var verdi = tinyMCE.get("Merknader").getContent();
                        $me.Merknader.val(verdi).trigger("change");
                    });
                    ed.on("init", function () {
                        var verdi = $me.Merknader.val();
                        $me.Merknader.tinymce().setContent(verdi);
                        tinymceInitPromise.resolve();
                    });
                },
                paste_data_images: false,
                paste_as_text: false,
                branding: false
            });

            $me.SourceBtn.on("click", function () {
                var nyttnr = prompt("Endre serienummer", redigerSerienummer);
                if (nyttnr != null) {
                    $me.EndreSerienummer(editId, nyttnr).done(function (result) {
                        if (!result.Success) {
                            $.fn.Alert(result.Message);
                            return false;
                        }
                        $me.LoadItem(editId);
                    });
                }
            });

            $me.RelaterteTabell.on("attach", function (e, $element, $form) {
                function finditem(id) {
                    var result = { id: id };
                    $.each(loadedRelaterte, function () {
                        if (this.Id == id) {
                            result = this;
                            result.id = result.Id;
                            return;
                        }
                    });
                    return result;
                }
                function formatItem(item) {
                    if (item.loading) { return $("<div><img src=\"Images/hourglass.svg\" style=\"height:1em\" /> " + $me.Tekst_28.text() + " ...</div>"); }
                    if (!item.id && item.Id) item.id = item.Id;
                    if (!item.Tittel) item = finditem(item.id);
                    if (!item.Status) item.Status = $me.Tekst_2.text();
                    var id = item.Oppføringsnr;
                    if (id == null) id = "utkast";
                    if (!item.Tittel) return $("<div>(" + $me.Tekst_1.text() + ")</div>");

                    var $item = $("<div class=\"select2-result-repository clearfix\"><span class=\"label label-default\">" + id + "</span> <b>" + item.Tittel + "</b> (" + item.Status + ")</div>");
                    return $item;
                };
                $element.find(".open").on("click", function () {
                    var id = $(this).closest("tr").find("select").val();
                    window.open("#/O+" + id);
                });

                if (skrivebeskyttetMedAlleRoller) {
                    $element.find("[data-name=\"Kommentar\"]").attr("contenteditable", false);
                    $element.find("[data-action=\"remove\"]").attr("disabled", true);
                    $element.find("[data-action=\"remove\"]").off("click");
                }

                $element.find("select").select2({
                    templateResult: formatItem,
                    templateSelection: formatItem,
                    disabled: skrivebeskyttetMedAlleRoller,
                    theme: "bootstrap",
                    placeholder: "(" + $me.Tekst_3.text() + ")",
                    ajax: {
                        url: "/API/InnboksBehandle/SokRelaterte",
                        dataType: "json",
                        delay: 250,
                        data: function (params) {
                            var side = params.page ? params.page : 0;

                            return {
                                auth: "",
                                id: editId,
                                sok: params.term ? params.term : "", // search term
                                side: side,
                                sideantall: 30
                            };
                        },
                        processResults: function (data, params) {
                            params.page = params.page || 1;
                            $.each(data.Liste, function () {
                                this.id = this.Id;
                            });
                            return {
                                results: data.Liste,
                                pagination: {
                                    more: (params.page * 30) < data.Antall
                                }
                            };
                        },
                        cache: false
                    },
                });
            });
            $relaterte = $me.RelaterteTabell.smartform();

            $lokasjoner = $me.LokasjonTabell.smartform();

            $me.LastNedPdfBtn.on("click", function () {
                $me.LastNedPdf(editId);

                return false;
            });

            $me.LastNedPdfMeldingshistorikkBtn.on("click", function () {
                $me.LastNedPdfMeldingshistorikk(editId);
            });

            $me.LastNedZipBtn.on("click", function () {
                $me.LastNedZip(editId);
            });

            $me.KopierForrigeBtn.on("click", function () {
                $me.Lagre(false).done(function (result) {
                    $me.KopierForrigeSkjema(editId, alternativ).done(function (result) {
                        if (!result.Success) {
                            $.fn.Alert(result.Message, null, "h3");
                        }
                        $me.LoadItem(editId);
                    });
                });
            });

            $me.LastNedDocxBtn.on("click", function () {
                $me.LastNedDocx(editId);
                return false;
            });

            $me.SlettSisteMeldingBtn.on("click", function () {
                $.fn.Confirm($me.Msg_1.text(), function () {
                    $me.SlettSisteMelding(editId).done(function (result) {
                        if (!result.Success) {
                            $.fn.Alert(result.Message, null, "h3");
                        } else {
                            $.fn.Alert($me.Msg_2.text(), null, "h3");
                            $me.LoadItem(editId);
                        }

                    });
                });
            });

            $me.find("[data-tab]").on("click", function () {
                var tabnavn = $(this).attr("data-tab");
                $me.find(".tabpage.active,.tab-pane.active").removeClass("active");

                $me.find("[data-tab=\"" + tabnavn + "\"]").not(".btn").addClass("active");
                var $tab = $me.find("[id=" + tabnavn + "]");
                $tab.addClass("active");
                if (tabnavn == "Info" && visVedleggsoppføringer == true)
                    $me.Vedleggsoppføringer.show();
                else
                    $me.Vedleggsoppføringer.hide();
            });

            $me.Info.on("change", function () {
                $me.Endringermelding.attr("class", "text-warning").show();
                $me.Endringermelding2.attr("class", "text-warning").show();

                var representanter = [];

                $me.Info.find("select.representant").each(function () {
                    var $h = $(this);
                    var rolleid = $h.data("rolleid");

                    if (rolleid) {
                        $me.find("select.representant[data-rolleid=\"" + rolleid + "\"]").not(this).val($(this).val());
                        representanter.push({ RolleId: rolleid, BrukerId: $h.val() });
                    }
                });

                $me.OppdaterInfo(editId, $me.Prioritet.val(), $me.Ferdighetsgrad.val(), representanter).done(function (result) {
                    if (result.Message && !result.Success) {
                        $.fn.Alert(result.Message, null, "h3");
                        return;
                    }
                    $me.Endringermelding.attr("class", "text-success");
                    $me.Endringermelding2.attr("class", "text-success");
                });
            });

            $me.Oppfolging.on("change input", function () {
                $me.Endringermelding3.attr("class", "text-warning").show();

                var data = {
                    id: editId,
                    krevet: $me.KravBeløp.val(),
                    antatt: $me.AntattBeløp.val(),
                    fakturert: $me.FakturertBeløp.val(),
                    omforentUavklartBeløp: $me.OmforentUavklartBeløp.val(),
                    omforentBeløp: $me.OmforentBeløp.val(),
                    opprinneligKravBeløp: $me.OpprinneligKravBeløp.val(),
                    frafaltBeløp: $me.FrafaltBeløp.val(),
                    utgårFraKontraktBeløp: $me.UtgårFraKontraktBeløp.val(),
                    reellMerkostnadBeløp: $me.ReellMerkostnadBeløp.val(),
                    avklartØkonomiOgTidBeløp: $me.AvklartØkonomiOgTidBeløp.val(),

                    FakturertDato: $me.FakturertDato.val(),
                    AndreEnnBH: $me.AndreEnnBH.prop("checked"),
                    FerdigFakturert: $me.FerdigFakturert.prop("checked"),
                    UE: $me.UE.val(),
                    ReferanseFraBH: $me.ReferanseFraBH.val(),
                    ReferanseUE: $me.ReferanseUE.val(),
                    Tilvalg: $me.Tilvalg.val(),
                    GodkjentBeløp: $me.GodkjentBeløp.val(),
                    OmtvistetBeløp: $me.OmtvistetBeløp.val(),
                    ForventetGodkjentBeløp: $me.ForventetGodkjentBeløp.val(),
                    GjenståendeKostnad: $me.GjenståendeKostnad.val(),
                    FakturertGodkjent: $me.FakturertGodkjent.val(),
                    EtatEier: $me.EtatEier.val(),
                    KrevetFristforlengelse: $me.KrevetFristforlengelse.val(),
                    OmforentFristforlengelse: $me.OmforentFristforlengelse.val()
                };

                $me.OppdaterOppfølging(data).done(function (result) {
                    if (result.Message && !result.Success) {
                        $.fn.Alert(result.Message, null, "h3");
                        return;
                    }
                    $me.Endringermelding3.attr("class", "text-success");
                });
            });

            var merknaderUpdate = null;
            $me.Merknader.on("change input", function () {
                var newText = $me.Merknader.val();
                if (newText === oldMerknad) return;

                $me.Endringermelding4.attr("class", "text-warning").show();
                if (merknaderUpdate != null) {
                    clearTimeout(merknaderUpdate);
                }
                merknaderUpdate = setTimeout(function () {
                    merknaderUpdate = null;
                    var text = $me.Merknader.val();
                    $me.OppdaterMerknader(editId, text).done(function (result) {
                        if (result.Message && !result.Success) {
                            $.fn.Alert(result.Message, null, "h3");
                            return;
                        }
                        oldMerknad = text;
                        $me.Endringermelding4.attr("class", "text-success");
                    });
                }, 1000);
            });

            $me.MerkSomSlettetBtn.on("click", function () {
                $.fn.Confirm($me.Msg_3.text(), function () {
                    $me.MerkSomSlettet(editId).done(function (result) {
                        if (!result.Success) {
                            $.fn.Alert(result.Message, null, "h3");
                            return;
                        }
                        $me.LoadItem(editId);
                    });
                });
            });

            $me.SlettBtn.on("click", function () {
                $.fn.Confirm($me.Msg_4.text(), function () {
                    $me.Slett(editId).done(function (result) {
                        if (!result.Success) {
                            $.fn.Alert(result.Message, null, "h3");
                            return;
                        }
                        if (result.Success && result.Arkivert) {
                            $.fn.Alert($me.Msg_5.text(), function () {
                                navback();
                            });
                            return;
                        }
                        navback();
                    });
                });
            });

            $me.Skjema.on("modify", function () {
                $me.Endringermelding.attr("class", "text-warning");
            });
            $me.Skjema.on("modified", function () {
                if (editId == null || $skjema == null) return;
                var dataobj = $skjema.Read();
                var data = JSON.stringify(dataobj);
                if (oldjson != data) {
                    $me.Lagre(false, data).done(function (result) {
                        if (!result.Success) {
                            return;
                        }
                        if (result.Rewrite) {
                            $skjema.Write(dataobj);
                        }
                        if (skjemakopiert) {
                            if (valgtskjemaId != kopiertskjemaId) skjemakopiert = false;
                        }

                        $me.Endringermelding.attr("class", "text-success");

                        if (result.Oppføringstittel) {
                            $me.Tittel2.text(result.Oppføringstittel + " - " + result.Oppføringstype);
                        }

                        $me.OppdaterMeldinger(result.Oppføring.Meldinger);

                        skjemaverdier[alternativ] = data;
                    });
                } else {
                    $me.Endringermelding.attr("class", "text-muted");
                }
            });
            $me.RelaterteTabell.on("modified", function () {
                if (oldrelaterte == null) return;

                var data = $relaterte.Read();
                var newjson = JSON.stringify(data);
                if (oldrelaterte == newjson) return;

                data.Id = editId;
                $me.LagreRelaterte(data).done(function (result) {
                    if (!result.Success) {
                        $.fn.Alert(result.Message);
                        $me.LoadItem(editId);
                        return;
                    }
                    $me.Relaterteantall.text(data.Relaterte.length.toString());
                });
                oldrelaterte = newjson;
            });

            $me.LokasjonTabell.on("modified", function () {
                if (oldlokasjoner == null) return;

                var data = $lokasjoner.Read();
                var newjson = JSON.stringify(data);
                if (oldlokasjoner == newjson) return;

                data.Id = editId;
                $me.LagreLokasjoner(data).done(function (result) {
                    if (result.Success == false) {
                        $.fn.Alert(result.Message, function () {
                            location.reload();
                        });
                        return;
                    }

                    $me.Lokasjonantall.text(result.length.toString());

                    $.each(data.Lokasjoner, function (index, item) {
                        if (!Number.isInteger(item.Id)) {
                            $.each(result, function (index2, item2) {
                                if (item.Guid == item2.Guid) {
                                    item.Id = item2.Id;
                                    $me.find("input[name=\"Id\"][value=\"" + item.Guid + "\"]").val(item.Id);
                                }
                            });
                        }
                        markers[item.Guid].setTitle(item.Beskrivelse);
                    });

                    oldlokasjoner = JSON.stringify({ Lokasjoner: data.Lokasjoner });
                });

            });

            $lokasjoner.on("removed", function (e, index, $arr, $removedItem) {
                var guid = $removedItem.find("input[name='Guid']").val();
                if (!guid) return;
                markers[guid].setMap(null);

                var lokasjoner = $lokasjoner.Read();
                $.each(lokasjoner.Lokasjoner, function (index, item) {
                    item.Nr = index + 1;
                    markers[item.Guid].setLabel(item.Nr.toString());
                });
                $lokasjoner.Write(lokasjoner);
            });

            $me.Skjema.on("submit", function () {
                return false;
            });

            $me.SendBtn.on("click", $me.UtforHandling);

            $me.Ansvarsmatrisefane.on("click", function () {
                $me.TegnDiagram()
            });

            $me.Knappetekster.on("click", function () {
                $me.toggleClass("visknappetekster");
            });

            $me.LukkModalBtn.on("click", function () {
                $me.KopierSendBtn.hide();
            });

            $me.KopierSendBtn.on("click", function () {
                sendingkopiertskjema = true;
                $me.KopierSendBtn.hide();
                $me.UtforHandling();
            });

            $me.Lokasjonfane.on("click", function () {
                $me.LastKart();

            });

            $me.NyLokasjonBtn.on("click", function () {
                kart.setOptions({ draggableCursor: 'crosshair' });
                $me.NyLokasjonInfo.css("display", "inline-block");

                kart.addListener("click", function (event) {
                    var lokasjoner = $lokasjoner.Read().Lokasjoner;

                    var lat = event.latLng.lat();
                    var lng = event.latLng.lng();
                    var Guid = guid();
                    var item = {
                        Nr: lokasjoner.length + 1,
                        Id: Guid,
                        Guid: Guid,
                        Breddegrad: lat,
                        Lengdegrad: lng,
                        Beskrivelse: ""
                    };

                    lokasjoner.push(item);
                    $lokasjoner.Write({ Lokasjoner: lokasjoner });

                    $me.LeggTilMarker(item);

                    $me.AvbrytNyLokasjon();
                });
            });



            $me.on("click", ".visikart", function () {
                var id = $(this).closest("tr").find("input[name=\"Id\"]").val();
                $.each($lokasjoner.Read().Lokasjoner, function (index, item) {
                    if (item.Id == id) {
                        kart.panTo(new google.maps.LatLng(parseFloat(item.Breddegrad), parseFloat(item.Lengdegrad)));

                        markers[item.Guid].setAnimation(google.maps.Animation.BOUNCE);
                        setTimeout(function () {
                            markers[item.Guid].setAnimation(null);
                        }, 1000);
                    }
                });
            });

            $me.AvbrytNyLokasjonBtn.on("click", function () {
                $me.AvbrytNyLokasjon();
            });

        });

        this.AvbrytNyLokasjon = function () {
            google.maps.event.clearListeners(kart, "click");
            kart.setOptions({ draggableCursor: '' });
            $me.NyLokasjonInfo.hide();
        };

        this.LastKart = function () {
            var scriptid = "google_maps_script_tag";
            var API_KEY = $app.GoogleMapsAPIKey;

            if (!document.head.querySelector("script#" + scriptid)) {
                var script = document.createElement("script");
                script.id = scriptid;
                script.src = "https://maps.googleapis.com/maps/api/js?key=" + API_KEY + "&callback=initMap";
                document.head.appendChild(script);
            } else if (!kartLastet) {
                window.initMap();
            }
        };

        window.initMap = function () {
            kartLastet = true;

            var latCenter = 64.204605;
            var lngCenter = 13.173096;
            var zoom = 4;

            var lokasjoner = $lokasjoner.Read().Lokasjoner;

            if (lokasjoner.length > 0) {
                var latSum = 0;
                var lngSum = 0;
                $.each(lokasjoner, function (index, item) {
                    latSum += parseFloat(item.Breddegrad);
                    lngSum += parseFloat(item.Lengdegrad);
                });

                latCenter = latSum / lokasjoner.length;
                lngCenter = lngSum / lokasjoner.length;
                zoom = 12;
            }

            kart = new google.maps.Map(document.getElementById('Kart'), {
                center: { lat: latCenter, lng: lngCenter },
                zoom: zoom
            });

            $.each(lokasjoner, function (index, item) {
                $me.LeggTilMarker(item);
            });
        };

        this.LeggTilMarker = function (item) {
            markers[item.Guid] = new google.maps.Marker({
                position: { lat: parseFloat(item.Breddegrad), lng: parseFloat(item.Lengdegrad) },
                label: item.Nr.toString(),
                title: item.Beskrivelse,
                draggable: !oppføringSlettet && !skrivebeskyttetMedAlleRoller,
                map: kart
            });
            markers[item.Guid].addListener("dragend", function (e) {
                var lat = this.getPosition().lat();
                var lng = this.getPosition().lng();
                var lokasjoner = $lokasjoner.Read().Lokasjoner;

                $.each(lokasjoner, function (index2, item2) {
                    if (item2.Guid == item.Guid) {
                        item2.Breddegrad = lat;
                        item2.Lengdegrad = lng;
                    }
                    $lokasjoner.Write({ Lokasjoner: lokasjoner });
                });
            });

            markers[item.Guid].addListener("click", function (e) {
                if (infoWindow == null) {
                    infoWindow = new google.maps.InfoWindow();
                }

                infoWindow.setContent(this.getTitle());
                infoWindow.open(kart, this);
            });
        };

        var removeTiny = false;
        this.on("viewclosed", function () {
            if (!removeTiny) {
                tinymceInitPromise.then(function () {
                    $me.Merknader.tinymce().remove();
                });
                removeTiny = true;
            }
        });

        this.OppdaterOppføring = function (result, leder, admin) {
            $me.Oppføringsnr.text(result.Oppføringsnr != null ? result.Oppføringsnr : "utkast");
            oppføringsnr = result.Oppføringsnr;
            $me.Tittel2.text(result.Tittel + " - " + result.Oppføringstype);
            $me.Status.text("(" + result.Status + ")");
            $me.Statusnavn.text(result.Status);
            $me.Hovedkontrakt.text(result.Hovedkontrakt);
            if (admin) {
                $me.Prosjekt.html("<a href=\"#Admin/Prosjekt/Rediger+" + result.ProsjektId + "\" target=\"_blank\">" + result.Prosjekt + "</a>");
            } else {
                $me.Prosjekt.text(result.Prosjekt);
            }
            $me.Ferdighetsgrad.val(result.Ferdighetsgrad);
            $me.Prioritet.val(result.Prioritet);

            if (leder) {
                $me.Merknader.text(result.Merknader);

                tinymceInitPromise.then(function () {
                    $me.Merknader.tinymce().setContent(result.Merknader);
                });

                oldMerknad = result.Merknader;
            }

            if (result.Beløp != null) {
                // Felter tilgjengelig for brukere med rolle leder eller oppfølging
                
                $me.KravBeløp.val(result.Beløp.KravBeløp);
                $me.AntattBeløp.val(result.Beløp.AntattBeløp);
                $me.FakturertBeløp.val(result.Beløp.FakturertBeløp);

                $me.OmforentUavklartBeløp.val(result.Beløp.OmforentUavklartBeløp);
                $me.OmforentBeløp.val(result.Beløp.OmforentBeløp);
                $me.OpprinneligKravBeløp.val(result.Beløp.OpprinneligKravBeløp);
                $me.FrafaltBeløp.val(result.Beløp.FrafaltBeløp);
                $me.UtgårFraKontraktBeløp.val(result.Beløp.UtgårFraKontraktBeløp);
                $me.ReellMerkostnadBeløp.val(result.Beløp.ReellMerkostnadBeløp);
                $me.AvklartØkonomiOgTidBeløp.val(result.Beløp.AvklartØkonomiOgTidBeløp);

                $me.GodkjentBeløp.val(result.Beløp.GodkjentBeløp);
                $me.OmtvistetBeløp.val(result.Beløp.OmtvistetBeløp);
                $me.ForventetGodkjentBeløp.val(result.Beløp.ForventetGodkjentBeløp);
                $me.GjenståendeKostnad.val(result.Beløp.GjenståendeKostnad);
                $me.FakturertGodkjent.val(result.Beløp.FakturertGodkjent);
            }

            if (result.Oppfølging != null) {
                $me.FakturertDato.val(result.Oppfølging.FakturertDato);

                if (result.Oppfølging.AndreEnnBH != null)
                    $me.AndreEnnBH.prop('checked', result.Oppfølging.AndreEnnBH);

                if (result.Oppfølging.FerdigFakturert != null)
                    $me.FerdigFakturert.prop('checked', result.Oppfølging.FerdigFakturert);

                $me.UE.val(result.Oppfølging.UE);
                $me.ReferanseFraBH.val(result.Oppfølging.ReferanseFraBH);
                $me.ReferanseUE.val(result.Oppfølging.ReferanseUE);
                $me.Tilvalg.val(result.Oppfølging.Tilvalg);

                if (result.Oppfølging.UEOptions != null && result.Oppfølging.UEOptions.length) {
                    $me.UE.autocomplete({
                        source: result.Oppfølging.UEOptions,
                        delay: 0,
                        select: function (event, ui) {
                            setTimeout(function () {
                                $me.UE.trigger("input");
                            }, 0)
                        }
                    });
                }

                $me.EtatEier.val(result.Oppfølging.EtatEier);

                $me.KrevetFristforlengelse.val(result.Oppfølging.KrevetFristforlengelse);
                $me.OmforentFristforlengelse.val(result.Oppfølging.OmforentFristforlengelse);

            }

            if (result.LiggerhosRolle && result.Oppføringsnr) {
                // Oppføringsdetaljer:
                $me.LiggerhosRolle.empty();
                $me.LiggerhosRolle.text(result.LiggerhosRolle);
                $me.LiggerhosRolle.prepend(" ");
                if (result.LiggerhosCss) $me.LiggerhosRolle.prepend("<span class=\"glyphicon glyphicon-stop text-" + result.LiggerhosCss + "\"></span>");
                // Meldinger:
                $me.LiggerhosRolle2.empty();
                $me.LiggerhosRolle2.text(result.LiggerhosRolle);
                $me.LiggerhosRolle2.prepend(" ");
                if (result.LiggerhosCss) $me.LiggerhosRolle2.prepend("<span class=\"glyphicon glyphicon-stop text-" + result.LiggerhosCss + "\"></span>");

            }

            if (result.DineRoller) {

                if (result.DineRoller.length == 1) {
                    $me.DineRoller.find("b").text($me.Tekst_30.text());
                }

                var $h = $me.DineRoller.find("div").first();
                $h.empty();

                var komma = "";
                $.each(result.DineRoller, function () {
                    $h.append(komma + "<span style=\"white-space: nowrap;\"><span class=\"glyphicon glyphicon-stop text-" + this.CSS + "\"></span>&nbsp;" + this.Navn + "</span>");
                    komma = ", ";
                });
            }

            $me.Svarfrist.text(formatDateTime(result.Svarfrist));
            $me.OpprettetAv.text(result.OpprettetAv);
            $me.Dato.text(result.Innsendt != null ? formatDate(result.Innsendt) : "-");

            if (result.Arkivert && leder) {
                $me.Overført.find("div").first().text(result.OverførtArkiv ? $me.Yes_1.text() : $me.No_1.text());
                $me.Overført.show();
            }

        };
        this.OppdaterHandlinger = function (handling) {
            sistEndret = handling.SistEndret;
            skjemaverdier[handling.Alternativ] = handling.Skjemaverdier;

            skjemakopiert = handling.KopiertForrigeSkjema;
            forrigeskjema = handling.ForrigeSkjema;
            kopiertskjemaId = handling.SkjemaIdKopiertSkjema;

            if (handling == null || handling.Alternativer.length == 0) {
                //$me.Skjemafane.hide();
                //$me.Infofane.addClass("active");
                //$me.Info.addClass("active");
            }

            alternativ = handling.Alternativ;

            $me.Alternativer.off("change");
            try {
                if ($me.Alternativer.data("select2"))
                    $me.Alternativer.select2("destroy");
            } catch (err) { }
            $me.Alternativer.select2({
                tags: true,
                theme: "bootstrap",
                placeholder: $me.Tekst_33.text(),
                escapeMarkup: function (markup) {
                    return markup;
                },
                templateResult: function (item) {
                    return $("<span/>").html(item.text);
                },
                templateSelection: function (item) {
                    return $("<span/>").html(item.text);
                }
            });
            $me.Alternativer.on("change", $me.VelgHandling);


            $me.Alternativer.empty();
            $me.Alternativer.append("<option></option>");

            $me.RepresentantPanel.empty();

            tillatSkjemakopieringLookup = {};
            var alleMottakereUtilgjengelige = true;
            $.each(handling.Alternativer, function () {
                alternativer[this.Id] = this;

                var text = (this.SkjemaHtml != null && this.SkjemaHtml != "") ? ($me.Tekst_32.text() + " &laquo;" + this.Tittel + "&raquo; ...") : this.Tittel;

                $me.Alternativer.append(new Option("<span class=\"colorbardropdown bg-" + this.FargeklasseCss + "\"></span>" + text, this.Id, false, false));

                if (this.MottakerRolleTilgjengelig) {
                    alleMottakereUtilgjengelige = false;
                }

                tillatSkjemakopieringLookup[this.Id] = this.TillatSkjemakopiering;

                if (representantDropdown[this.MottakerrolleId] != null && $me.RepresentantPanel.find("div[data-id=\"" + this.MottakerrolleId + "\"]").length == 0) {
                    var mottakerrolleId = this.MottakerrolleId;
                    var div = $("<div data-id=\"" + mottakerrolleId + "\" style=\"display:none\">");
                    div.append("<label>" + $me.Tekst_31.text() + " - " + this.Mottakerrolle + "</label>");
                    var dropdown = $(representantDropdown[mottakerrolleId]);
                    dropdown.on("change", function () {
                        $me.find("select.representant[data-rolleid=\"" + mottakerrolleId + "\"]").val($(this).val());
                        $me.Info.trigger("change");
                    });
                    div.append(dropdown);
                    $me.RepresentantPanel.append(div);
                }
            });


            if (alleMottakereUtilgjengelige) $me.AlleMottakereUtilgjengeligeAlert.show();

            if (handling.Alternativer.length == 1) {
                //$me.Alternativpanel.hide();
                var alt = handling.Alternativer[0];
                $me.Alternativer.val(alt.Id).trigger("change");
                $me.SendBtn.text(alt.Tittel);
                $me.SendBtn.prepend("<div class=\"colorbar bg-" + alt.FargeklasseCss + "\"></div>");
            } else {
                $me.Alternativer.val(alternativ).trigger("change");
            }


        };
        this.VelgHandling = function () {
            var scrolly = $(window).scrollTop();

            var id = $(this).val();
            var alt = alternativer[id];
            if (alt == "undefined" || alt == null)
                return;

            var skreddersyddskjema = alt.SkreddersyddSkjemanavn;
            var skjema = alt.SkjemaHtml;
            var skjemaId = alt.SkjemaId;
            var tillatSkjemakopiering = alt.TillatSkjemakopiering;
            var mottaker = alt.Mottakerrolle;

            alternativ = id;
            valgtskjema = skjema;
            valgtskjemaId = skjemaId;

            if (tillatSkjemakopiering)
                $me.KopierForrigeBtn.show();
            else
                $me.KopierForrigeBtn.hide();

            $me.Mottakerrolle.text(mottaker);
            $me.SendBar.attr("class", "colorbar bg-" + alt.FargeklasseCss);

            if (alt.FortsettBehandling) {
                $me.SendTilLbl.hide();
                $me.VidereLbl.show();
            }
            else {
                $me.SendTilLbl.show();
                $me.VidereLbl.hide();
            }

            if (skreddersyddskjema && skreddersyddskjema.length) {
                var $new = $("<form id=\"Svarskjema\" style=\"display:none\"/>");

                $me.loadControl("Innboks/Skjema/" + skreddersyddskjema, $new).done(function ($c) {
                    $c.oppføringId = _Id;

                    if ($skjema == null) $skjema = $me.Svarskjema;
                    $skjema.replaceWith($new);
                    $skjema = $new.smartform();
                    $skjema.DownloadUrlPrefix = downloadurl;
                    $skjema.ThumbUrlPrefix = downloadurl;
                    $me.Svarskjema = $new;

                    var vo = (skjemaverdier[alternativ] != null ? eval("(" + skjemaverdier[alternativ] + ")") : null);
                    if (vo != null) {
                        $skjema.Write(vo);
                    }
                    $skjema.fadeIn();
                    $me.Skjemapanel.show();
                });
            } else if (skjema != null) {
                var $new = $("<form id=\"Svarskjema\" style=\"display:none\"/>");
                if ($skjema == null) $skjema = $me.Svarskjema;
                $skjema.replaceWith($new);
                $skjema = $new.html(skjema).smartform();
                $skjema.DownloadUrlPrefix = downloadurl;
                $skjema.ThumbUrlPrefix = downloadurl;
                $me.Svarskjema = $new;

                var vo = (skjemaverdier[alternativ] != null ? eval("(" + skjemaverdier[alternativ] + ")") : null);
                if (vo != null) {
                    $skjema.Write(vo);
                }
                $skjema.fadeIn();
                $me.Skjemapanel.show();
            }
            else $me.Skjemapanel.hide();
            $me.Aksjonpanel.show();
            $(window).scrollTop(scrolly);

            $me.RepresentantPanel.find("div[data-id]").hide();
            $me.RepresentantPanel.find("div[data-id=\"" + alt.MottakerrolleId + "\"]").show();

            var alleMottakereUtilgjengelige = true;
            console.log(alternativer);

            for (var altid in alternativer) {
                if (alternativer[altid].MottakerRolleTilgjengelig)
                    alleMottakereUtilgjengelige = false;
            };

            if (alt.MottakerRolleTilgjengelig == false) {
                $me.SkjemaDiv.hide();
                $me.RepresentantPanel.hide();
                if (!alleMottakereUtilgjengelige) $me.MottakerrolleUtilgjengeligeAlert.show();

            } else {
                $me.SkjemaDiv.show();
                $me.RepresentantPanel.show();
                $me.MottakerrolleUtilgjengeligeAlert.hide();
            }
        };
        this.OppdaterRelaterte = function (result) {
            var relaterte = result.Relaterte;
            loadedRelaterte = relaterte;
            $me.Relaterteantall.text(relaterte.length);
            $relaterte.Write({ Relaterte: relaterte });
            oldrelaterte = JSON.stringify($relaterte.Read());

            if (result.Oppføring.Slettet && !result.Admin) {
                $me.RelaterteTabell.find("[data-func=\"input\"]").attr("readonly", true).removeAttr('contenteditable');
                $me.RelaterteTabell.find("[data-action=\"remove\"]").attr("disabled", true).off("click");
                $me.RelaterteTabell.find("select").attr("disabled", true);
            }
        };
        this.OppdaterMeldinger = function (meldinger, triggerClick) {
            var antall = 0;
            var $forste = null;
            var $forste2 = null;
            var oppføringId = null;
            $me.Meldingsliste.empty();

            $.each(meldinger, function () {
                var $li = $("<li class=\"list-group-item\"/>");
                var $div = $("<div class=\"row\"/>");
                $li.append($div);

                // --------------------- Ny meldingsliste til venstre:
                var $a = $("<a class=\"list-group-item melding " + (this.Slettet ? "slettet" : "") + "\"><div class=\"bg-" + this.FargeklasseCss + " meldingskloss\"></div></a>");
                var icon = "envelope";
                if (this.Innsendt == null) icon = "pencil";
                if (this.Slettet) icon = "trash";
                $a.append("<span class=\"glyphicon glyphicon-" + icon + "\"></span> <b>" + this.Avsendernavn + "</b> som  <b>" + this.Rolle + "</b><br/>");
                if (this.Skjemanavn != null) {
                    $a.append("<div>" + this.Skjemanavn + "</div>");
                }
                $a.data("melding", this);

                if (this.Innsendt) {
                    $a.append("<div><i class=\"text-muted\">" + formatDate(this.Innsendt) + "</i></div>");
                }

                $a.append("<div class=\"clearfix\"></div>");
                $a.on("click", function () {
                    if ($a.is(".active")) return;
                    $a.parent().find(".active").removeClass("active");
                    $a.addClass("active");

                    viserMeldingId = $a.data("melding").Id;
                    $me.LoadMelding($a.data("melding"));
                });

                if ($forste == null && !this.Slettet) {
                    $forste = $a;
                }

                if ($forste2 == null) {
                    $forste2 = $a;
                    oppføringId = this.OppføringId;
                }

                if (oppføringId != this.OppføringId) {
                    $me.Meldingsliste.append("<div style=\"background:#f2f2f2; width:100%; height:10px;\" />"); // Skillelinje
                    oppføringId = this.OppføringId;
                }

                $me.Meldingsliste.append($a);
                antall++;
            });

            if (triggerClick) {
                $me.Meldingsantall.text(antall);
                if ($forste != null) {
                    $forste.trigger("click");
                }
            } else if (viserMeldingId) {
                $me.Meldingsliste.find(".melding").each(function (index, $a) {
                    $a = $($a);
                    if ($a.data("melding").Id == viserMeldingId) {
                        if ($a.is(".active")) return;
                        $a.parent().find(".active").removeClass("active");
                        $a.addClass("active");
                    }
                });
            }

        };

        this.LoadMelding = function (melding) {
            $me.HentMelding(melding.Id).done(function (result) {
                var $f = $("<div/>");

                $f.html(result.Meldingstekst);

                if (result.SkjemaKopiert) {

                    var melding_kopiertfra = JSON.parse(result.KopiertFraSkjemaverdier);
                    var melding_skjemaverdier = JSON.parse(result.Skjemaverdier);
                    var melding_skjemahtml = result.SkjemaHtml;

                    var $visdiff = $("<div class=\"btn btn-sm btn-default pull-right\" title=\"" + $me.Tekst_5.text() + "\">" +
                        "<span class=\"glyphicon glyphicon-copy\"></span> " + $me.Tekst_7.text() + "</div>");

                    $visdiff.on("click", function () {

                        var diff = $me.GenererDiff(melding_kopiertfra, melding_skjemaverdier, melding_skjemahtml, $me.Tekst_6.text());

                        $me.Meldingsskjema.empty();
                        $me.Meldingsskjema.html(diff);
                        $me.Meldingsdialog.modal({});
                    });

                    $f.prepend($visdiff);
                }

                if (result.Innsendt == null)
                    $me.Meldingspanel.hide();
                else
                    $me.Meldingspanel.show();

                if (result.Slettet == null) {
                    //$me.Meldingspanel.removeClass("panel-danger").addClass("panel-default");
                } else {
                    //$me.Meldingspanel.removeClass("panel-default").addClass("panel-danger");
                    $f.prepend("<div class=\"alert alert-danger\">" + result.SlettetMelding + "</div>");
                }

                var statushandling = result.Statushandling != null ? result.Statushandling : $me.Tekst_7.text();
                if (melding.Statusendring != null) melding.Statusendring;

                if (result.Meldingstekst == "")
                    $f.append("<span disabled title=\"" + $me.Tekst_8.text() + "\" class=\" disabled melding text-primary pull-right glyphicon\"></span>");
                else
                    $f.append("<span title=\"" + $me.Tekst_8.text() + "\" style=\"cursor:pointer\" data-melding=\"" + melding.Id +
                        "\" class=\"melding text-primary pull-right glyphicon glyphicon-zoom-in\"></span>");

                var tidspunkt = melding.Innsendt != null ? formatDateTime(melding.Innsendt) : formatDateTime(melding.Opprettet);
                $f.append("<div class=\"text-muted\">" + tidspunkt + " - " + statushandling + "</div>");



                $me.Meldingsinnhold.on("click", ".melding", function () {
                    var $m = $(this);
                    $me.HentMelding($m.attr("data-melding")).done(function (result) {
                        var $f = $("<div/>");

                        $f.on("attach", function (e, $el) {
                            $el.find(":input,[contenteditable],.btn:not(.dropdown-toggle)")
                                .prop("disabled", true)
                                .addClass("readonly disabled")
                                .attr("contenteditable", null);
                            $el.find("[data-action]").remove();
                            $el.find(".dropdown-menu .reset, .dropdown-menu .divider").remove();
                        });

                        var processdata = function () {
                            $f = $f.smartform();
                            var urlPrefix = "/API/InnboksBehandle/LastNed?auth=&id=" + result.Oppføringsid + "&vedlegg=";
                            $f.DownloadUrlPrefix = urlPrefix;
                            $f.ThumbUrlPrefix = urlPrefix;


                            var vo = eval("(" + result.Skjemaverdier + ")");
                            $f.Write(vo);
                            $f.find(".autoinsert").remove();
                            $f.find("[data-action]").remove();
                            $f.find(".dropdown-menu .reset, .dropdown-menu .divider").remove();

                            $me.Meldingsskjema.empty();
                            $me.Meldingsskjema.append($f);

                            $me.Meldingsdialog.modal({});
                        };

                        if (result.SkreddersyddSkjemanavn) {
                            $me.loadControl("Innboks/Skjema/" + result.SkreddersyddSkjemanavn, $f).done(function ($c) {
                                $c.oppføringId = _Id;
                                $c.VisHistorisk();
                                processdata();
                            });
                        } else {
                            $f.html(result.SkjemaHtml);
                            processdata();
                        }


                    });
                });

                $me.Meldingsinnhold.empty();
                $me.Meldingsinnhold.append($f);
            });
        };
        this.LoadItem = function (id) {
            var scrolly = $(window).scrollTop();
            $me.Hent(id).done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }
                editId = id;
                downloadurl = "/API/InnboksBehandle/LastNed?auth=&id=" + editId + "&vedlegg=";
                _Id = result.Oppføring.Id;


                if (result.Oppføringsvedlegg.length == 0) {
                    visVedleggsoppføringer = false;
                } else {
                    visVedleggsoppføringer = true;
                    $me.Vedleggsoppføringer.empty();
                    $.each(result.Oppføringsvedlegg, function () {

                        var id = this.Id;

                        dowloadurl2 = "/API/InnboksBehandle/LastNed?auth=&id=" + id + "&vedlegg=";

                        $me.Vedleggsoppføringer.append(
                            "<div class=\"section\"><div class=\"panel panel-default\">" +
                            "<div id=\"PanelHeading\" class=\"panel-heading\">" + this.Oppføringsnr + " - " + this.Tittel + "</div> " +
                            "<table class=\"table table-hover table-bordered table-condensed table-striped small\">" +
                            "<thead><tr>" +
                            "<th>" + $me.Tekst_9.text() + "</th>" +
                            "<th>" + $me.Tekst_10.text() + "</th>" +
                            "<th>" + $me.Tekst_11.text() + "</th>" +
                            "<th>" + $me.Tekst_12.text() + "</th>" +
                            "<th class=\"tight\"></th>" +
                            "</tr></thead>" +
                            "<tbody id=\"Oppføringsvedleggsliste" + id + "\"></tbody>" +
                            "</table>" +
                            "</div></div>"
                        );

                        $.each(this.Vedlegg, function () {
                            $(
                                "<tr>" +
                                "<td>" + (formatDate(this.Innsendtdato)) + "</td>" +
                                "<td>" + this.Filnavn + "</td>" +
                                "<td>" + this.SendtInnAv + "</td>" +
                                "<td>" + this.Beskrivelse + "</td>" +
                                "<td class=\"nopadd\"><a href=\"" + dowloadurl2 + this.NedlastingsId + "&saveAsName=" + this.Filnavn + "\" class=\"btn btn-default glyphicon glyphicon-download-alt\"></a></td>" +
                                "</tr>"
                            ).appendTo("#Oppføringsvedleggsliste" + id);
                        });
                    });
                }

                $me.Ferdighetsgrad.empty();
                $me.Svarskjema.empty();
                $skjema = null;
                oldjson = null;
                skjemaverdier = [];
                if (result.Admin) {
                    $me.find(".admin").show();
                }

                skrivebeskyttetGjeldendeProsesstrinn = result.SkrivebeskyttetGjeldendeProsesstrinn;
                skrivebeskyttetMedAlleRoller = result.SkrivebeskyttetMedAlleRoller;

                if (skrivebeskyttetGjeldendeProsesstrinn)
                    $me.Svarpanel.hide();
                else
                    $me.Svarpanel.show();

                if (result.Oppføring.Slettet) {
                    oppføringSlettet = true;

                    $me.OppføringsInfo.hide();
                    $me.SlettetAlert.show();
                    $me.SlettetAlert.html(result.Oppføring.SlettetMelding);
                    $me.SourceBtn.attr("disabled", true);

                    $me.Endringermelding.hide();
                    $me.Endringermelding2.hide();
                    $me.Endringermelding3.hide();
                    $me.Endringermelding4.hide();

                    //oppføringsdetaljer
                    $me.Ferdighetsgrad.attr("disabled", true);
                    $me.Prioritet.attr("disabled", true);

                    //oppfølging
                    $me.OmforentUavklartBeløp.attr("disabled", true);
                    $me.OmforentBeløp.attr("disabled", true);
                    $me.OpprinneligKravBeløp.attr("disabled", true);
                    $me.FrafaltBeløp.attr("disabled", true);
                    $me.UtgårFraKontraktBeløp.attr("disabled", true);
                    $me.ReellMerkostnadBeløp.attr("disabled", true);
                    $me.AvklartØkonomiOgTidBeløp.attr("disabled", true);
                    $me.KravBeløp.attr("disabled", true);
                    $me.AntattBeløp.attr("disabled", true);
                    $me.FakturertBeløp.attr("disabled", true);
                    $me.FakturertDato.attr("disabled", true);
                    $me.AndreEnnBH.attr("disabled", true);
                    $me.FerdigFakturert.attr("disabled", true);
                    $me.ReferanseFraBH.attr("disabled", true);
                    $me.ReferanseUE.attr("disabled", true);
                    $me.Tilvalg.attr("disabled", true);
                    $me.GodkjentBeløp.attr("disabled", true);
                    $me.OmtvistetBeløp.attr("disabled", true);
                    $me.ForventetGodkjentBeløp.attr("disabled", true);
                    $me.GjenståendeKostnad.attr("disabled", true);
                    $me.FakturertGodkjent.attr("disabled", true);
                    $me.UE.attr("disabled", true);
                    $me.EtatEier.attr("disabled", true);
                    $me.KrevetFristforlengelse.attr("disabled", true);
                    $me.OmforentFristforlengelse.attr("disabled", true);


                    //merknader
                    tinyMCE.get("Merknader").setMode("readonly");
                    $me.Merknadertab.find(".mce-toolbar-grp").hide();

                    //lokasjoner
                    $me.NyLokasjonBtn.attr("disabled", true);

                }


                $me.Representanter.find("div.representant").remove();

                $.each(result.RepresentantUtvalg, function () {

                    var selectname = "representant_";
                    var rolleid = this.Id;
                    var rollenavn = this.Navn;
                    var css = this.CSS;
                    var currentbruker = result.Bruker;

                    var select = "<select name=\"" + selectname + rolleid + "\" class=\"form-control representant\" " + (result.Oppføring.Slettet ? "disabled" : "") + " data-rolleid=\"" + rolleid + "\">";
                    select += "<option value=\"\">(" + $me.Tekst_13.text() + ")</option>";

                    $.each(this.Brukerkontoer, function () {
                        var brukerid = this.Id;
                        var brukernavn = this.Navn;
                        var brukerepost = this.Epost;
                        var representant = this.Representant;

                        var selected = representant ? "selected" : "";
                        select += "<option value=\"" + brukerid + "\" " + selected + ">" + brukernavn + " (" + brukerepost + ")</option>";
                    });

                    select += "</select>";

                    representantDropdown[rolleid] = select;

                    var dropdown = "<div class=\"form-group col-sm-3 col-xs-4 representant\"><span class=\"glyphicon glyphicon-stop text-" + css + "\"></span>&nbsp;<b>" + rollenavn + "</b> <br />" + select + "</div>";
                    $me.Representanter.append(dropdown);
                });

                //Gir her en alert slik at brukeren ikke (uvitende) begynner å jobbe med andres oppføringer
                if (result.RepresentantAdvarsel1) {
                    $.fn.Confirm($me.Tekst_14.text(), function () { }, function () {
                        location = "#/Innboks/Oversikt";
                    });
                } else if (result.RepresentantAdvarsel2) {
                    $.fn.Confirm($me.Tekst_15.text(), function () { }, function () {
                        location = "#/Innboks/Oversikt";
                    });
                }

                if (result.Oppfølging) {
                    $me.find(".oppfølging").show();

                    if (!result.VisOppfølging) $me.Oppfolgingfane.hide();

                    var o = result.Oppføring;
                    if (!o.VisAvklart) $me.Avklartfelt.remove();
                    if (!o.VisFrafalt) $me.Frafaltfelt.remove();
                    if (!o.VisOmforent) $me.Omforentfelt.remove();
                    if (!o.VisOmforentUavklart) $me.OmforentUavklartfelt.remove();
                    if (!o.VisOpprinneligKrav) $me.OpprinneligKravfelt.remove();
                    if (!o.VisReellMerkostnad) $me.ReellMerkostnadfelt.remove();
                    if (!o.VisUtgårFraKontrakt) $me.UtgårFraKontraktfelt.remove();

                } else {
                    $me.find(".oppfølging").remove();
                }

                if (result.Leder) {
                    $me.find(".leder").show();
                    $me.Ferdighetsgrad.append("<option value=\"\">(" + $me.Tekst_16.text() + ")</option>");
                    $.each(result.Ferdighetsgrader, function () {
                        $me.Ferdighetsgrad.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                    });

                    if (!result.VisPrioritet) $me.PrioritetFelt.hide();
                    if (!result.Admin && !result.AktiverAnsvarsmatrisefane) $me.Ansvarsmatrisefane.hide();
                } else {
                    $me.find(".leder").remove();
                    $me.PrioritetFelt.hide();
                }

                if (!result.Oppføring.VisKravBeløp) $me.VisKravBeløp.hide();
                if (!result.Oppføring.VisAntattBeløp) $me.VisAntattBeløp.hide();
                if (!result.Oppføring.VisFakturertBeløp) $me.VisFakturertBeløp.hide();

                if (!result.Oppføring.VisFakturertDato) $me.VisFakturertDato.hide();
                if (!result.Oppføring.VisAndreEnnBH) $me.VisAndreEnnBH.hide();
                if (!result.Oppføring.VisFerdigFakturert) $me.VisFerdigFakturert.hide();
                if (!result.Oppføring.VisUE) $me.VisUE.hide();
                if (!result.Oppføring.VisReferanseFraBH) $me.VisReferanseFraBH.hide();
                if (!result.Oppføring.VisReferanseUE) $me.VisReferanseUE.hide();
                if (!result.Oppføring.VisTilvalg) $me.VisTilvalg.hide();
                if (!result.Oppføring.VisGodkjentBeløp) $me.VisGodkjentBeløp.hide();
                if (!result.Oppføring.VisOmtvistetBeløp) $me.VisOmtvistetBeløp.hide();
                if (!result.Oppføring.VisForventetGodkjentBeløp) $me.VisForventetGodkjentBeløp.hide();
                if (!result.Oppføring.VisGjenståendeKostnad) $me.VisGjenståendeKostnad.hide();
                if (!result.Oppføring.VisFakturertGodkjent) $me.VisFakturertGodkjent.hide();

                if (!result.Oppføring.VisEtatEier) $me.VisEtatEier.hide();
                if (!result.Oppføring.VisKrevetFristforlengelse) $me.VisKrevetFristforlengelse.hide();
                if (!result.Oppføring.VisOmforentFristforlengelse) $me.VisOmforentFristforlengelse.hide();


                if (result.Oppføring.VisLokasjon) {
                    $me.Lokasjonfane.show();
                    $me.Lokasjonantall.text(result.Lokasjoner.length);
                    $lokasjoner.Write({ Lokasjoner: result.Lokasjoner });
                    oldlokasjoner = JSON.stringify($lokasjoner.Read());

                    if (result.Oppføring.Slettet || skrivebeskyttetMedAlleRoller) {
                        $me.LokasjonTabell.find("[data-func=\"input\"]").attr("readonly", true).removeAttr('contenteditable');
                        $me.LokasjonTabell.find("[data-action=\"remove\"]").attr("disabled", true).off("click");
                        $me.NyLokasjonBtn.attr("disabled", true);
                    }
                }

                if (result.KanMerkesSlettet)
                    $me.MerkSomSlettetBtn.show();
                else
                    $me.MerkSomSlettetBtn.hide();

                if (result.KanSlettes)
                    $me.SlettBtn.show();
                else
                    $me.SlettBtn.hide();

                if (result.KanSletteSiste)
                    $me.SlettSisteMeldingBtn.show();
                else
                    $me.SlettSisteMeldingBtn.hide();

                $me.OppdaterOppføring(result.Oppføring, result.Leder, result.Admin);

                if (result.Oppføring.Innsendt == null)
                    $me.Svarskjemalabel.text("" + $me.Tekst_19.text() + ":");

                $me.OppdaterMeldinger(result.Meldinger, true);
                $me.OppdaterRelaterte(result);

                if (result.Handling == null) {
                    $me.Svarskjemalabel.parent().parent().hide();
                    //$me.Skjema.hide();
                    //$me.Skjemafane.hide();
                    //$me.Infofane.addClass("active");
                    //$me.Info.addClass("active");
                }
                else {
                    $me.OppdaterHandlinger(result.Handling);
                }

                if ($skjema != null)
                    oldjson = JSON.stringify($skjema.Read());

                redigerSerienummer = result.Oppføring.Serienummer;


                if (oppføringsnr == null) {
                    $me.LastNedDocxBtn.hide();
                    $me.LastNedPdfBtn.hide();
                    $me.LastNedPdfEkstraBtn.hide();
                    $me.LastNedZipBtn.hide();
                }
                else {
                    if (result.Leder) $me.LastNedDocxBtn.show();
                    if (result.HarVedlegg) $me.LastNedZipBtn.show();
                    $me.LastNedPdfBtn.show();
                    if (result.PdfBundle) $me.LastNedPdfEkstraBtn.show();
                }

                $(window).scrollTop(scrolly);
            });
        };
        

        this.GenererDiff = function (oldverdier, newverdier, skjemahtml, header) {
            var output = "<h3>" + header + "</h3><br /><table class=\"table table-striped table-bordered kopierskjemadiff\">";
            output += "<thead><tr><th>" + $me.Tekst_17.text() + "</th><th>" + $me.Tekst_18.text() + "</th></tr></thead><tbody>";

            var $skjemahtml = $("<div />");
            $skjemahtml.html(skjemahtml);

            var htmlEntities = function (str) {
                return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
            }

            var formattype = function (input) {
                if (typeof input === "boolean") {
                    return input ? $me.Yes_2.text() : $me.No_2.text();
                } else {
                    if (input === null || typeof input === "undefined") return "";
                    return htmlEntities(input);
                }
            }

            var compareArrayItem = function (item1, item2) {
                for (var prop in item1) {
                    if (item1[prop] != item2[prop]) {
                        return false;
                    }
                }

                for (var prop in item2) {
                    if (item1[prop] != item2[prop]) {
                        return false;
                    }
                }

                return true;
            }

            var getItemName = function (item) {
                for (var prop in item) {
                    if (typeof item[prop] === 'string') {
                        return htmlEntities(item[prop]);
                    }
                }
                for (var prop in item) {
                    return htmlEntities(item[prop]);
                }
                return "";
            }

            var compareArray = function (array1, array2, tag) {
                var output = "";
                if (array1 && array1.length) {
                    for (var i = 0; i < array1.length; i++) {
                        if (array2 && array2.length) {
                            var inneholder = false;
                            for (var y = 0; y < array2.length; y++) {
                                if (compareArrayItem(array1[i], array2[y])) {
                                    inneholder = true;
                                }
                            }
                            if (!inneholder) {
                                output += "<" + tag + ">" + getItemName(array1[i]) + "</" + tag + ">\n";
                            }
                        } else {
                            output += "<" + tag + ">" + getItemName(array1[i]) + "</" + tag + ">\n";
                        }
                    }
                }
                return output;
            }

            var evaluatesame = function (var1, var2) {

                if ((var1 === null || typeof var1 === "undefined" || (typeof var1 === "string" && var1.length == 0)) &&
                    (var2 === null || typeof var2 === "undefined" || (typeof var2 === "string" && var2.length == 0))) {
                    return true;
                }

                if (typeof var1 === "number" || typeof var2 === "number") {

                    var var1n = var1;
                    var var2n = var2;

                    if (var1n === null || typeof var1n === "undefined") {
                        var1n = 0;
                    } else if (typeof var1n === "string") {
                        var1n = var1n.replace(/\s/g, '').replace(/,/g, '.');
                    }

                    if (var2n === null || typeof var2n === "undefined") {
                        var2n = 0;
                    } else if (typeof var2n === "string") {
                        var2n = var2n.replace(/\s/g, '').replace(/,/g, '.');
                    }

                    var1n = Number(var1n);
                    var2n = Number(var2n);

                    if (!isNaN(var1n) && !isNaN(var2n)) {
                        return var1n === var2n;
                    }
                }

                return var1 == var2;
            }

            var antallendringer = 0;
            for (var prop in oldverdier) {

                var gammelverdi = oldverdier[prop];
                var nyverdi = newverdier[prop];

                if (Array.isArray(gammelverdi) || Array.isArray(nyverdi)) {

                    var arrayoutput = "";

                    // Gå gjennom begge array og se etter endringer og tillegg
                    var compare1 = compareArray(gammelverdi, nyverdi, "del");
                    var compare2 = compareArray(nyverdi, gammelverdi, "ins");

                    if (compare1 || compare2) {
                        output += "<tr><td>" + prop + "</td><td style=\"white-space: pre-line;\">";

                        output += compare1 + compare2;

                        output += "</td></tr>";

                        antallendringer++;
                    }

                    continue;
                }

                var propname = prop;
                var $el = $skjemahtml.find("input[name=\"" + prop + "\"]").first() || $skjemahtml.find("[data-name=\"" + prop + "\"]").first();

                if ($el) {
                    if ($el.attr("type") == "checkbox") {
                        var $lbl = $el.parent();
                        if ($lbl.prop('nodeName').toLowerCase() == "label") {
                            propname = $lbl.clone()
                                .children()
                                .remove()
                                .end()
                                .text()
                                .trim();

                            if (!propname || !propname.length) {
                                propname = prop;
                            }
                        }
                    }
                }

                if (!evaluatesame(gammelverdi, nyverdi)) {

                    var spacer = " ";

                    if ((gammelverdi ? gammelverdi.length : 0) + (nyverdi ? nyverdi.length : 0) > 80) spacer = "\n";

                    output += "<tr><td>" + htmlEntities(propname) + "</td><td style=\"white-space: pre-line;\">";

                    output += "<del>" + formattype(gammelverdi) + "</del>" + spacer + "<ins>" + formattype(nyverdi) + "</ins>";

                    output += "</td></tr>";

                    antallendringer++;

                } else {
                    //output += "<tr><td>" + propname + "</td><td>" + formattype(gammelverdi) + "</td>";
                }
            }

            if (antallendringer == 0) {
                output += "<tr><td colspan=\"10\"><i>" + $me.Tekst_20.text() + "</i></td></tr>";
            }

            output += "</tbody></table>";

            return output;
        }

        this.UtforHandling = function () {

            if (!sendingkopiertskjema && skjemakopiert && tillatSkjemakopieringLookup[alternativ] && valgtskjemaId == kopiertskjemaId) {

                $me.Meldingsskjema.empty();

                var oldverdier = JSON.parse(forrigeskjema);
                var newverdier = $skjema.Read();

                var output = $me.GenererDiff(oldverdier, newverdier, valgtskjema, $me.Tekst_21.text());

                $me.Meldingsskjema.html(output);

                $me.KopierSendBtn.show();

                $me.Meldingsdialog.modal({});
                return;
            }

            sendingkopiertskjema = false;

            $me.Lagre(true).done(function (result) {
                if (!result.Success) {
                    // TODO: Vis feilmeldinger ved felter i skjema / merk de røde (med has-error klassen)
                    return;
                }

                if (result.NyOppføring) {

                    if (result.AlleredeOpprettet) {
                        $.fn.Alert($me.Tekst_22.text() + " " + result.NyOppføringNavn, function () {
                            location = "#/O+" + result.NyOppføring;
                        });
                    } else {
                        $.fn.Alert($me.Tekst_23.text() + " " + result.NyOppføringNavn, function () {
                            location = "#/O+" + result.NyOppføring;
                        });
                    }

                    return;
                }

                if (result.Fortsett) {
                    $.fn.Alert($me.Tekst_24.text(), function () {
                        location.reload();
                    });
                    return;
                }

                $me.OppføringsInfo.hide();

                $me.Skjemaside.hide();
                $me.Tabs.hide();
                $me.Innsendtside.show();
                $me.InnsendtTilRolle.html("<span class=\"glyphicon glyphicon-stop text-" + result.LiggerHosRolle.FargeklasseCss + "\"></span> " + result.LiggerHosRolle.Navn);
                $me.InnsendtOppføringsnr.text(result.Oppføringsnr);
                $me.Oppføringsnr.text(result.Oppføringsnr);
                $me.Status.text("(" + result.Oppføring.Oppføring.Status + ")");
                if (result.Leder) $me.LastNedDocxBtn.show();
                if (result.HarVedlegg) $me.LastNedZipBtn.show();
                $me.LastNedPdfBtn.show();
                if (result.PdfBundle) $me.LastNedPdfEkstraBtn.show();

                if (result.KanSlettes)
                    $me.SlettBtn.show();
                else
                    $me.SlettBtn.hide();

                if (result.KanMerkesSlettet)
                    $me.MerkSomSlettetBtn.show();
                else
                    $me.MerkSomSlettetBtn.hide();

                if (result.KanSletteSiste)
                    $me.SlettSisteMeldingBtn.show();
                else
                    $me.SlettSisteMeldingBtn.hide();
            });
        };

        var ajax = null;
        var running = false;
        var retrySave = null;
        var retryDfd = null;

        this.Lagre = function (send, savejson) {

            if (running) { // Already running, try after previous done
                if (retryDfd != null) {
                    return retryDfd.promise();
                }

                var retryDfd = $.Deferred();

                retrySave = function () {
                    $me.Endringermelding.attr("class", "text-warning");
                    $me.Lagre(send).done(function (res) {
                        retryDfd.resolve(res); // Do not specify json on retry. read updated json again.
                        retryDfd = null;
                    });
                    retrySave = null;
                };

                return retryDfd.promise();
            }
            running = true;


            var data = new FormData();

            if (!savejson) savejson = JSON.stringify($skjema.Read());
            if (send) $app.showLoading();

            data.append("auth", $app.token);
            data.append("Id", editId);
            data.append("Send", send);
            data.append("SistEndret", sistEndret);
            data.append("Skjemaverdier", savejson);
            data.append("Handling", alternativ);

            var attachments = 0;
            $skjema.find("input[type=file][data-upload]").each(function (i, item) {
                var f = item.files[$(item).attr("data-fileindex") || 0];
                var filename = $(item).attr("data-upload");
                data.append("attachments[]", f, filename);
                attachments++;
            });

            $skjema.find("img[data-upload]").each(function (index, item) {
                var base64 = $(item).attr("src").split(";base64,")[1];
                var blob = b64toBlob(base64);
                var filename = $(item).attr("data-upload");
                data.append("attachments[]", blob, filename);
                attachments++;
            });

            var xhr = null;
            ajax = $.ajax({
                url: "API/InnboksBehandle/Lagre",
                type: "POST",
                dataType: "json",
                data: data,
                cache: false,
                contentType: false,
                processData: false,
                xhr: function () {
                    // get the native XmlHttpRequest object
                    xhr = $.ajaxSettings.xhr();

                    if (attachments > 0) {
                        $me.FileUpload.Dialog.show();
                        xhr.upload.onprogress = function (evt) {
                            if (ajax != null) {
                                $me.FileUpload.UpdateProgress(evt.loaded, evt.total);
                            }
                        };
                    }

                    return xhr;
                }
            });

            $me.FileUpload.AbortUploadBtn.off("click");
            $me.FileUpload.AbortUploadBtn.on("click", function () {
                if (ajax != null) ajax.abort();
                ajax = null;

                $me.FileUpload.Dialog.hide();
                $me.FileUpload.Reset();

                // Reload...
                location.reload();
            });

            ajax.always(function () {
                ajax = null;
                running = false;
                if (send) $app.hideLoading();
                $me.FileUpload.Dialog.hide();
                $me.FileUpload.Reset();
                setTimeout(function () { // Run after ajax.done or ajax.fail
                    if (!send && retrySave != null) retrySave(); // Retry only if not submitting
                }, 100);
            });

            ajax.done(function (result) {
                if (result.Success) {
                    $skjema.find("[data-upload]").each(function () {
                        $(this).attr("data-upload", null);
                    });

                    oldjson = savejson;
                    sistEndret = result.SistEndret;
                    $me.Endringermelding.attr("class", "text-success");
                } else {
                    if (result.Reload) {
                        $.fn.Alert(result.Message, function () {
                            location.reload();
                        }, "h3");
                    } else {
                        $.fn.Alert(result.Message || $me.Tekst_25.text(), null, "h3");
                    }
                }
            });

            ajax.fail(function (jqXHR, textStatus, errorThrown) {
                $.fn.Alert($me.Tekst_26.text() + " \"" + textStatus + " : " + errorThrown + "\"");
            });

            return ajax;
        };
        this.TegnDiagram = function () {

            $me.HentDiagramdata(_Id).done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }

                var roller = result.Roller;
                var skjemaer = result.Skjemaer;
                var data = result.Prosessflyter;

                var columns = {};

                var blookup = {};
                $.each(result.Brukernavn, function () { blookup[this.rolleId] = this; });

                $me.Brukernavnheaders.empty();

                // Finn alle rolle kolonner med assisterende rolle
                var columnsMedAss = {};
                $.each(result.Prosessflyter.Prosesstrinn, function () {
                    if (this.BehandlesAvRoller && this.BehandlesAvRoller.length) {

                        if (this.BehandlesAvRoller.length == 1 && this.BehandlesAvRoller[0] == this.RolleId) {

                        } else {
                            columnsMedAss[this.RolleId] = true;
                        }
                    }
                });

                $.each(result.Prosessflyter.Prosesstrinn, function () {

                    var that = this;

                    if (columns[that.RolleId]) {
                        return;
                    }

                    var brukernavn = blookup[that.RolleId] ? blookup[that.RolleId].brukernavn : null;
                    var rowString = "<td>";

                    if (!brukernavn || !brukernavn.length) {
                        rowString += "<i class=\"text-danger\">Ingen brukere</i>";
                    } else {
                        var first = true;
                        for (var i = 0; i < brukernavn.length; i++) {
                            if (!first) {
                                rowString += ", ";
                            }
                            rowString += "<span style=\"white-space:nowrap\"><span class=\"small glyphicon glyphicon-user\"></span> " + brukernavn[i] + "</span>";
                            first = false;
                        }
                    }

                    if (columnsMedAss[that.RolleId]) {
                        rowString += "<br/><br/>*" + $me.Tekst_27.text();
                    }

                    rowString += "</td>";

                    $me.Brukernavnheaders.append(rowString);

                    columns[that.RolleId] = true;
                });

                TegnDiagram(roller, skjemaer, data, $me.Ansvarsmatrisetab);
            });
        };
        this.Hent = function (id) {
            return $app.APIGet("InnboksBehandle", "Hent", { id: id });
        };
        this.HentMelding = function (id) {
            return $app.APIGet("InnboksBehandle", "HentMelding", { id: id });
        };
        this.MerkSomSlettet = function (id) {
            return $app.APIGet("InnboksBehandle", "MerkSomSlettet", { id: id });
        };
        this.Slett = function (id) {
            return $app.APIGet("InnboksBehandle", "Slett", { id: id });
        };
        this.LastNedZip = function (id) {
            $app.APIGet("InnboksBehandle", "ValiderLastNedZip", { id: id }).done(function (result) {
                if (result.Success) {
                    return $app.APIDownload("InnboksBehandle", "LastNedZip", { id: id });
                } else {
                    $.fn.Alert(result.Message);
                }
            });
        };
        this.LastNedPdf = function (id) {
            $app.APIGet("InnboksBehandle", "ValiderLastNedPdf", { id: id, ignorerPdfBundle: false }).done(function (result) {
                if (result.Success) {
                    return $app.APIDownload("InnboksBehandle", "LastNedPdf", { id: id, ignorerPdfBundle: false });
                } else {
                    $.fn.Alert(result.Message);
                }
            });
        };
        this.LastNedPdfMeldingshistorikk = function (id) {
            $app.APIGet("InnboksBehandle", "ValiderLastNedPdf", { id: id, ignorerPdfBundle: true }).done(function (result) {
                if (result.Success) {
                    return $app.APIDownload("InnboksBehandle", "LastNedPdf", { id: id, ignorerPdfBundle: true });
                } else {
                    $.fn.Alert(result.Message);
                }
            });
        };
        
        this.LastNedDocx = function (id) {
            return $app.APIDownload("InnboksBehandle", "LastNedDocx", { id: id });
        };
        this.OppdaterInfo = function (id, prioritet, ferdighetsgrad, representanter) {
            return $app.APIPost("InnboksBehandle", "OppdaterInfo", {
                id: id, prioritet: prioritet, ferdighetsgrad: ferdighetsgrad, representanter: representanter
            }, { background: true });
        };
        this.OppdaterOppfølging = function (data) {
            return $app.APIPost("InnboksBehandle", "OppdaterOppfølging", data, { background: true });
        };
        this.OppdaterMerknader = function (id, merknader) {
            return $app.APIPost("InnboksBehandle", "OppdaterMerknader", {
                id: id, merknader: merknader
            }, { background: true });
        };
        this.LagreRelaterte = function (data) {
            return $app.APIPost("InnboksBehandle", "LagreRelaterte", data, { background: true });
        };
        this.KopierForrigeSkjema = function (id, alternativ) {
            return $app.APIGet("InnboksBehandle", "KopierForrigeSkjema", { id: id, alternativId: alternativ });
        };
        this.SlettSisteMelding = function (id) {
            return $app.APIGet("InnboksBehandle", "SlettSisteMelding", { id: id });
        };
        this.HentVedlegg = function () {
            return $app.APIGet("InnboksBehandle", "HentVedlegg");
        };
        this.EndreSerienummer = function (id, serienummer) {
            return $app.APIGet("InnboksBehandle", "EndreSerienummer", { id: id, serienummer: serienummer });
        };
        this.OverførTilArkiv = function (id) {
            return $app.APIGet("InnboksBehandle", "OverførTilArkiv", { id: id });
        };
        this.HentDiagramdata = function (id) {
            return $app.APIGet("InnboksBehandle", "HentDiagramdata", { id: id });
        };
        this.LagreLokasjoner = function (data) {
            return $app.APIPost("InnboksBehandle", "LagreLokasjoner", data, { background: true });
        };

        return this;
    };
})(jQuery);