﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.AdminLoggHendelseslogg = function ($app) { // <-- Constructor method
        var $me = this;

        var sisteId = null;
        var antallprside = 30;
        var oldsoketekst = null;
        var soketekst = "";

        var ajax = null;
        var timer = null;

        var $liste;
        var $kunfeil;
        var $visadmin;
        var $oppdater;

        // Define the jquery class
        this.on("viewinit", function () {
            $liste = $me.Hendelser;
            $kunfeil = $me.KunFeil;
            $visadmin = $me.VisAdmin;
            $oppdater = $me.Oppdater;


            var dt = new Date();
            if ($me.FraDato[0].valueAsDate) {
                $me.FraDato[0].valueAsDate = dt;
                $me.TilDato[0].valueAsDate = dt;
            } else {
                var dts = dt.toISOString("yyyy-MM-dd").split("T")[0];
                $me.FraDato.val(dts);
                $me.TilDato.val(dts);
            }


            $app.UpdateTitle("BIM2Share - " + $me.Title1.text());

            $me.TabellContainer.on("scroll", $me.HandleScroll);

            $me.GetBrukere().done(function (res) {
                $me.Bruker.select2({
                    theme: "bootstrap",
                    escapeMarkup: function (markup) {
                        return markup;
                    },
                    templateResult: function (item) {
                        return $("<span/>").html(item.text);
                    },
                    templateSelection: function (item) {
                        return $("<span/>").html(item.text);
                    },
                    placeholder: "(Alle)"
                });

                $.each(res, function (index, item) {
                    $me.Bruker.append(new Option(item.Navn + " (" + item.Login + ")" + (item.Slettet ? " - <span class=\"text-danger\">" + $me.Slettet.text() + "</span>" : ""), item.Id));
                });
            });

            $me.Bruker.on("change", function () {
                if (ajax != null) ajax.abort();
                if (timer != null) clearTimeout(timer);
                timer = setTimeout($me.ExecSearch, 500);
                return false;
            });

            $me.Soketekst.bind("keyup click tap", function () {
                if (ajax != null) ajax.abort();
                if (timer != null) clearTimeout(timer);

                var soketekst = $(this).val();

                if (soketekst == oldsoketekst) {
                    $liste.css("opacity", 1);
                    return;
                }

                $liste.css("opacity", 0.2);

                timer = setTimeout($me.ExecSearch, 500);
                return false;
            });
            $me.KunFeil.on("change", function () {
                if (ajax != null) ajax.abort();
                if (timer != null) clearTimeout(timer);
                timer = setTimeout($me.ExecSearch, 500);
                return false;
            });
            $me.VisAdmin.on("change", function () {
                if (ajax != null) ajax.abort();
                if (timer != null) clearTimeout(timer);
                timer = setTimeout($me.ExecSearch, 500);
                return false;
            });
            $me.Oppdater.on("click", function () {
                if (ajax != null) ajax.abort();
                if (timer != null) clearTimeout(timer);
                timer = setTimeout($me.ExecSearch, 500);
                return false;
            });

            $me.FraDato.on("change", function () {
                if (ajax != null) ajax.abort();
                if (timer != null) clearTimeout(timer);
                timer = setTimeout($me.ExecSearch, 500);
                return false;
            });

            $me.TilDato.on("change", function () {
                if (ajax != null) ajax.abort();
                if (timer != null) clearTimeout(timer);
                timer = setTimeout($me.ExecSearch, 500);
                return false;
            });

            $me.EksportKnapp.on("click", function () {
                var kunfeil = $kunfeil.prop("checked");
                var visadmin = $visadmin.prop("checked");

                var tilDato = new Date($me.TilDato.val());
                var fraDato = new Date($me.FraDato.val());
                if (fraDato > tilDato) {
                    $.fn.Alert($me.Alert1.text());
                } else {
                    var brukere = $me.Bruker.val();
                    if (brukere == null) brukere = new Array();
                    $me.GetHendelserXlsx(soketekst, kunfeil, visadmin, $me.FraDato.val(), $me.TilDato.val(), brukere);
                }
            });

            $me.Visning.on("click", function () {
                $app.openDialog("Admin/Logg/Visning").done(function ($dialog) {
                    $dialog.on("dialogclosed", function () {
                        $oppdater.trigger("click");
                    });
                });
            });

            $me.TabellContainer.on("scroll", function () {
                var left = $(this).scrollLeft();
                $me.StickyHeaderContainer.find("table").css({ "margin-left": -left });
            });

            $(window).on("resize.hendelseslogg", $me.OppdaterStickyHeader);

            $me.LoadNextPage();
            $me.AttachSearch();
        });

        this.on("viewclosing", function () {
            $(window).off("resize.hendelseslogg");
        });

        this.OppdaterStickyHeader = function () {
            $me.TabellHeader.find("th").each(function (index, item) {
                var width = $(item).outerWidth();
                $me.StickyHeader.find("th:nth-child(" + (index + 1) + ")").css({
                    "width": width,
                    "min-width": width,
                    "max-width": width,
                });
            });

            var scrollbarWidth = $me.TabellContainer.prop("offsetWidth") - $me.TabellContainer.prop("clientWidth");
            var width = $me.TabellContainer.width() - scrollbarWidth;
            $me.StickyHeaderContainer.width(width);
        };

        this.HandleScroll = function () {
            if (ajax != null) return; // Already loading
            if (isScrolledIntoView($me.LoadMore)) {
                $me.LoadNextPage();
            }
            var $txt = $me.Soketekst;
            if ($txt.is(":focus") && !isScrolledIntoView($txt)) {
                $txt.blur();
            }
        };

        this.AddHendelse = function (item) {

            var $tr = $("<tr data-id=\"" + item.Id + "\">" +
                "<td class=\"tight\"><span class=\"glyphicon glyphicon-file\"></span> " + formatDateTime(item.Opprettet) + "</td>" +
                "<td>" + item.Type + "</td>" +
                "<td>" + item.Bruker + "</td>" +
                "<td>" + item.Firma + "</td>" +
                "<td>" + item.IP + "</td>" +
                "<td>" + (item.ReferanseId != null ? item.ReferanseId : "") + "</td>" +
                "<td>" + (item.Success ? "OK" : "Feilet") + "</td>" +
                "<td>" + (item.Execms != null ? item.Execms : "") + "</td></tr>");

            if (item.Success) {
                $tr.addClass("text-success");
            }
            else $tr.addClass("text-danger");

            $liste.append($tr);
            if (item.Detaljer)
                $liste.append("<tr><td colspan=\"100\"><blockquote style=\"margin:0;white-space: pre-wrap\" class=\"small text-warning\">" + item.Detaljer + "</div></td></tr>");
        };

        function isScrolledIntoView(elem) {
            var docViewTop = $me.TabellContainer.scrollTop();
            var docViewBottom = docViewTop + $me.TabellContainer.height();
            var $e = $(elem);
            if (!$e.is(":visible")) return false;
            var elemTop = $e.offset().top - $e.parent().offset().top;
            //var elemBottom = elemTop + $(elem).height();

            return elemTop < docViewBottom;
        }

        this.LoadNextPage = function () {
            var tilDato = new Date($me.TilDato.val());
            var fraDato = new Date($me.FraDato.val());
            if (fraDato > tilDato) {
                $.fn.Alert($me.Alert1.text());
                return;
            }

            var brukere = $me.Bruker.val();
            if (brukere == null) brukere = new Array();

            
            $me.LoadMore.show();
            $liste.find(".empty").remove();
            $liste.css("opacity", 1);
            var kunfeil = $kunfeil.prop("checked");
            var visadmin = $visadmin.prop("checked");

            ajax = $me.GetHendelser(soketekst, sisteId, antallprside, kunfeil, visadmin, $me.FraDato.val(), $me.TilDato.val(), brukere).done(function (result) {
                ajax = null;
                sisteId = result.SisteId;
                oldsoketekst = soketekst;

                $.each(result.Logg, function (index, item) {
                    $me.AddHendelse(item);
                });

                if (result.Logg.length == antallprside) $me.HandleScroll();
                else {
                    $me.LoadMore.hide();

                    if (sisteId == null && result.Logg.length == 0)
                        $liste.append("<tr class=\"empty\"><td class=\"text-muted\" colspan=\"100\">Ingen treff</td></tr>");
                }

                $me.HandleScroll();
                $me.OppdaterStickyHeader();
            });
        };

        this.ExecSearch = function () {
            soketekst = $me.Soketekst.val();

            sisteId = null;
            $liste.html("");
            timer = null;

            $me.LoadNextPage();
        };

        this.on("viewsuspended viewclosed", function () {
            $(document).off("scroll");
            $(document).off("keydown");
        });

        this.on("viewrestored", function () {
            $me.AttachSearch();
        });

        this.AttachSearch = function () {
            $(document).off("keydown");
            $(document).on("keydown", function (ev) {
                if (ev.which >= 33 && ev.which <= 40) return true; // Arrow keys
                var nodeName = ev.target.nodeName;
                if ("INPUT" == nodeName || "TEXTAREA" == nodeName || "SELECT" == nodeName) return;
                if ($(ev.target).attr("contenteditable") == "true") return;
                $me.Soketekst.val("").trigger("focus");
            });
        };

        this.GetHendelserXlsx = function (soketekst, kunfeil, visadmin, fraDato, tilDato, brukere) {
            return $app.APIDownload("Admin/Logg/Hendelseslogg", "GetHendelserXlsx", { soketekst: soketekst, kunfeil: kunfeil, visadmin: visadmin, fraDato: fraDato, tilDato: tilDato, brukere: brukere }, { background: true });
        };
        this.GetHendelser = function (soketekst, sisteId, antall, kunfeil, visadmin, fraDato, tilDato, brukere) {
            var data = { Soketekst: soketekst, SisteId: sisteId, Antall: antall, KunFeil: kunfeil, VisAdmin: visadmin, FraDato: fraDato, TilDato: tilDato, Brukere: brukere };
            return $app.APIPost("Admin/Logg/Hendelseslogg", "GetHendelser", data, { background: true });
        };

        this.GetBrukere = function () {
            return $app.APIGet("Admin/Logg/Hendelseslogg", "GetBrukere");
        };

        return this;
    };
})(jQuery);