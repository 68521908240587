﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.FileUpload = function ($app) { // <-- Constructor method
        var $me = this;
        // Define the jquery class

        this.Reset = function () {
            $me.UploadProgress.css("width", "0%");
        };

        this.UpdateProgress = function (progress, total) {
            var pc = parseInt(Math.round(progress / total * 100));
            $me.UploadProgress.css("width", pc + "%");
            $me.UploadProgressLabel.text(Math.round(pc) + " %");
            if (progress == 0) $me.Dialog.show();
        };
        
        return this;
    };
})(jQuery);