﻿(function ($) {
    "use strict";

    // Add any neccessary contructor parameters in side the function () below. Example function (otherControl), this will
    $.fn.RapportMålebrev = function ($app) { // <-- Constructor method
        var $me = this;
        var sortering = 0;
        var reversert = false;

        var searchdebounce = null;
        $me.latestresult = null;

        var lastShownMålebrevId = 0;

        // Define the jquery class
        this.on("viewinit", function () {

            $me.find("[data-s]").each(function () {
                $(this).append("<span title=\"Sortert stigende\" class=\"glyphicon glyphicon-triangle-top sortering stigende\"></span>");
                $(this).append("<span title=\"Sortert synkende\" class=\"glyphicon glyphicon-triangle-bottom sortering synkende\"></span>");
            });
            $me.find("[data-s]").on("click", function () {
                var nysortering = $(this).attr("data-s");
                if (nysortering != Math.abs(sortering)) {
                    // Sorter på annen kolonne
                    reversert = false;
                    sortering = nysortering;
                    // Tall og datoer starter descending (som explorer.exe)
                    if (nysortering == 7 || nysortering == 8 || nysortering == 9 || nysortering == 10 || nysortering == 11 || nysortering == 12 || nysortering == 13) {
                        reversert = true;
                        sortering = nysortering * -1;
                    }
                } else {
                    // Reverser gjeldende sortering
                    sortering = sortering * -1;
                    reversert = !reversert;
                }

                $me.find(".sortering").css("display", "none");

                if (reversert) {
                    $(this).find(".synkende").first().css("display", "inline-block");
                } else {
                    $(this).find(".stigende").first().css("display", "inline-block");
                }

                $me.Oppdater();
            });

            $me.Hovedkontrakter.on("change", function () {
                $me.Oppdater();
            });

            $me.find("input[name=format]").on("change", function () {
                $me.ResetSort();
                $me.Oppdater();
            });

            $me.HentHovedkontrakter().done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }

                $.each(result.Hovedkontrakter, function () {
                    $me.Hovedkontrakter.append("<option value=\"" + this.Id + "\">" + this.Navn + "</option>");
                });

                $me.Hovedkontrakter.trigger("change");
            });

            $me.EksportKnapp.on("click", function () {

                var id = $me.Hovedkontrakter.val();
                var format = $me.format();

                $me.HentRapportExcel(id, format);
            });

            $me.Søk.on("input", function () {
                if (searchdebounce != null) {
                    clearTimeout(searchdebounce);
                    searchdebounce = null;
                }

                searchdebounce = setTimeout($me.ApplySearch, 278);
            });

            $me.BtnSearch.on("click", function () {
                $me.ApplySearch();
            });

            $me.HentStatuser().done(function (result) {
                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }

                $.each(result.Statuser, function () {
                    $me.Statuser.append("<label style=\"margin-right:20px\"><input type=\"checkbox\" value=\"" + this.Id + "\" checked=\"checked\" /> " + this.Navn + "</label >");
                });

                $me.Statuser.find("input").on("change", function () {
                    var $h = $(this);
                    $me.VisOppføringer(lastShownMålebrevId, true);
                });
            });

            $(window).on("resize.malebrevrapport", $me.OppdaterStickyHeader);

            $me.TabellContainer.on("scroll", function () {
                var left = $(this).scrollLeft();
                $me.StickyHeaderContainer.find("table").css({ "margin-left": -left });
            });
        });

        this.on("viewclosing", function () {
            $(window).off("resize.malebrevrapport");
        });

        this.OppdaterStickyHeader = function () {
            $me.HeaderNSkode.find("th").each(function (index, item) {
                var width = $(item).outerWidth();
                $me.StickyHeaderNSkode.find("th:nth-child(" + (index + 1) + ")").css({
                    "width": width,
                    "min-width": width,
                    "max-width": width,
                });
            });

            $me.HeaderProsesskode.find("th").each(function (index, item) {
                var width = $(item).outerWidth();
                $me.StickyHeaderProsesskode.find("th:nth-child(" + (index + 1) + ")").css({
                    "width": width,
                    "min-width": width,
                    "max-width": width,
                });
            });

            var scrollbarWidth = $me.TabellContainer.prop("offsetWidth") - $me.TabellContainer.prop("clientWidth");
            var width = $me.TabellContainer.width() - scrollbarWidth;
            $me.StickyHeaderContainer.width(width);
        };

        this.ApplySearch = function () {

            var search = $me.Søk.val();

            if (!search || !search.length) {

                $me.ApplyResults($me.latestresult);
                return;
            }

            var searchresults = [];
            var words = search.toLowerCase().split(" ");

            for (var i = 0; i < $me.latestresult.length; i++) {
                var m = $me.latestresult[i];

                var matchedwords = 0;

                for (var w = 0; w < words.length; w++) {
                    var word = words[w];

                    for (var field in m) {
                        var val = m[field];
                        if (val) {

                            if (typeof (val) === 'number') {
                                val = val.toFixed(2);
                            }

                            if (val.toString().toLowerCase().indexOf(word) > -1) {
                                matchedwords++;
                                break;
                            }
                        }
                    }
                }

                if (matchedwords == words.length) {
                    searchresults.push(m);
                }
            }

            var format = $me.format();

            $me.ApplyResults(searchresults);

            if (format == 0) {
                $me.MålebrevNSkode.highlight(words);
            } else {
                $me.MålebrevProsesskode.highlight(words);
            }
        }

        this.ApplyResults = function (rapport) {

            var format = $me.format();

            $me.MålebrevNSkode.empty();
            $me.MålebrevProsesskode.empty();

            if (!rapport || !rapport.length) {
                $me.MålebrevProsesskode.append("<tr><td colspan=\"1000\"><i>" + $me.Tekst_1.text() + "</i></td></tr>");
                $me.MålebrevNSkode.append("<tr><td colspan=\"1000\"><i>" + $me.Tekst_2.text() + "</i></td></tr>");
                return;
            }

            if (format == 0) {
                $.each(rapport, function (index, item) {
                    var $tr = $("<tr style=\"cursor:pointer\">" +
                        "<td>" + item.Postnr + "</td>" +
                        "<td>" + item.Element + "</td>" +
                        "<td>" + item.Målebrevsnr + "</td>" +
                        "<td><span class=\"linjebeskrivelse\" title=\"" + $me.EscapeQoutes(item.Beskrivelse) + "\">" + item.Beskrivelse + "</span></td>" +
                        "<td>" + item.MottattDato + "</td>" +
                        "<td>" + item.Sluttavregnet + "</td>" +
                        "<td class=\"text-right nobr\">" + item.Mengde + "</td>" +
                        "<td class=\"text-right nobr\">" + item.GodkjentMengde + "</td>" +
                        "<td>" + item.GodkjentDato + "</td>" +
                        "<td class=\"text-right nobr\">" + item.Kontraktsmengde + "</td>" +
                        "<td class=\"text-right nobr\">" + item.Enhetspris + "</td>" +
                        "<td><span class=\"linjebeskrivelse\" title=\"" + $me.EscapeQoutes(item.Elementbeskrivelse) + "\">" + item.Elementbeskrivelse + "</span></td>" +
                        "<td class=\"nopadd\"><button class=\"btn btn-default\"><span class=\"glyphicon glyphicon-zoom-in\"></span></a></td>"

                        + "</tr>");

                    $tr.data("id", item.Id);
                    $tr.on("click", function () {
                        $me.VisOppføringer(item.Id, false);
                    });

                    $me.MålebrevNSkode.append($tr);
                });
            } else {
                $.each(rapport, function (index, item) {
                    var $tr = $("<tr style=\"cursor:pointer\">" +
                        "<td>" + item.Sted + "</td>" +
                        "<td>" + item.Element + "</td>" +
                        "<td>" + item.Prosess + "</td>" +
                        "<td>" + item.Målebrevsnr + "</td>" +
                        "<td><span class=\"linjebeskrivelse\" title=\"" + $me.EscapeQoutes(item.Beskrivelse) + "\">" + item.Beskrivelse + "</span></td>" +
                        "<td>" + item.MottattDato + "</td>" +
                        "<td>" + item.Sluttavregnet + "</td>" +
                        "<td class=\"text-right nobr\">" + item.Mengde + "</td>" +
                        "<td class=\"text-right nobr\">" + item.GodkjentMengde + "</td>" +
                        "<td>" + item.GodkjentDato + "</td>" +
                        "<td class=\"text-right nobr\">" + item.Kontraktsmengde + "</td>" +
                        "<td class=\"text-right nobr\">" + item.Enhetspris + "</td>" +
                        "<td><span class=\"linjebeskrivelse\" title=\"" + $me.EscapeQoutes(item.Elementbeskrivelse) + "\">" + item.Elementbeskrivelse + "</span></td>" +
                        "<td class=\"nopadd\"><button class=\"btn btn-default\"><span class=\"glyphicon glyphicon-zoom-in\"></span></a></td>"

                        + "</tr>");

                    $tr.data("id", item.Id);
                    $tr.on("click", function () {
                        $me.VisOppføringer(item.Id, false);
                    });

                    $me.MålebrevProsesskode.append($tr);
                });
            }

            $me.OppdaterStickyHeader();
        }

        this.EscapeQoutes = function (string) {
            return string.replace(/"/g, "&quot;");
        }

        this.StatusInstillinger = function () {
            var output = [];
            $me.Statuser.find("input").each(function () {
                var $h = $(this);
                if ($h.prop("checked")) {
                    output.push(Number($h.val()));
                }
            });
            return output;
        }

        this.VisOppføringer = function (id, ignoremodal) {
            lastShownMålebrevId = id;

            $me.OppføringerTabell.empty();

            var visStatuser = $me.StatusInstillinger();

            $me.HentOppføringer(id, visStatuser).done(function (result) {

                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }

                if (!ignoremodal) {
                    $me.OppføringerModal.modal("show");
                }

                $me.Statuser.find("input").each(function () {
                    var $h = $(this);

                    if (result.Statuser.indexOf(Number($h.val())) == -1) {
                        $h.closest("label").hide();
                    } else {
                        $h.closest("label").show();
                    }
                });

                $me.OppføringHeader.text($me.Tekst_3.text() + " " + $me.Tekst_5.text() + " " + result.Prosess + " - " + $me.Tekst_6.text() + " " + result.Element + " - " + result.Elementbeskrivelse);

                $.each(result.Oppføringer, function (index, item) {
                    var $tr = $("<tr>" +
                        "<td>" + item.Oppføringsnr + "</td>" +
                        "<td>" + item.Tittel + "</td>" +
                        "<td>" + item.Sluttmålebrev + "</td>" +
                        "<td class=\"text-right nobr\">" + item.Målebrevsnr + "</td>" +
                        "<td>" + item.Dokumentasjon + "</td>" +
                        "<td>" + item.Beskrivelse + "</td>" +
                        "<td class=\"text-right nobr\">" + item.Mengde + "</td>" +
                        "<td>" + item.Status + "</td>" +
                        "<td>" + item.Innsendt + "</td>" +
                        "<td>" + item.GodkjentDato + "</td>" +
                        "<td class=\"nopadd\"><a href=\"#/O+" + item.Id + "\" target=\"_blank\" class=\"btn btn-default\"><span class=\"glyphicon glyphicon-zoom-in\"></span></a></td>"
                        + "</tr>");
                    $me.OppføringerTabell.append($tr);
                });

                if (!result.Oppføringer || !result.Oppføringer.length) {
                    $me.OppføringerTabell.append("<tr><td colspan=\"42\"><i>" + $me.Tekst_4.text() + "</i></td></tr>");
                }
            });
        }

        this.ResetSort = function () {
            $me.find("[data-s]").find(".sortering").css("display", "none");
            sortering = 0;
            reversert = false;
        }

        this.Oppdater = function () {
            var id = $me.Hovedkontrakter.val();

            var format = $me.format();

            if (format == 0) {
                $me.TabellProsesskode.hide();
                $me.TabellNSkode.show();
                $me.TabellProsesskodeHeader.hide();
                $me.TabellNSkodeHeader.show();
            } else {
                $me.TabellNSkode.hide();
                $me.TabellProsesskode.show();
                $me.TabellNSkodeHeader.hide();
                $me.TabellProsesskodeHeader.show();
            }

            if (!id) {
                $me.EksportKnapp.prop('disabled', true);
                return;
            }

            $me.ValgtHovedkontrakt.text($(this).find("option:selected").text());

            $me.HentMålebrev(id, $me.format(), sortering, reversert).done(function (result) {

                if (!result.Success) {
                    $.fn.Alert(result.Message, null, "h3");
                    return;
                }

                if (result.Rapport && result.Rapport.length) {
                    $me.EksportKnapp.prop('disabled', false);
                } else {
                    $me.EksportKnapp.prop('disabled', true);
                }

                $me.latestresult = result.Rapport;

                $me.ApplySearch(result.Rapport);
            });
        };

        this.format = function () {
            return $me.find("input[name=format]:checked").val()
        };

        this.HentHovedkontrakter = function () {
            return $app.APIGet("Rapport/Målebrev", "HentHovedkontrakter");
        };

        this.HentMålebrev = function (hovedkontraktId, format, sortering, reversert, søketekst) {
            return $app.APIGet("Rapport/Målebrev", "HentMålebrev", { hovedkontraktId: hovedkontraktId, format: format, sortering: sortering, reversert: reversert, søketekst: søketekst });
        };

        this.HentRapportExcel = function (hovedkontraktId, format) {
            return $app.APIDownload("Rapport/Målebrev", "HentMålebrevExcel", { hovedkontraktId: hovedkontraktId, format: format });
        };

        this.HentStatuser = function () {
            return $app.APIGet("Rapport/Målebrev", "HentStatuser");
        };

        this.HentOppføringer = function (målebrevkontraktId, visStatuser) {
            return $app.APIGet("Rapport/Målebrev", "HentOppføringer", { id: målebrevkontraktId, visStatuser: visStatuser });
        };

        return this;
    };
})(jQuery);